.leagues-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
}

.league-view-layout {
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.league-ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 10vh;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  row-gap: 0.2rem;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;

  .league-ranking-body {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.39);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 400px;
    left: 0px;
    top: 0px;
    flex-direction: column;
    display: flex;
    row-gap: 0.2rem;
    z-index: 1;
    margin-bottom: 10vh;

    &::-webkit-scrollbar {
      width: 5px;
      background: #ffffff3f;
      /* make scrollbar transparent */
    }

    // custom scroll thumb
    &::-webkit-scrollbar-thumb {
      background: #ffffff3f;
      border-radius: 5px;
    }

  }

  .league-ranking-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 0.5rem 2rem;
    font-weight: 600;
    font-family: 'Rajdhani', sans-serif;
    background-color: #3b4345;
    border-radius: 2px 2px 0px;
    color: #c1c1c1;
    font-size: 14px;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }

    &__position {
      width: 5%;
    }

    &__player {
      width: 60%;
    }

    &__score {
      width: 10%;
    }

    &__avatar {
      width: 10%;
    }

    &__prize {
      width: 20%;
    }

  }

  .highlighted {
    box-shadow: 0 0 20px rgba(249, 195, 146, 0.955),
     5px 5px 20px rgba(237, 224, 126, 0.955),
     -5px -5px 20px rgba(235, 171, 103, 0.955);
    border: 3px solid orange;
  }

  .league-ranking-item {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 2px;
    height: 70px;
    position: relative;

    &:hover {
      .league-ranking-prize__preview--popup {
        position: absolute;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        top: -40%;
        left: 130%;

        &--name {
          /* font-size: 0.5rem; */
          white-space: nowrap;
        }
      }
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 70px;
      left: 0;
      background-image: url(../../assets/images/octagon.svg);
      background-size: 10px 10px;
      transform: rotateX(180deg);
      opacity: 0.15;
    }

    &__position {
      width: 5%;
      font-size: 1.5rem;

      @media screen and (max-width: 768px) {
        font-size: 10px;
      }

      font-weight: 800;
      font-family: 'Rajdhani',
      sans-serif;
    }

    &__player {
      width: 55%;
      font-weight: 600;
      font-size: 1.5rem;

      @media screen and (max-width: 768px) {
        font-size: 10px;
      }

      font-family: 'Rajdhani',
      sans-serif;
    }

    &__score {
      font-weight: 600;
      font-size: 1.5rem;

      @media screen and (max-width: 768px) {
        font-size: 10px;
      }

      font-family: 'Rajdhani',
      sans-serif;
      width: 10%;
    }


    &__prize {
      font-weight: 600;
      /* font-size: 0.9rem; */
      font-family: 'Rajdhani', sans-serif;
      width: 20%;
      padding: 10px 3px;
      border-radius: 2px;
      color: #fff;
      cursor: pointer;
      z-index: 2;
      position: relative;
    }

    &--default {
      color: white;
      background: linear-gradient(45deg, #262423, #454444);
      height: 70px;
    }

    &--first {
      padding: 1rem 2rem;
      color: black;
      border: 1px solid gold;
      background: linear-gradient(to right, #bf943f90, #FCF6BA, #b38728dd, #FBF5B7, #aa761c7b);

    }

    &--second {
      color: rgb(255, 255, 255);
      background: #abbaab;
      border: 1px solid silver;
      // background: linear-gradient(to right, #dae5e7, #e5f5fc);
      background: linear-gradient(to right, #5d6365, #d8f8e880, #577383a3, #c6e0f29b, #3b4345);

    }

    &--third {
      color: white;
      border: 1px solid #D47800;
      background: linear-gradient(to right, #662E00, #783c04, #884f13, #8d47028e, #d47800b7);

    }

  }
}