.tournament-ranking {
  display: flex;
  flex: 2 1 0;

  .tournament-ranking-container {
    display: flex;
    flex-direction: column;
  }

  >div {
    width: 100%;
  }

  .tr-tab-container {
    display: flex;
    justify-content: center;
    margin: 15px 15px -15px 15px;
  }

  .tr-tab {
    font-family: 'Rajdhani', serif;
    font-size: 18px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 14px 24px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25), inset 0 2px 0 rgba(255, 255, 255, 0.06);
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  .tr-tab-enabled {
    color: rgb(255, 92, 0);
  }

  table {
    border-collapse: collapse;
    flex: 2 1 auto;
    width: MIN(90%, 1000px);
    margin: 30px auto 0;

    td,
    th div {
      font-family: 'Rajdhani', serif;
      text-align: center;
      padding: 8px;
    }

    th div {
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: 300;
      color: rgb(116, 116, 116);
      padding: 10px 5px 15px;
    }

    tr:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.6);
    }

    tbody {
      border: 2px solid rgba(0, 0, 0, 0.5);
    }
  }
}
