@keyframes blinker-skip {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes popeye-slide-up {
  0% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes popeye-slide-down {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.pie__segment {
  height: 100%;
  position: absolute;
  width: 100%;
  transform: rotate(-90deg) rotate(0deg);
  animation: pie__segment 1s linear infinite alternate, pie__angle 2s linear infinite;
}

@keyframes pie__angle {
  0% { transform: rotate(-90deg) rotate(0) }
  50% { transform: rotate(-90deg) rotate(0) }
  62.5% { transform: rotate(-90deg) rotate(90deg) }
  75% { transform: rotate(-90deg) rotate(180deg) }
  87.5% { transform: rotate(-90deg) rotate(270deg) }
  100% { transform: rotate(-90deg) rotate(360deg) }
}

@keyframes pie__segment {
  0% { clip-path: polygon(0 0, 0 0, 0 0, 0 0); }
  0.28% { clip-path: polygon(50% 50%, 100% 50%, 249.969539% 53.4904812%); }
  0.56% { clip-path: polygon(50% 50%, 100% 50%, 249.8781654% 56.9798994%); }
  0.83% { clip-path: polygon(50% 50%, 100% 50%, 249.72590699999998% 60.4671912%); }
  1.11% { clip-path: polygon(50% 50%, 100% 50%, 249.51281% 63.9512948%); }
  1.39% { clip-path: polygon(50% 50%, 100% 50%, 249.2389396% 67.4311486%); }
  1.67% { clip-path: polygon(50% 50%, 100% 50%, 248.904379% 70.90569260000001%); }
  1.94% { clip-path: polygon(50% 50%, 100% 50%, 248.50923039999998% 74.37386860000001%); }
  2.22% { clip-path: polygon(50% 50%, 100% 50%, 248.0536138% 77.8346202%); }
  2.5% { clip-path: polygon(50% 50%, 100% 50%, 247.5376682% 81.28689299999999%); }
  2.78% { clip-path: polygon(50% 50%, 100% 50%, 246.9615506% 84.7296356%); }
  3.06% { clip-path: polygon(50% 50%, 100% 50%, 246.3254366% 88.161799%); }
  3.33% { clip-path: polygon(50% 50%, 100% 50%, 245.6295202% 91.58233820000001%); }
  3.61% { clip-path: polygon(50% 50%, 100% 50%, 244.874013% 94.9902108%); }
  3.89% { clip-path: polygon(50% 50%, 100% 50%, 244.05914520000002% 98.3843792%); }
  4.17% { clip-path: polygon(50% 50%, 100% 50%, 243.1851652% 101.76380900000001%); }
  4.44% { clip-path: polygon(50% 50%, 100% 50%, 242.2523392% 105.1274712%); }
  4.72% { clip-path: polygon(50% 50%, 100% 50%, 241.2609512% 108.474341%); }
  5% { clip-path: polygon(50% 50%, 100% 50%, 240.2113032% 111.8033988%); }
  5.28% { clip-path: polygon(50% 50%, 100% 50%, 239.1037152% 115.1136308%); }
  5.56% { clip-path: polygon(50% 50%, 100% 50%, 237.9385242% 118.4040286%); }
  5.83% { clip-path: polygon(50% 50%, 100% 50%, 236.7160852% 121.67359%); }
  6.11% { clip-path: polygon(50% 50%, 100% 50%, 235.436771% 124.92131859999999%); }
  6.39% { clip-path: polygon(50% 50%, 100% 50%, 234.10097059999998% 128.14622559999998%); }
  6.67% { clip-path: polygon(50% 50%, 100% 50%, 232.7090916% 131.3473286%); }
  6.94% { clip-path: polygon(50% 50%, 100% 50%, 231.26155740000002% 134.5236524%); }
  7.22% { clip-path: polygon(50% 50%, 100% 50%, 229.7588092% 137.6742294%); }
  7.5% { clip-path: polygon(50% 50%, 100% 50%, 228.2013048% 140.7981%); }
  7.78% { clip-path: polygon(50% 50%, 100% 50%, 226.58951860000002% 143.89431259999998%); }
  8.06% { clip-path: polygon(50% 50%, 100% 50%, 224.92394140000002% 146.961924%); }
  8.33% { clip-path: polygon(50% 50%, 100% 50%, 223.20508080000002% 150%); }
  8.61% { clip-path: polygon(50% 50%, 100% 50%, 221.4334602% 153.007615%); }
  8.89% { clip-path: polygon(50% 50%, 100% 50%, 219.6096192% 155.9838528%); }
  9.17% { clip-path: polygon(50% 50%, 100% 50%, 217.7341136% 158.927807%); }
  9.44% { clip-path: polygon(50% 50%, 100% 50%, 215.80751460000002% 161.8385806%); }
  9.72% { clip-path: polygon(50% 50%, 100% 50%, 213.8304088% 164.71528719999998%); }
  10% { clip-path: polygon(50% 50%, 100% 50%, 211.8033988% 167.55705039999998%); }
  10.28% { clip-path: polygon(50% 50%, 100% 50%, 209.727102% 170.3630046%); }
  10.56% { clip-path: polygon(50% 50%, 100% 50%, 207.6021508% 173.132295%); }
  10.83% { clip-path: polygon(50% 50%, 100% 50%, 205.4291922% 175.8640782%); }
  11.11% { clip-path: polygon(50% 50%, 100% 50%, 203.2088886% 178.55752199999998%); }
  11.39% { clip-path: polygon(50% 50%, 100% 50%, 200.941916% 181.2118058%); }
  11.67% { clip-path: polygon(50% 50%, 100% 50%, 198.628965% 183.8261212%); }
  11.94% { clip-path: polygon(50% 50%, 100% 50%, 196.27074040000002% 186.399672%); }
  12.22% { clip-path: polygon(50% 50%, 100% 50%, 193.86795999999998% 188.931674%); }
  12.5% { clip-path: polygon(50% 50%, 100% 50%, 191.4213562% 191.4213562%); }
  12.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 188.931674% 193.86795999999998%); }
  13.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 186.399672% 196.27074040000002%); }
  13.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 183.8261212% 198.628965%); }
  13.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 181.2118058% 200.941916%); }
  13.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 178.55752199999998% 203.2088886%); }
  14.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 175.8640782% 205.4291922%); }
  14.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 173.132295% 207.6021508%); }
  14.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 170.3630046% 209.727102%); }
  15% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 167.55705039999998% 211.8033988%); }
  15.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 164.71528719999998% 213.8304088%); }
  15.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 161.8385806% 215.80751460000002%); }
  15.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 158.927807% 217.7341136%); }
  16.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 155.9838528% 219.6096192%); }
  16.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 153.007615% 221.4334602%); }
  16.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 150% 223.20508080000002%); }
  16.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 146.961924% 224.92394140000002%); }
  17.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 143.89431259999998% 226.58951860000002%); }
  17.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 140.7981% 228.2013048%); }
  17.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 137.6742294% 229.7588092%); }
  18.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 134.5236524% 231.26155740000002%); }
  18.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 131.3473286% 232.7090916%); }
  18.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 128.14622559999998% 234.10097059999998%); }
  18.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 124.92131859999999% 235.436771%); }
  19.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 121.67359% 236.7160852%); }
  19.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 118.4040286% 237.9385242%); }
  19.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 115.1136308% 239.1037152%); }
  20% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 111.8033988% 240.2113032%); }
  20.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 108.474341% 241.2609512%); }
  20.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 105.1274712% 242.2523392%); }
  20.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 101.76380900000001% 243.1851652%); }
  21.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 98.3843792% 244.05914520000002%); }
  21.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 94.9902108% 244.874013%); }
  21.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 91.58233820000001% 245.6295202%); }
  21.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 88.161799% 246.3254366%); }
  22.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 84.7296356% 246.9615506%); }
  22.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 81.28689299999999% 247.5376682%); }
  22.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 77.8346202% 248.0536138%); }
  23.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 74.37386860000001% 248.50923039999998%); }
  23.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 70.90569260000001% 248.904379%); }
  23.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 67.4311486% 249.2389396%); }
  23.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 63.9512948% 249.51281%); }
  24.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 60.4671912% 249.72590699999998%); }
  24.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 56.9798994% 249.8781654%); }
  24.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 53.4904812% 249.969539%); }
  25% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 250%); }
  25.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 46.5095188% 249.969539%); }
  25.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 43.0201006% 249.8781654%); }
  25.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 39.5328088% 249.72590699999998%); }
  26.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 36.0487052% 249.51281%); }
  26.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 32.5688514% 249.2389396%); }
  26.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 29.094307399999998% 248.904379%); }
  26.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 25.6261314% 248.50923039999998%); }
  27.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 22.1653798% 248.0536138%); }
  27.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 18.713107% 247.5376682%); }
  27.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 15.270364400000005% 246.9615506%); }
  28.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 11.838200999999998% 246.3254366%); }
  28.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 8.417661799999998% 245.6295202%); }
  28.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 5.0097892% 244.874013%); }
  28.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 1.615620800000002% 244.05914520000002%); }
  29.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -1.763809000000002% 243.1851652%); }
  29.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -5.127471200000002% 242.2523392%); }
  29.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -8.474340999999995% 241.2609512%); }
  30% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -11.803398799999997% 240.2113032%); }
  30.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -15.113630799999996% 239.1037152%); }
  30.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -18.404028600000004% 237.9385242%); }
  30.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -21.673590000000004% 236.7160852%); }
  31.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -24.921318599999992% 235.436771%); }
  31.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -28.146225599999994% 234.10097059999998%); }
  31.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -31.347328599999997% 232.7090916%); }
  31.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -34.5236524% 231.26155740000002%); }
  32.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -37.6742294% 229.7588092%); }
  32.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -40.798100000000005% 228.2013048%); }
  32.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -43.89431259999999% 226.58951860000002%); }
  33.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -46.961923999999996% 224.92394140000002%); }
  33.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -50% 223.20508080000002%); }
  33.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -53.007615% 221.4334602%); }
  33.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -55.983852799999994% 219.6096192%); }
  34.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -58.927807% 217.7341136%); }
  34.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -61.8385806% 215.80751460000002%); }
  34.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -64.71528719999999% 213.8304088%); }
  35% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -67.5570504% 211.8033988%); }
  35.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -70.3630046% 209.727102%); }
  35.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -73.132295% 207.6021508%); }
  35.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -75.86407820000001% 205.4291922%); }
  36.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -78.55752199999998% 203.2088886%); }
  36.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -81.21180580000001% 200.941916%); }
  36.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -83.82612119999999% 198.628965%); }
  36.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -86.39967200000001% 196.27074040000002%); }
  37.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -88.93167399999999% 193.86795999999998%); }
  37.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, -91.42135619999999% 191.4213562%); }
  37.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -93.86795999999998% 188.931674%); }
  38.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -96.27074040000002% 186.399672%); }
  38.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -98.628965% 183.8261212%); }
  38.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -100.94191599999999% 181.2118058%); }
  38.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -103.2088886% 178.55752199999998%); }
  39.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -105.42919219999999% 175.8640782%); }
  39.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -107.6021508% 173.132295%); }
  39.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -109.727102% 170.3630046%); }
  40% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -111.8033988% 167.55705039999998%); }
  40.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -113.83040879999999% 164.71528719999998%); }
  40.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -115.80751460000002% 161.8385806%); }
  40.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -117.7341136% 158.927807%); }
  41.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -119.6096192% 155.9838528%); }
  41.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -121.43346020000001% 153.007615%); }
  41.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -123.20508080000002% 150%); }
  41.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -124.92394140000002% 146.961924%); }
  42.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -126.58951860000002% 143.89431259999998%); }
  42.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -128.2013048% 140.7981%); }
  42.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -129.7588092% 137.6742294%); }
  43.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -131.26155740000002% 134.5236524%); }
  43.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -132.7090916% 131.3473286%); }
  43.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -134.10097059999998% 128.14622559999998%); }
  43.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -135.436771% 124.92131859999999%); }
  44.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -136.7160852% 121.67359%); }
  44.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -137.9385242% 118.4040286%); }
  44.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -139.1037152% 115.1136308%); }
  45% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -140.2113032% 111.8033988%); }
  45.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -141.2609512% 108.474341%); }
  45.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -142.2523392% 105.1274712%); }
  45.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -143.1851652% 101.76380900000001%); }
  46.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -144.05914520000002% 98.3843792%); }
  46.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -144.874013% 94.9902108%); }
  46.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -145.6295202% 91.58233820000001%); }
  46.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -146.3254366% 88.161799%); }
  47.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -146.9615506% 84.7296356%); }
  47.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -147.5376682% 81.28689299999999%); }
  47.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -148.0536138% 77.8346202%); }
  48.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -148.50923039999998% 74.37386860000001%); }
  48.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -148.904379% 70.90569260000001%); }
  48.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -149.2389396% 67.4311486%); }
  48.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -149.51281% 63.9512948%); }
  49.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -149.72590699999998% 60.4671912%); }
  49.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -149.8781654% 56.9798994%); }
  49.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -149.969539% 53.4904812%); }
  50% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, -150% 50%); }
  50.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -149.969539% 46.5095188%); }
  50.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -149.8781654% 43.0201006%); }
  50.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -149.72590699999998% 39.5328088%); }
  51.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -149.51281% 36.0487052%); }
  51.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -149.2389396% 32.5688514%); }
  51.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -148.904379% 29.094307399999998%); }
  51.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -148.50923039999998% 25.6261314%); }
  52.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -148.0536138% 22.1653798%); }
  52.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -147.5376682% 18.713107%); }
  52.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -146.9615506% 15.270364400000005%); }
  53.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -146.3254366% 11.838200999999998%); }
  53.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -145.6295202% 8.417661799999998%); }
  53.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -144.874013% 5.0097892%); }
  53.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -144.05914520000002% 1.615620800000002%); }
  54.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -143.1851652% -1.763809000000002%); }
  54.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -142.2523392% -5.127471200000002%); }
  54.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -141.2609512% -8.474340999999995%); }
  55% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -140.2113032% -11.803398799999997%); }
  55.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -139.1037152% -15.113630799999996%); }
  55.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -137.9385242% -18.404028600000004%); }
  55.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -136.7160852% -21.673590000000004%); }
  56.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -135.436771% -24.921318599999992%); }
  56.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -134.10097059999998% -28.146225599999994%); }
  56.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -132.7090916% -31.347328599999997%); }
  56.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -131.26155740000002% -34.5236524%); }
  57.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -129.7588092% -37.6742294%); }
  57.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -128.2013048% -40.798100000000005%); }
  57.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -126.58951860000002% -43.89431259999999%); }
  58.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -124.92394140000002% -46.961923999999996%); }
  58.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -123.20508080000002% -50%); }
  58.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -121.43346020000001% -53.007615%); }
  58.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -119.6096192% -55.983852799999994%); }
  59.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -117.7341136% -58.927807%); }
  59.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -115.80751460000002% -61.8385806%); }
  59.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -113.83040879999999% -64.71528719999999%); }
  60% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -111.8033988% -67.5570504%); }
  60.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -109.727102% -70.3630046%); }
  60.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -107.6021508% -73.132295%); }
  60.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -105.42919219999999% -75.86407820000001%); }
  61.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -103.2088886% -78.55752199999998%); }
  61.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -100.94191599999999% -81.21180580000001%); }
  61.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -98.628965% -83.82612119999999%); }
  61.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -96.27074040000002% -86.39967200000001%); }
  62.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -93.86795999999998% -88.93167399999999%); }
  62.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, -91.42135619999999% -91.42135619999999%); }
  62.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -88.93167399999999% -93.86795999999998%); }
  63.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -86.39967200000001% -96.27074040000002%); }
  63.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -83.82612119999999% -98.628965%); }
  63.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -81.21180580000001% -100.94191599999999%); }
  63.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -78.55752199999998% -103.2088886%); }
  64.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -75.86407820000001% -105.42919219999999%); }
  64.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -73.132295% -107.6021508%); }
  64.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -70.3630046% -109.727102%); }
  65% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -67.5570504% -111.8033988%); }
  65.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -64.71528719999999% -113.83040879999999%); }
  65.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -61.8385806% -115.80751460000002%); }
  65.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -58.927807% -117.7341136%); }
  66.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -55.983852799999994% -119.6096192%); }
  66.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -53.007615% -121.43346020000001%); }
  66.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -50% -123.20508080000002%); }
  66.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -46.961923999999996% -124.92394140000002%); }
  67.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -43.89431259999999% -126.58951860000002%); }
  67.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -40.798100000000005% -128.2013048%); }
  67.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -37.6742294% -129.7588092%); }
  68.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -34.5236524% -131.26155740000002%); }
  68.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -31.347328599999997% -132.7090916%); }
  68.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -28.146225599999994% -134.10097059999998%); }
  68.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -24.921318599999992% -135.436771%); }
  69.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -21.673590000000004% -136.7160852%); }
  69.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -18.404028600000004% -137.9385242%); }
  69.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -15.113630799999996% -139.1037152%); }
  70% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -11.803398799999997% -140.2113032%); }
  70.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -8.474340999999995% -141.2609512%); }
  70.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -5.127471200000002% -142.2523392%); }
  70.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, -1.763809000000002% -143.1851652%); }
  71.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 1.615620800000002% -144.05914520000002%); }
  71.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 5.0097892% -144.874013%); }
  71.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 8.417661799999998% -145.6295202%); }
  71.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 11.838200999999998% -146.3254366%); }
  72.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 15.270364400000005% -146.9615506%); }
  72.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 18.713107% -147.5376682%); }
  72.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 22.1653798% -148.0536138%); }
  73.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 25.6261314% -148.50923039999998%); }
  73.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 29.094307399999998% -148.904379%); }
  73.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 32.5688514% -149.2389396%); }
  73.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 36.0487052% -149.51281%); }
  74.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 39.5328088% -149.72590699999998%); }
  74.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 43.0201006% -149.8781654%); }
  74.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 46.5095188% -149.969539%); }
  75% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% -150%); }
  75.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 53.4904812% -149.969539%); }
  75.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 56.9798994% -149.8781654%); }
  75.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 60.4671912% -149.72590699999998%); }
  76.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 63.9512948% -149.51281%); }
  76.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 67.4311486% -149.2389396%); }
  76.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 70.90569260000001% -148.904379%); }
  76.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 74.37386860000001% -148.50923039999998%); }
  77.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 77.8346202% -148.0536138%); }
  77.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 81.28689299999999% -147.5376682%); }
  77.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 84.7296356% -146.9615506%); }
  78.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 88.161799% -146.3254366%); }
  78.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 91.58233820000001% -145.6295202%); }
  78.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 94.9902108% -144.874013%); }
  78.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 98.3843792% -144.05914520000002%); }
  79.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 101.76380900000001% -143.1851652%); }
  79.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 105.1274712% -142.2523392%); }
  79.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 108.474341% -141.2609512%); }
  80% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 111.8033988% -140.2113032%); }
  80.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 115.1136308% -139.1037152%); }
  80.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 118.4040286% -137.9385242%); }
  80.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 121.67359% -136.7160852%); }
  81.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 124.92131859999999% -135.436771%); }
  81.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 128.14622559999998% -134.10097059999998%); }
  81.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 131.3473286% -132.7090916%); }
  81.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 134.5236524% -131.26155740000002%); }
  82.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 137.6742294% -129.7588092%); }
  82.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 140.7981% -128.2013048%); }
  82.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 143.89431259999998% -126.58951860000002%); }
  83.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 146.961924% -124.92394140000002%); }
  83.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 150% -123.20508080000002%); }
  83.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 153.007615% -121.43346020000001%); }
  83.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 155.9838528% -119.6096192%); }
  84.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 158.927807% -117.7341136%); }
  84.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 161.8385806% -115.80751460000002%); }
  84.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 164.71528719999998% -113.83040879999999%); }
  85% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 167.55705039999998% -111.8033988%); }
  85.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 170.3630046% -109.727102%); }
  85.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 173.132295% -107.6021508%); }
  85.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 175.8640782% -105.42919219999999%); }
  86.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 178.55752199999998% -103.2088886%); }
  86.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 181.2118058% -100.94191599999999%); }
  86.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 183.8261212% -98.628965%); }
  86.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 186.399672% -96.27074040000002%); }
  87.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 188.931674% -93.86795999999998%); }
  87.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 191.4213562% -91.42135619999999%); }
  87.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 193.86795999999998% -88.93167399999999%); }
  88.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 196.27074040000002% -86.39967200000001%); }
  88.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 198.628965% -83.82612119999999%); }
  88.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 200.941916% -81.21180580000001%); }
  88.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 203.2088886% -78.55752199999998%); }
  89.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 205.4291922% -75.86407820000001%); }
  89.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 207.6021508% -73.132295%); }
  89.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 209.727102% -70.3630046%); }
  90% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 211.8033988% -67.5570504%); }
  90.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 213.8304088% -64.71528719999999%); }
  90.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 215.80751460000002% -61.8385806%); }
  90.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 217.7341136% -58.927807%); }
  91.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 219.6096192% -55.983852799999994%); }
  91.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 221.4334602% -53.007615%); }
  91.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 223.20508080000002% -50%); }
  91.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 224.92394140000002% -46.961923999999996%); }
  92.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 226.58951860000002% -43.89431259999999%); }
  92.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 228.2013048% -40.798100000000005%); }
  92.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 229.7588092% -37.6742294%); }
  93.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 231.26155740000002% -34.5236524%); }
  93.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 232.7090916% -31.347328599999997%); }
  93.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 234.10097059999998% -28.146225599999994%); }
  93.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 235.436771% -24.921318599999992%); }
  94.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 236.7160852% -21.673590000000004%); }
  94.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 237.9385242% -18.404028600000004%); }
  94.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 239.1037152% -15.113630799999996%); }
  95% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 240.2113032% -11.803398799999997%); }
  95.28% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 241.2609512% -8.474340999999995%); }
  95.56% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 242.2523392% -5.127471200000002%); }
  95.83% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 243.1851652% -1.763809000000002%); }
  96.11% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 244.05914520000002% 1.615620800000002%); }
  96.39% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 244.874013% 5.0097892%); }
  96.67% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 245.6295202% 8.417661799999998%); }
  96.94% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 246.3254366% 11.838200999999998%); }
  97.22% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 246.9615506% 15.270364400000005%); }
  97.5% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 247.5376682% 18.713107%); }
  97.78% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 248.0536138% 22.1653798%); }
  98.06% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 248.50923039999998% 25.6261314%); }
  98.33% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 248.904379% 29.094307399999998%); }
  98.61% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 249.2389396% 32.5688514%); }
  98.89% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 249.51281% 36.0487052%); }
  99.17% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 249.72590699999998% 39.5328088%); }
  99.44% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 249.8781654% 43.0201006%); }
  99.72% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 249.969539% 46.5095188%); }
  100% { clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, 250% 50%); }
}
