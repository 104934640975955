@import "../../../styles/deviants-styles.scss";

.deck-selector{
  h1{
    margin-bottom: 64px;
  }
  &-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    height: 80vh;
    overflow: scroll;
    .card-holder{
      filter: grayscale(0.5);
      cursor: pointer;
      .card{
        transform: none;
      }
      &.selectedHQ{
        filter: drop-shadow(0 0 5px $color-primary-orange);
      }
    }
  }
}

.deviants-select {
  z-index:5;
  * {
    cursor: pointer;
    outline: none;
  }
   >div::after{
    content: "";
  position: absolute;
  height: 4px;
  width: 36px;
  background: #ff5c00;
  bottom: 13px;
  left: -10px;
  transform: rotate(45deg);
  }
  >div::before{
    content: "";
  position: absolute;
  height: 4px;
  width: 36px;
  background: #ff5c00;
  top: 12px;
  right: -10px;
  transform: rotate(45deg);
  }

  svg {
    fill: white !important;
  }

  &:hover {
    cursor:pointer;
    filter: drop-shadow(-4px -4px 20px #FF5207);
  }

  > div:nth-child(3),
  > div:last-child {
    border-radius: 0;
    border: none;
    box-shadow: -4px -4px 0px 0px #FF5207 inset,4px 4px 0px 0px #FF5207 inset;
    background: linear-gradient(90deg, #0b0806 0%, #1b1f1e 50%, #0b0806 100%);
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
    color: white;
    font-family: $font-family-title;


    > div:first-child {
      > div:first-child,
      input {
        color: white;
        font-weight: bold;
        font-family: $font-family-title;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
    > div:last-child {
      margin-right: 5px;
    }
  }

  #react-select-3-listbox {
    color: white;
    font-weight: bold;
    font-family: $font-family-title;
    text-indent: 10px;
    cursor: pointer;
    > div {
      > div {
        color: white;
        font-weight: bold;
        font-family: $font-family-title;
        text-indent: 10px;
        cursor: pointer;
      }
    }
  }
}
