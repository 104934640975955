.toast-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 1vh;
  left: 0;
  z-index: 9999;
}

.toast {
  width: 400px;
  display: flex;
  text-align: center;
  justify-content: center;
  max-width: 400px;
  padding: 1rem;
  background-color: #211f1de5;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  overflow: hidden;
  animation: slideIn 0.5s ease-in-out;
}