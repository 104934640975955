@import "../../styles/deviants-styles.scss";
.nft_list{
    display: grid;
    grid-gap: 16px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(320px,1fr));
		grid-template-rows: repeat(auto-fit, minmax(300px,1fr));
    box-sizing: border-box;
    justify-self: center;
		@include media-small{
			grid-template-columns: repeat(auto-fit, minmax(420px,1fr));

		}
    @include media-xlarge{
        align-self: center;
        grid-template-columns: repeat(auto-fit, minmax(350px,1fr));
				grid-gap: 32px;
				padding: 24px;
    }
}

.nft_card{
    display: grid;
    position: relative;
    flex-direction: column;
    max-width: 300px;
    height: 560px;
    justify-self: center;
    grid-template-columns: 8px 260px 8px;
    grid-template-rows: 8px 20px 30px 350px auto 14px;
    gap: 8px;
    border: 2px solid transparent;
		@include media-small{
			grid-template-columns: 8px 360px 8px;
			grid-template-rows: 8px 20px 30px 450px auto 14px;
			max-width: 420px;
    	height: 660px;
		}
		&::after{
			content:"";
			position: absolute;
			width: 18px;
			height: 18px;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-top: 9px solid white;
			opacity: 0.1;
			bottom: -11px;
			left: -10px;
			transform: rotate(45deg);
		}
		&::before{
			content:"";
			position: absolute;
			width: 18px;
			height: 18px;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 9px solid white;
			opacity: 0.1;
			top: -11px;
			right: -10px;
			transform: rotate(45deg);
		}
		&:hover{
			&::after{
				opacity: 1;
			}
			&::before{
				opacity: 1;
			}
			.nft_background{
				border: 2px solid white;
				box-shadow: 0px 0px 32px white inset;
				&::after{
						background: white;
				}
				&::before{
						background: white;
				}
			}
		}

    .nft_image{
        grid-column: 2/3;
        grid-row: 2/5;
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
        position: relative;

    }
    .nft_background{
			grid-column: 1/4;
			grid-row: 1/7;
			clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px));
			position: relative;
			&::after{
				content: "";
				position: absolute;
				width: 49px;
				height: 2px;
				top: 0;
				right: -12px;
				transform: rotate(45deg);
			}
			&::before{
				content: "";
				position: absolute;
				width: 49px;
				height: 2px;
				bottom: 0;
				left: -12px;
				transform: rotate(45deg);
			}

    }
    .nft_title{
        grid-column: 2/3;
        grid-row: 3/4;
        z-index: 2;
        font-size: 20px;
        font-family: $font-family-title;
        display: flex;
        justify-content: center;
        font-weight: 800;
        text-transform: uppercase;
    }
    .nft_actions{
        grid-column: 2/3;
        grid-row: 5/6;
        align-self: center;
        justify-self: center;
				z-index: 2;
				padding: 4px;
    }
}

.opener-content{
	height: 100vh;
	grid-column: 1/2;
	grid-row: 1/2;
	width: 100vw;
	display: grid;
	gap: 16px;
	@include media-xlarge{
		height: 100vh;
		width: 100vw;
		padding: 32px;
		grid-template-rows: 64px auto;
	}
	.skip-animation{
		justify-self: flex-end;
		z-index: 10;
	}
	.cards-opened{
		display: none;
	}
	.menu-box{
		display: none;
	}
	&-card-list{
		height: 300px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		@include media-xlarge{
			height: 100%;
		}
		.card_container{
			width: 272px;
			height: 300px;
			>img{
				width: 100%;
			}
			.card-CHAR{
				width: 240px;
			}
			.card-TECH{
				width: 250px;
			}
		}



		.card_container{
			animation: center-grow;
			animation-duration: 1s;
			animation-iteration-count: 1;
			position: absolute;
			opacity: 0;
			animation-fill-mode: forwards;
			@include media-xlarge{
			}
			&:nth-child(1){
				animation-delay: 1s;
				z-index: 1;
				@include media-xlarge{
					position: absolute;
					animation-name: card-1;
					opacity: 0;
				}
			}
			&:nth-child(2){
				animation-delay: 2s;
				z-index: 2;
				@include media-xlarge{
					position: absolute;
					animation-name: card-2;
					opacity: 0;
				}
			}
			&:nth-child(3){
				animation-delay: 3s;
				z-index: 3;
				@include media-xlarge{
					position: absolute;
					animation-name: card-3;
					opacity: 0;
				}
			}
			&:nth-child(4){
				animation-delay: 4s;
				z-index: 4;
				@include media-xlarge{
					position: absolute;
					animation-name: card-4;
					opacity: 0;
				}
			}
			&:nth-child(5){
				animation-delay: 5s;
				z-index: 5;
				@include media-xlarge{
					position: absolute;
					animation-name: card-5;
					opacity: 0;
				}
			}
			&:nth-child(6){
				animation-delay: 6s;
				z-index: 6;
				@include media-xlarge{
					position: absolute;
					animation-name: card-6;
					opacity: 0;
				}
			}
			&:nth-child(7){
				animation-delay: 7s;
				z-index: 7;
				@include media-xlarge{
					position: absolute;
					animation-name: card-7;
					opacity: 0;
				}
			}
			&:nth-child(8){
				animation-delay: 8s;
				z-index: 8;
				@include media-xlarge{
					position: absolute;
					animation-name: card-8;
					opacity: 0;
				}
			}
		}
	}
	&.skipped{
		width: 100%;
		height: 456px;
		@include media-small{
			height: 544px;
		}
		@include media-xlarge{
			width: 100%;
			height: 100%;
		}
		.skip-animation{
			display: none;
		}
		.cards-opened{
			display: grid;
			width: 272px;
			grid-column: 1/2;
			grid-row: 1/2;
			grid-template-columns: 100%;
			grid-gap: 8px;
			@include media-xlarge{
				width: 100vw;
				height: 800px;
				grid-template-columns: 120px 1fr 120px;
				grid-template-rows: 8px 13vh 42vh 64px 19vh 8px;
				max-width: 1200px;
			}
			.left-holder{
				display: none;
				@include media-xlarge{
					height: 100%;
					background: url(../FeatCards/assets/gallery-top-left.png);
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 100%;
					grid-column: 1/2;
					grid-row: 2/5;
					display: flex;
					z-index: 2;
					background-position-x: -10px;
				}
			}
			.right-holder{
				display: none;
				@include media-xlarge{
					height: 100%;
					background: url(../FeatCards/assets/gallery-top-right.png);
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 100%;
					grid-column: 3/4;
					grid-row: 2/5;
					display: flex;
					z-index: 2;
					background-position-x: 10px;
				}
			}
		}
		.menu-box{
			display: none;
			@include media-xlarge{
				display: flex;
				grid-column: 2/3;
				grid-row: 2/6;
				flex-direction: column;
				justify-content: space-between;
				background: #121514;
				position: relative;
			}
			h1{
				margin-top: 60px;
			}
			&-bottom{
				display: flex;
				justify-content: space-between;
				height: 19vh;
				&-option{
					flex: 1 0 30%;
					padding: 20px;
					display: flex;
					flex-direction: column;
					gap: 16px;
					background: #171A19;
					align-items: center;
					justify-content: center;
					border-right: 4px solid #121514 ;
					border-left: 4px solid #121514;
					&:first-child{
						border: none;
					}
					&:last-child{
						border: none;
					}
					p:first-child{
						font-size: 18px;
					}
				}
			}
			&-card-amount{
				display: none;
				@include media-xlarge{
					display: flex;
				}
				grid-row: 4/5;
				grid-column: 2/3;
				z-index: 2;
				justify-content: flex-end;
				align-items: center;
				gap: 16px;
				margin-right: 16px;
				&-decal{
					background: url(../Banner/assets/banner-decal.png);
					height: 32px;
					width: 150px;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
				}
				&-divider{
					background: #c4c4c4 ;
					width: 200px;
					height: 2px;
					opacity: 0.1;
				}
				&-total{
					display: flex;
					gap: 8px;
					&-cards{
						font-weight: 800;
						text-transform: capitalize;
						font-size: 16px !important;
					}
					&-amount{
						font-size: 16px !important;
					}
				}
			}
		}
		.skipped-close-btn{
			justify-self: flex-end;
			grid-column: 1/2;
			@include media-xlarge{
				margin-right: 24px;
				grid-column: 3/4;
				grid-row: 2/3;
				z-index: 3;
			}
		}
		.card-opened-actions{
			display: flex;
			gap: 24px;
			justify-content: center;
			align-items: center;
			z-index: 3;
			p{
				font-weight: 800;
				@include media-xlarge{
					display: none;
				}
			}
			@include media-xlarge{
				grid-column: 2/3;
				grid-row: 4/5;
				align-self: flex-start;
				justify-self: start;
				margin-top: 20px;
				width: 100px;
				gap: 8px;
				margin-left: 100px;
			}
		}
		.alice-carousel{
			height: 300px;
			grid-column: 1/2;
			@include media-small{
				height: 380px;
			}
			@include media-xlarge{
				grid-column: 1/4;
				grid-row: 2/5;
				height: 382px;
				border-top: 3px solid rgba(254, 91, 2, 0.5);
				border-bottom: 3px solid rgba(254, 91, 2, 0.5);
				box-shadow: 0px 0px 200px 0px rgba(255, 92, 0, 0.44) inset;
			}
			.card_container{
				animation: none;
				width: 272px; // achicar para tamaño mobile chiquito 320 o menos
				.card{
					margin: auto;
					width: 100%;
				}
				.card-TECH,.card-HQ{
					width: 272px;
				}
				.card-CHAR{
					width: auto;
					height: 300px;
				}
			}
		}
		.opener-content-card-list{
			display: none;
		}
		.build-my-deck-btn{
			justify-self: center;
			@include media-xlarge{
				display: none;
			}
		}
	}
}

#modal-opener{
	&.overlay{
		background: #000000;
	}
	.modal{
			background: none;
			box-shadow: none;
			width: 100vw;
			height: 100vh;
			grid-template-columns: auto;
			grid-template-rows: auto;
			pointer-events: none;
			padding: 0;
			clip-path: none;
	}

	.content{
		margin: 0;
		padding: 0;
		@include media-small{
		min-height: 100vh;
		max-height: 100vh;
		}
		width: 100vw;
		height: 100vh;
		grid-column: 1/2;
		grid-row: 1/2;
		place-self: center;
		max-height: 100vh;
		place-items: center;
		pointer-events: none;
		&>*{
			pointer-events: auto;
		}
		.opener-content{
			padding:20px;
			width: auto;
			@include media-small{
				width: 100vw;
			}
			&.skipped{
				width: auto;
			}
		}
	}
	.bevel::after{
			display: none;
	}
	.bevel::before{
			display: none;
	}
	.video-loop{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include media-small{
			width: 125vw;
			max-width: 1200px;
		}
	}
}

.please-wait {
	width: 100%;
	text-align: center;
}

.nft_image {
	scale: 0.75;
}