a.btn.discord-button {
  background-color: #5865F2;
  color: white;
  box-shadow: -4px -4px 0px 0px rgba(144, 0, 0, 0.25) inset, 4px 4px 0px 0px rgba(255, 233, 153, 0.31) inset;
}

.discord-button-shadow {
  width: fit-content;
  text-decoration: none;

  &:hover {
    filter: drop-shadow(-4px -4px 20px #5865F2);
  }

  &.btn-disabled {
    &:hover {
      filter: drop-shadow(none);
    }
  }

  svg {
    margin-right: 15px;
  }
}