.user-profile-view {

  &__footer {
    margin-top: 4em;
    margin-bottom: 2em;
  }
}

.user-profile-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2em;
}

@media (max-width: 1100px) {
  .user-profile-container {
    flex-direction: column;
    gap: 1em;
    align-items: center;
  }
}
