.preload-card-assets {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  .preload {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: none transparent no-repeat scroll center center;
  }
}
