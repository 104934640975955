@import "../../styles/deviants-styles.scss";

.minter {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  @include media-small {
    margin: 0 40px;
  }

  &__list {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    flex: 1 1 0;
    font-size: 1.2em;
    background: rgba(255, 84, 0, 0.1);
    box-shadow: 0 0 10px rgba(255, 84, 0, .4) inset;
    padding: 10px 20px;
    margin: 5px 10px;
    border: 3px solid #813008;
    font-family: "Rajdhani";
    text-transform: uppercase;
    font-weight: 600;
    min-width: 250px;

    &:hover {
      border: 3px solid #ff5c00;
      box-shadow: 0 0 10px rgba(255, 92, 0, .8) inset;
    }

    &.active {
      background-color: #ff5c00;
      border-color: #ff5c00;
    }
  }


  &__body {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 2 1 0;
    justify-content: center;
    background: rgb(25, 25, 25);
    border-top: 8px solid rgb(30, 38, 35);
    border-right: 8px solid rgb(30, 38, 35);
    border-left: 8px solid rgb(8, 8, 8);
    border-bottom: 8px solid rgb(8, 8, 8);
    width: 100%;
    height: 100%;
    padding: 50px 20px 30px 20px;
    box-shadow: none;
    margin-top: 30px;

    @include media-small {
      padding: 20px 20px 30px 20px;
    }
  }

  &__description {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;

    &_img {
      display: flex;
      flex: 0 0 0;

      & img {
        height: 300px;
      }
    }

    &_txt {
      flex: 1 1 0;
      min-width: 300px;
      margin-top: 20px;
      font-family: "Rajdhani";

      @include media-medium {
        padding-left: 20px;
        margin: 0px;
      }
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    width: 100%;
    padding: 0;
    margin: 0px 10px 15px 10px;
    font-family: "Rajdhani";
  }

  &__price {
    display: flex;
    align-items: center;
    position: absolute;
    top: -30px;
    right: 30px;

    &__content {
      background: #ff5c00;
      clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%);
      font-size: 3rem;
      font-weight: 600;
      font-family: "Rajdhani";
      padding: 5px 10px 15px 10px;

      @include media-small {
        padding: 5px 10px 25px 10px;
      }

      & span {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1rem;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -20px;
      background: #7f330b;
      width: 20px;
      height: 23px;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
  }

  &__title {
    font-family: "Rajdhani";
    text-transform: uppercase;
    font-weight: 600;
  }

  &__bar {
    display: flex;
    align-items: center;

    & div:nth-child(2) {
      margin-left: 10px;
      font-size: .7rem;
    }
  }

  &__total-bar {
    height: 10px;
    flex: 1 1 0;
    box-shadow: 0 0 8px rgba(255, 92, 0, .6) inset;
    border-radius: 5px;
    border: 1px solid #713008;
  }

  &__selled-bar {
    height: 8px;
    background: #ff5c00;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(255, 92, 0, .8), 0 0 5px rgba(255, 255, 255, .5);
    transition: all .3s ease-in-out;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 2em;
    padding: 0;
    margin: 0;
  }

  &__amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;

    &-txt {
      width: 40px;
      text-align: center;
    }
  }

  &__total {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;

    & div {
      &:nth-child(1) {
        color: #ff5c00;
        font-size: .9rem;
      }

      &:nth-child(2) {
        font-size: 1.4rem;
        margin: -4px 0;
      }

      &:nth-child(3) {
        color: #ff5c00;
        font-size: .7rem;
      }
    }
  }

  &__buy {
    display: flex;
    align-items: center;
    margin-left: 15px;
    width: 250px;

    #minter-buy-button {
      flex: 1 1 0;
      width: auto;
    }
  }

  &__bundle {
    display: flex;
    justify-content: center;
    flex: 1 1 0;
  }
}

.new-line {
  white-space: pre-line;
}

.bundle-icon {
  margin: -50px auto 0;
}

.btn-plus-ten {
  font-size: 0.7em;
}

.btn.btn-small.minter__amount--plus {
  clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  margin-left: -3px;

  &:hover {
    background: #ff5c00
  }

  &::before {
    display: none;
  }
}

.btn.btn-small.minter__amount--plus.btn-plus-ten {
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 0 100%, 0 10px);

  &::before {
    display: block;
  }

  &::after {
    display: none;
  }
}


.btn.btn-small.minter__amount-minus {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10px 100%, 0 calc(100% - 10px));
  margin-right: -3px;

  &:hover {
    background: #ff5c00
  }

  &::before {
    display: none;
  }
}


.btn.btn-small.minter__amount-minus.btn-plus-ten {
  clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 0 100%);

  &::before {
    display: block;
  }

  &::after {
    display: none;
  }
}


@media (max-width: 800px) {
  .bundle-icon {
    display: none;
  }
}