@import "styles/deviants-styles.scss";

html,
body {
  margin: 0;
  background: linear-gradient(90deg, rgba(11,8,6,1) 0%, rgba(27,31,30,1) 50%, rgba(11,8,6,1) 100%);;
  color: #fff;

}
body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background-color: #141512;
}
body::-webkit-scrollbar-thumb {
  background-color: #ff5c00;
  border-radius: 20px;
  border: 3px solid #141512;
}
body > iframe {
  display: none !important;
}
.scroll {
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: #141512;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ff5c00;
    border-radius: 20px;
    border: 3px solid #141512;
  }
}
html {
  font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
button{
  border:0;
  padding: 0;
}
section{
  margin-bottom: 128px;
}

.message-alert {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 32px;
  gap: 32px;
}

.deck-wrapper > .library {
  overflow-x: hidden;
}
