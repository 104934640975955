.tournament-rounds {
  display: flex;
  justify-content: center;

  &__round {
    font-family: 'Rajdhani';
    font-weight: 700;
    text-align: center;
    margin-left: 20px;
    padding: 15px;
    width: 73px;
    // border-bottom: 3px solid #C4C4C4;
    // border-left: 3px solid #C4C4C4;
    position: relative;

    &--current {
      border-color: rgb(235, 90, 0);

      &::after {
        background: rgb(235, 90, 0) !important;
        filter: drop-shadow(0px 0px 8px rgb(235, 90, 0))
      }

      &::before {
        background: rgb(235, 90, 0) !important;
        filter: drop-shadow(0px 0px 8px rgb(235, 90, 0))
      }
    }

    &--future {
      color: rgb(60, 60, 60);

      &::after {
        background: rgb(60, 60, 60) !important;
      }

      &::before {
        background: rgb(60, 60, 60) !important;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 63px;
      height: 3px;
      background: white;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 5px;
      left: -2px;
      display: block;
      width: 15px;
      height: 3px;
      background: white;
      transform: rotate(45deg);
    }
  }
}