.prize-pool {

  >div,
  .money>div {
    font-family: 'Rajdhani';
    font-weight: 700;
    text-transform: uppercase;
  }

  & div:nth-child(2) {
    display: flex;
    align-items: center;
  }
  &__rewards {
    display: flex;
    gap: 1ch;
    text-align: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
    }
  }
  &__booster {
    font-size: 2rem;
    
    img {
      height: 48px;
      margin-bottom: -8px;
      margin-right: 5px;
    }

    span {
      font-size: 0.9rem;
      display: inline-block;
    }
  }

  .reward-item__position__piece{

    img {
      height: 26px;
    }
    
    span {
      margin-left: 5px;
    }
  }
}