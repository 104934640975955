@import "media-query.scss";
@import "typography.scss";
@import "variables.scss";
@import "animations.scss";

body,
html {
	background: linear-gradient(90deg, rgb(15, 12, 10) 0%, rgb(27, 31, 30) 50%, rgb(11, 8, 6) 100%);
}

main {
	background: url(/assets/images/noise.png);
}

.wrapper {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 8px;
	width: 1440px;
	justify-self: center;
}

section.desktop h1 {
	color: $color-primary-orange;
	font-weight: 800 !important;
}

.btn-left svg #chevron {
	transform: rotate(90deg) translate(0px, 2px);
	transform-origin: center;
}

.btn:hover svg #chevron {
	fill: #fff;
}

.btn-right svg #chevron {
	transform: rotate(-90deg) translate(0px, 2px);
	transform-origin: center;
}

.btn svg #chevron {
	fill: #fff;
}

#launcher-frame {
	margin-bottom: 50px;
}

.CookieConsent {
	border-top: rgb(255, 92, 0) 1px solid;
}