@import "../../../styles/deviants-styles.scss";
$deviant-border: 2px solid #813008;
.deck {
    max-width: 485px;
    max-height: 1200px;
    display: flex;
    flex: 1;
    padding: 31px;
    padding-bottom: 0;
    position: sticky;
    top:110px;
    margin-bottom: 128px;
    // height: calc(100vh - 280px);
    &__head {
        width: 100%;
        height:80px;
        margin-left: -31px;
        position: absolute;
        top: 0;
        background-image: url(./assets/deck-head.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
    &__container {
        z-index: 0;
        display: flex;
        flex:1;
        flex-direction: column;
        background: rgba(255, 84, 0, 0.1);
        box-shadow: 0 0 150px rgba(255, 84, 0, .4) inset;
        border: $deviant-border;
        clip-path: polygon(calc(100% - 30px) 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 30px 100%, 0 calc(100% - 30px), 0 30px, 30px 0);
        &.transparent{
            position: absolute;
            background: transparent;
            box-shadow: none;
            border: none;
        }
    }
    &::before {
        content: '';
        position: absolute;
        background-color: #813008;
        width: 40px;
        height: 2px;
        bottom: 15px;
        left: 26px;
        transform: rotate(45deg);
    }
    &::after {
        content: '';
        position: absolute;
        background-color: #813008;
        width: 40px;
        height: 2px;
        bottom: 15px;
        right: 26px;
        transform: rotate(-45deg);
    }
   
}
