.scroll-style{
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ff5c00;
    border-radius: 20px;
    border: 3px solid rgba(0,0,0,0.3);
  }
}