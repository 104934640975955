.store-view {
  // background-image: url(../../components/Hero/assets/bg.png);
  margin-top: -40px;
  padding-top: 50px;

  &__title {
    margin-bottom: 2em;
  }

  &__subtitle {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    div {
      font-family: "Rajdhani";
    }

    display: flex;
    justify-content: center;
    column-gap: 0.75ch;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .minter-component {}

  &__footer {
    margin-top: 4em;
    margin-bottom: 2em;
  }
}

.pack-sale {
  float: right;
  width: auto;
  color: orange;
  padding-left: 5px;
  font-size: 10px;
  text-shadow: 1px 1px black;
}

.smaller-text {
  font-size: 3em;
}


.store-banner-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.img-banner-size {
  width: 100%;
  max-width: 1230px;
}