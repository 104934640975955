.subscription-error-alert {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 10px;
  border-radius: 5px;
  background-color: #f8c0b3;
  color: orangered;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  transition: all 0.3s ease-in-out;
}