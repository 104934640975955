@keyframes bottom-top-fade {
  0%{
    transform: translateY(600px);
    opacity: 0;
  }
  45%{
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-opacity {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes card-1 {
  0%{
    top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 40%;
    left: 5%;
    transform: translate(0,0);
    opacity: 1;
  }
}
@keyframes card-2 {
  0%{
    top: 50%;
		left: 60%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 20%;
    left: 20%;
    transform: translate(0,0);
    opacity: 1;
  }
}
@keyframes card-3 {
  0%{
    top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 10%;
    left: 40%;
    transform: translate(0,0);
    opacity: 1;
  }
}

@keyframes card-4 {
  0%{
    top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 10%;
    left: 60%;
    transform: translate(0,0);
    opacity: 1;
  }
}
@keyframes card-5 {
  0%{
    top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 40%;
    left: 80%;
    transform: translate(0,0);
    opacity: 1;
  }
}
@keyframes card-6 {
  0%{
    top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 60%;
    left: 60%;
    transform: translate(0,0);
    opacity: 1;
  }
}
@keyframes card-7 {
  0%{
    top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 60%;
    left: 40%;
    transform: translate(0,0);
    opacity: 1;
  }
}
@keyframes card-8 {
  0%{
    top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
  }
  45%{
    opacity: 0;
  }
  100%{
    top: 60%;
		left: 20%;
    transform: translate(0,0);
    opacity: 1;
  }
}

@keyframes center-grow {
  0%{
    transform: scale(0.1);
    opacity: 0;
  }
  45%{
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade-opacity {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
