.desc-inside {
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.desc-inside .line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
}

.larger .desc-inside .line {
  font-size: 22px;
}

.desc-inside .line:first-child {
  margin-top: -10px;
}

.desc-inside .line:first-child:nth-last-child(2),
.desc-inside .line:first-child:nth-last-child(2) ~ .line {

}

.desc-inside .line:first-child:nth-last-child(4),
.desc-inside .line:first-child:nth-last-child(4) ~ .line {
  margin: 0;
  height: 29px;
}

.desc-inside b {
  font-weight: bold;
  margin: 0 5px;
}
.desc-inside .card-desc-step {
  font-size: 20px;
  font-weight: bold;
  color: black;
  background: white;
  padding: 0 5px;
  background: linear-gradient(180deg, #e9e9e9 60%, #c0c0c0 80%, #c0c0c0 100%);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  margin: 0 5px;
  border: 1px solid black;
  border-radius: 2px;
}
.desc-inside .card-desc-arrow {
  font-size: 20px;
  font-weight: bold;
  color: black;
  background: #49c7bb;
  padding: 0 15px 0 10px;
  border-radius: 40px;
  margin: 0 5px 0 5px;
  position: relative;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.desc-inside .card-desc-arrow * {
  font-size: 25px;
  font-weight: bold;
  z-index: 1;
  position: relative;
}
.desc-inside .card-desc-arrow:before {
  content: "";
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #49c7bb;
  clear: both;
}
.desc-inside .card-desc-arrow .card-desc-faction {
  filter: brightness(0%);
  width: 20px;
  height: 20px;
}
.desc-inside .card-desc-arrow .card-desc-gender {
  filter: brightness(0%);
  width: 20px;
  height: 20px;
}
.desc-inside .card-desc-faction {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 4px;
}
.desc-inside .card-desc-faction .cd-icon-faction {
  display: block;
  width: 100%;
  height: 100%;
  background: none transparent scroll no-repeat center center;
  background-size: contain;
}
.desc-inside .card-desc-faction .cd-icon-faction.awaken {
  background-image: url(./assets/markup/awaken.png);
}
.desc-inside .card-desc-faction .cd-icon-faction.entropy {
  background-image: url(./assets/markup/entropy.png);
}
.desc-inside .card-desc-faction .cd-icon-faction.inhuman {
  background-image: url(./assets/markup/inhuman.png);
}
.desc-inside .card-desc-faction .cd-icon-faction.owner {
  background-image: url(./assets/markup/owner.png);
}
.desc-inside .card-desc-gender {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.desc-inside .card-desc-gender .icon-gender {
  display: block;
  width: 100%;
  height: 100%;
  background: none transparent scroll no-repeat center center;
  background-size: contain;
}
.desc-inside .card-desc-gender .icon-gender.male {
  background-image: url(./assets/markup/male.png);
}
.desc-inside .card-desc-gender .icon-gender.female {
  background-image: url(./assets/markup/female.png);
}
.desc-inside .card-desc-gender .icon-gender.non-binary {
  background-image: url(./assets/markup/non-binary.png);
}

.desc-inside .promo {
  display: block;
  width: 550px;
  height: 300px;
  background: url(./assets/markup/promo.png) transparent scroll no-repeat center center;
  background-size: contain;
}
