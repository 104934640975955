@import "../../styles/deviants-styles.scss";

.investors-section{
  display: grid;
  margin-bottom: 72px;
  width: 100%;
  &-list{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    gap: 16px;
    align-items: center;
    @include media-medium{
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: space-evenly;
      justify-self: center;
    }
    @include media-large{
      gap: 16px;
      width: 90%;
    }
  }
  &-brand{
    display: flex;
    justify-content: center;
    border: 20px solid white;
    border-image: url(./assets/brand-border.png) 30 round;
    width: 220px;
    box-sizing: content-box;
    padding: 16px;
    
    @include media-medium{
      flex: 0 0 32%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    @include media-large{
      flex: 0 0 20%;
      padding-left: 80px;
      justify-content: center;
      height: 90px;
      padding: 0px 64px;
    }
    &:hover{
      filter: drop-shadow(0px 0px 11px #FF5207);
    }
    img{
      
    }
  }
}
