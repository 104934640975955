.economytools__error_box {
  color: orangered;
  font-weight: 500;
  font-size: 1.15em;
}

.ec-ticket-prices-container {
  display: flex;
  column-gap: 1ch;
  .ec-ticket-price-container {
    display: flex;
    column-gap: 1ch;
    align-items: center;
  }
}

.select-schema-message {
  display: grid;
  place-content: center;
}

.deviants-textarea {
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
  background-color: black;
  outline: none;
  color: #fff;

  &:focus-visible {
    outline: none;
  }
}

.json-editor {
  background-color: black;
  color: #fff;
}

.tournament-toolbar {
  display: flex;
  gap: 1ch;
  margin-left: 1ch;

  .copy-tournament-object {
    color: orangered;
    cursor: pointer;
  }
}