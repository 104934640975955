@import "../styles/deviants-styles.scss";

// INVENTORY

.inventory {
  &-view {
    display: grid;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 120px 1fr;
    &-loaded {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
      &-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 8px;
        & .btn {
          margin: 32px 0px;
        }
      }
      .modal {
        .content {
          max-height: 570px;
        }
        &-nft {
          display: grid;
          grid-template-columns: 260px;
          grid-template-rows: 8px 8px 30px 300px auto 14px;
          height: 510px;
          gap: 16px;
          &_title {
            grid-column: 1/2;
            grid-row: 3/4;
            align-self: center;
            justify-self: center;
            z-index: 2;
            text-transform: uppercase;
            font-weight: 800;
          }
          &_image {
            grid-column: 1/2;
            grid-row: 2/5;
            background-size: cover;
            background-position: center;
          }
          &_actions {
            grid-column: 1/2;
            grid-row: 5/6;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;

            .btn-shadow,
            .btn {
              width: 100%;
            }
          }
        }
      }
    }

    .connect-wallet {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
    }
  }
}

// DECK BUILDER
.empty-deck {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 90vw;
  margin: auto;
  @include media-large {
    width: 60vw;
  }
  @include media-large {
    height: 70vh;
  }
  h2 {
    margin-top: 8px;
  }
  h3 {
    margin-top: 32px;
  }
  p {
    font-family: $font-family-title;
    font-size: 22px;
    width: 240px;
    text-align: center;
    @include media-small {
      width: 100%;
    }
  }
  .actions {
    margin-top: 64px;
    display: flex;
    gap: 16px;
    margin-bottom: 48px;
    flex-direction: column;
    @include media-small {
      flex-direction: row;
    }
  }
}

// ERROR
.error-view {
  display: flex;
  flex-direction: column;
  .error {
    &-wrapper {
      display: flex;
      height: 70vh;
      flex-direction: column-reverse;
      @include media-large {
        flex-direction: row;
      }
    }
    &-message {
      width: 100%;
      @include media-large {
        width: 50%;
      }
      padding: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 32px;
    }
    &-title {
      font-weight: 800;
      font-family: $font-family-title;
      font-size: 50px;
      color: $color-primary-orange;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      @include media-large {
        text-align: left;
      }
    }
    &-type {
      font-weight: 800;
      font-family: $font-family-title;
      font-size: 40px;
      text-align: center;
      width: 100%;
      @include media-large {
        text-align: left;
      }
    }
    &-description {
      font-weight: 800;
      font-family: $font-family-title;
      font-family: 18px;
      width: 100%;
      text-align: center;
      @include media-large {
        text-align: left;
      }
    }
    &-actions {
      display: flex;
      gap: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      @include media-large {
        flex-direction: row;
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      background-image: url(../assets/images/error.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
.maintenance {
  &-view {
    display: flex;
    flex-direction: column;
    margin-top: -70px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-family-title;
    justify-content: center;
    height: 100vh;
    gap: 32px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      background-image: url(../assets/images/maintenance.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }
  &-title {
    text-transform: uppercase;
    font-family: $font-family-title;
    font-size: 48px;
    z-index: 2;

    @include media-small {
      font-size: 64px;
    }
  }
  &-description {
    width: 100%;
    font-family: $font-family-title;
    text-align: center;
    font-size: 28px;
    text-transform: uppercase;
    z-index: 2;

    @include media-small {
      font-size: 40px;
    }
  }
  &-actions {
    display: flex;
    gap: 16px;
    flex-direction: column;
    z-index: 2;

    @include media-small {
      flex-direction: row;
    }
  }
  &-message {
    font-family: $font-family-title;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    z-index: 2;
  }
  &-social-title {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 16px;
    z-index: 2;
  }
}
.net-indicator {
  width: 100%;
  text-align: center;
  font-weight: bolder;
  top: 0;
  height: auto;
  background: rgb(255, 248, 0);
  background: radial-gradient(circle, rgba(255, 248, 0, 1) 0%, rgba(255, 248, 0, 1) 50%, rgba(255, 248, 0, 0) 100%);
  text-transform: uppercase;
  color: black;
  font-size: 10px;
  font-family: $font-family-title;
}

// PROFILE
.userprofile-view {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  height: 100vh;
  .profile {
    width: 100%;
    padding: 0px 4px;
    @include media-large {
      width: 1024px;
    }
    h1 {
      text-transform: uppercase;
      text-align: start;
    }
    h2 {
      color: white;
      font-size: 24px;
      text-align: start;
    }
  }
  .data {
    display: none;
  }
  .user-name {
    font-family: $font-family-title;
    font-weight: 800;
    font-size: 24px;
    word-break: break-all;
    text-transform: uppercase;
  }
  .avatar-img {
    min-width: 80px;
    min-height: 80px;
    background-size: contain;
    border-radius: 100%;
    @include media-large {
      min-width: 48px;
      min-height: 48px;
    }
    &.awaken {
      background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/awakenF_2.png");
    }
    &.entropy {
      background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/entropyM.png");
    }
    &.inhuman {
      background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/inhumanM.png");
    }
    &.owner {
      background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/OwnerF.png");
    }
  }

  .user-data {
    display: flex;
    gap: 16px;
    flex-direction: column;
    @include media-large {
      flex-direction: row;
      width: 40%;
    }
    .user-profile {
      display: flex;
      flex-direction: row;
      padding: 16px;
      background: #171a19;
      border: 1px solid #2f3a37;
    }
    .user-wallet-data {
      background: #171a19;
      border: 1px solid #2f3a37;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      p {
        font-family: $font-family-title;
        text-transform: uppercase;
        font-weight: 800;
      }
      .wallet-data-title {
        margin-bottom: 16px;
        font-size: 20px;
      }
      .wallet-address {
        word-break: break-all;
        width: 95%;
      }
      .wallet-currencies {
        .single-currency {
          display: flex;
          gap: 8px;
          .currency-type {
          }
          .currency-amount {
          }
        }
      }
    }
  }
  .game-data {
  }
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin: auto;
    gap: 8px;
    @include media-large {
      width: 600px;
    }
    .icon {
      margin-bottom: 16px;
    }
    h1 {
      margin-bottom: 24px;
      text-transform: uppercase;
    }
    p {
      font-family: $font-family-title;
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 16px;
      text-align: center;
    }
  }
  #profile-overlay {
    .first-step {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      p.description {
        text-align: center;
      }
      h2 {
        font-size: 24px;
      }
    }
    .second-step {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      .name-list {
        margin: 24px 0px;
        width: 300px;
        p.single-item {
          position: relative;
          input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            & ~ label {
              font-family: $font-family-title;
              width: 100%;
              font-weight: 800;
              display: inline-block;
              padding: 4px;
            }
            &:checked {
              & ~ label {
                background: grey;
                padding: 4px;
              }
            }
          }
        }
      }
    }
    .third-step {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      .photo-list {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        width: 70%;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
        .single-photo {
          position: relative;
          width: 120px;
          height: 120px;
          input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 2;
            & ~ img {
              width: 120px;
              height: 120px;
              filter: grayscale(1);
            }
            &:checked ~ img {
              width: 120px;
              height: 120px;
              filter: grayscale(0);
              filter: drop-shadow(-4px -4px 20px #ff5207);
            }
          }
        }
      }
    }
    .end-step {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      h1 {
        color: #13db27;
        margin: 24px 0px;
      }
    }
  }
}
@import "../css/inventory.scss";
@import "../css/deck-builder.scss";
@import "../css/market-place.scss";
@import "../css/project-info.scss";
@import "../css/error.scss";
@import "../css/play-view.scss";
@import "../css/profile.scss";
@import "../css/tutorial.scss";
@import "../css/bridge.scss";
.error-while-listing{
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    >button {
      max-width: 250px;
    }
  }
}

.zIndexSup {
  z-index: 4;
}

.ban-message {
  background-color: #ff510799;
  box-shadow: inset 0px 0px 10px 0px #ff5107d6;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-family: $font-family-title;
}