  .user-balances-wrapper {
    width: 100%;
    padding: 2ch 5ch;
    margin-bottom: 10ch;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    height: 150px;
    &__icon {
      height: 24px;
      margin-bottom: -1px;
      margin-right: -2px;
    }

    &__icon-second {
      margin-left: 8px;
    }

    &__tickets {
      box-shadow: -27px -18px 22px -9px rgba(0, 0, 0, 0.3),
        1.5px 5px 2px 0px rgba(92, 92, 92, 0.22) inset;
      clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
      background-color: #131212;
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      padding: 0.5ch 0 2ch 0;
    }

    &__name {
      font-size: 0.6rem;
      padding-top: 0.5ch;
    }

    &__tickets-container {
      clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
      box-shadow: -1px -2px 1.5px -1px rgba(66, 61, 61, 0.75) inset, 9px 8px 5px 0px rgba(0, 0, 0, 0.75) inset;
      border-radius: 4px;
      background-color: rgba(14, 14, 14, 0.929);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 85%;
      padding: 2ch 0;
    }

    &__tickets-container-title {
      font-family: 'Rajdhani';
      font-weight: 600;
      display: grid;
      font-size: 1.5rem;
      color: rgba(116, 108, 108, 0.825);
      place-content: center;
      text-align: center;
      width: 25%;
      height: 100%;
    }

    &__item {
      padding: 1ch;
      align-items: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      width: 20%;
      font-size: 0.85rem;
      position: relative;
      &__badge {
        position: absolute;
        background-color: #c84910;
        padding: 1px 0.5ch;
        border-radius: 4px;
        font-weight: bold;
      }
    }
  }