.vol-catalog {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 0.1em;
  align-items: center;
  position: relative;
  z-index: 1;
  max-width: 600px;
  width: 600px;

  .gridjs-table {
    //width: fit-content;
    margin: 0px;
    width: 100%;
  }

  .modal {
    padding: 0 24px 0px 24px;
  }
}

.account-age-restriction {
  background-color: #0f1010;
  padding: 5px;
  border-radius: 4px;
  margin: 5px;
}

.metal-holder-left {
  position: absolute;
  z-index: 9;
  right: -4.5%;
  top: -30px;
}

.vol-catalog-title-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2C1711;
  clip-path: polygon(0% 40px, 0% 100%, 100% 100%, 100% 0%, 40px 0%);
  width: -webkit-fill-available;
}

.title-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2C1711;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 40px, calc(100% - 40px) 0%);
  width: -webkit-fill-available;
}

.title {
  padding-top: 1ch;
  font-weight: 700;
  font-size: 60px;
  letter-spacing: -0.04em;
  color: rgba(255, 92, 0, 0.6);
  text-shadow: 0px 0px 16px rgba(255, 66, 0, 0.7);
  -webkit-text-stroke: 2px rgb(255, 92, 0);
}

.vol-catalog-grid {
  background: #1B1F1E;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  padding-bottom: 20px;
  clip-path: polygon(0% 0%, 0% calc(100% - 40px), 40px 100%, 100% 100%, 100% 0%);
  width: -webkit-fill-available;
}

.vol-catalog-name-container {
  display: flex;
  gap: 0.5ch;
  width: 40%;
  align-items: center;
  font-family: 'Rajdhani';
}

.vol-catalog-name {
  font-family: 'Rajdhani';
  font-size: 1.1rem;
  text-align: start;
}

.vol-in-wallet {
  font-family: 'Rajdhani';
  font-size: 0.75rem;
  font-weight: 500;
  text-align: start;
}

.convert-button-wrapper {
  width: 20%;
}

.btn-convert {
  height: 40px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 8% 100%, 0 87%);
  border-radius: 2px;

  &.disabled {
    background-color: #202322;
    cursor: not-allowed;
    color: gray;
    text-decoration: line-through;
  }
}

.vol-catalog-tab-container {
  display: flex;
  justify-content: center;
}

.vol-catalog-tab {
  font-family: 'Rajdhani';
  font-size: 18px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
  cursor: pointer;
}

.vol-catalog-tab-enabled {
  color: rgb(255, 92, 0);
}

.description-container {
  font-family: 'Rajdhani';
  font-size: 0.77rem;
  justify-self: center;
  font-weight: 600;
  width: 40%;
}

.modal-message {
  display: flex;
  text-align: center;
}

.modal-button {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-button .btn {
  width: 128px;
}

@media (max-width: 1200px) {
  .vol-catalog {
    width: 600px;
  }
}

.convertion-cell {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1.5ch;
  margin-top: 1ch;
  background-color: #171b1b;
  padding: 1ch 2ch;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
  box-shadow: inset 6px 10px 10px 0px #0f1010, 7px 9px 0px 0px rgba(186, 186, 186, 0.32);

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.qty-input {
  background-color: rgb(18, 18, 18);
  outline: none;
  color: #fff;
  padding: 1ch;
  width: 50%;
  font-size: 1.3rem;
  text-align: end;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Rajdhani';
  margin-right: 1ch;
  padding-right: 2.5ch;
}