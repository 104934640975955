.league-ranking-prize {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1ch;
  padding: 0.25ch;
  position: relative;
 
  &__preview {
    border-radius: 4px;
    border: 1px solid rgba(60, 58, 59, 0.589);
    background-color: rgba(0, 0, 0, 0.217);
    display: flex;
    flex-direction: row;
    width: 120px;
    align-items: center;
    justify-content: center;
    column-gap: 1ch;

    &--popup {
      opacity: 0;
      position: absolute;
      display: flex;
      left: 100%;
      top: 9px;
      background-color: rgba(225, 108, 18, 0.4);
      border: 1px solid orangered;
      border-radius: 4px;
      flex-direction: column;
      align-items: center;
      column-gap: 1ch;
      padding: 0.5ch;
      
      &--title {
        font-size: 0.9rem;
      }
      &--name {
        font-size: 0.65rem;
        white-space: nowrap;
      }
    }
  }

  &--n {
    font-size: 14px;
    font-weight: 400;
    background-color: #e3910c66;
    padding: 0.2ch;
    border-radius: 4px;
    font-style: italic;
  }

}