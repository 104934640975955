@import "../../styles/deviants-styles.scss";


.checkbox {
  display: inline-flex;
  position: relative;
  user-select: none;
  min-height: 32px;
  min-width: 32px;
  align-items: center;

  input {
    opacity: 0;
    cursor: pointer;
    min-height: 18px;
    min-width: 18px;
    max-width: none;
    max-height: none;
    border: none;
    padding: 0;
    margin: 0;

    &:checked {
      ~ .checkbox--checkmark:after {
        display: block;
      }

      &:enabled ~ .checkbox--checkmark {
        background-color: black;
        border-color: $color-primary-orange;
      }
    }

    &:disabled {
      cursor: not-allowed;

      ~ .checkbox--checkmark {
        background-color: gray;
        border-color: grey;
      }

      &:checked ~ .checkbox--checkmark {
        border-color: $color-primary-orange;

        &:after {
          border-color: $color-primary-orange;
        }
      }
    }
  }
}

.checkbox input,
.checkbox--checkmark {
  position: absolute;
  left: 7px;
}

.checkbox--checkmark {
  height: 20px;
  width: 20px;
  border: 2px solid $color-primary-orange;
  border-radius: 2px;
  background-color: black;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 4px;
    right: 1px;
    width: 14px;
    height: 5px;
    border: solid $color-primary-orange;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
    pointer-events: none;
  }
}

.checkbox--text {
  font-size: 16px;
  color: white;
  margin-left: 40px;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 120px;
	font-family: $font-family-title;
	font-weight: 800;
	text-transform: uppercase;
}
