.page-header {
  height: 250px;
  width: 100%;
  position: relative;
  z-index: 3;

  &__hero-curve {
    position: absolute;
    height: 350px;
    width: 100%;
    top: -45%;
    z-index: 1;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.327);
    position: absolute;
    height: 350px;
    width: 100%;
    top: -45%;
    object-fit: cover;
    z-index: 1;
  }

  &__hero {
    position: absolute;
    height: 350px;
    width: 100%;
    top: -45%;
    object-fit: cover;
    z-index: 1;
  }

  &__subtitle {
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
    font-size: 1.2rem;
    text-align: center;

    &__link {
      font-weight: bold;
      color: #fff;
      text-decoration: none;
      position: relative;
      z-index: 4;
      text-align: center;
    }
  }


  &__title {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 4;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
  }
}