.league-header {
  height: 180px;
  width: 100%;
  position: relative;
  
  &__hero-curve {
    position: absolute;
    height: 350px;
    width: 100%;
    top: -45%;
    z-index: 1;
  }
  
  &__hero {
    position: absolute;
    height: 350px;
    width: 100%;
    top: -45%;
    object-fit: cover;
    object-position: 100% 55%;
    filter: blur(1.5px);
    z-index: 1;
  }

  &__subtitle {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
  }
  &__title {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 2;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}