.twitter-button {
  background-color: black;
  padding: 5px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  font-family: "Rajdhani";
  cursor: pointer;

  &:hover {
    background-color: #080809;
  }
}