@import "../../../styles/deviants-styles.scss";

.card-holder{
	width: 100%;
	height: 100%;
  cursor: pointer;
	color: white;
  background: none no-repeat center;
  background-size: contain;
}

.disabled-card {
	filter: gray-scale(1);
}

// External Cards behaviors (alpha)
.modal{
	.card-holder{
		width: 264px;
		@include media-small{
			width: 320px;
		}
	}
}

.deck-selector__header{
	.hq{
		.card-holder{
			width: 332px;
		}
	}
}
.deck__cards{
	.card-holder{
		.card{
			width: 230px;
		}
	}
}
.card-detail-table{

	tr:nth-child(odd) {
		background: rgba(0,0,0,0.3);
	  }
	tr{
		border: none;
		border-spacing: 0;
		>td{
		padding: 5px;
		}
  }
}

