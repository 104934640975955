@import "../styles/deviants-styles.scss";

@mixin inv-media-medium {
  @media (max-width: 768px) { //sucio ortega
    @content;
  }
}


.play-view{
  .faction-section__top {
    max-width: 1100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 15px 0 15px;
    margin: 40px auto 30px;

    @include inv-media-medium  {
      flex-direction: column-reverse;
    }

    .faction-deckselect {
      min-width: 500px;
      @include media-mobile {
        max-width: 90vw;
        min-width: 90vw;

      }
    }
  }

  .faction-section__container {
    padding: 15px;
    max-width: 1100px;
    margin: 40px auto 30px;
  }
  .deck-selector{
    width: 550px;
    &__header {
      display: flex;
      justify-content: space-around;

      @include inv-media-medium {
        flex-direction: column-reverse;
      }
      .hq {
        display: flex;
        justify-content: center;
        .card {
          margin: 40px 0 80px 0;
        }
      }
    }
    &__title {
      font-size: 2.3rem;
      font-weight: 700;
      transform: scaleY(1.2);
      margin: 10px 0 20px 0;
      color: white;
      position: relative;
    }
    &__detail {
      margin-left: 50px;
      @include inv-media-medium {
        margin-left: 0;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__buttons {
      display: flex;
      justify-content: space-evenly;
      margin: 10px;
      flex-direction: row;

      @include media-mobile {
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
      }

      a {
        height: 56px;
        margin-left: 20px;
        @include media-mobile {
          margin-bottom: 20px;
        }
      }
    }
  }

  @include media-mobile {
    .card-HQ {
      transform: scale(0.95) !important
    }
  }

  .deviants-select {
    z-index: 1;
  }

  h1 {
    text-transform: uppercase;
    font-size: 2.3rem;

    @include media-mobile {
      margin-bottom: 20px;
    }
  }

  &-deck-content{
    flex: 1 0 75%;
  }

  .deck__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    .card-holder {
      transition: all 300ms ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      .card{
        transform: none;
      }
    }
  }


}

.card-detail-modal {
  display: flex;
  flex-direction: row;
}
