#deviants-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  opacity: 0;
  visibility: hidden;

  &.started {
    transition-duration: 0.3s;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  
}
.deviants-loader {
  display: flex;
  align-items: center;
  justify-content:center;
  position: relative;
  overflow: hidden;
  width: 170px;
  height: 170px;
  border-radius: 85px;

  .bg {
    top: 73%;
    left: 43%;
    transform: translate(-50%, -50%);
    width: 343px;
    height: 343px;
    position: absolute;
    background: transparent url(../../assets/loader/connecting.png) scroll no-repeat top left;
    background-size: 343px auto;
  }

  .loader-cont {
    width: 211px;
    height: 211px;
    position: absolute;

    animation: blinker-skip 0.5s linear infinite;

    .loader {
      width: 211px;
      height: 211px;
      position: absolute;
      background: transparent url('../../assets/loader/circle.png') scroll no-repeat center center;
      background-size: 211px auto;
    }
  }

  .logo {
    width: 159px;
    height: 159px;
    position: absolute;
    margin: 0 5px 0 0;
    background: transparent url('../../assets/loader/logo.gif') scroll no-repeat center center;
    background-size: contain;
    mix-blend-mode: screen;
  }
}
