@import "../../styles/variables.scss";

.reward {
  background: rgba(0, 0, 0, 0.2);
  padding: 15px 15px 8px 15px;
  width: 95%;
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .achievement {
      padding: 20px;
      width: -webkit-fill-available;
    }
  }

  &__content-img {
    position: relative;
    padding: 10px;
    height: 70px;
    width: 70px;
  }
  &__img {
    height: 100%;
    // width: 100%;
  }

  &__line {
    position: absolute;
    right: 0px;
    height: 43px;
    width: 0.5px;
    background: #FFFFFF;
    opacity: 0.1;
    top: 13.5px
  }
  &__achievement {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__achievement-title {
    font-family: $font-family-title;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 5px;
  }
  &__achievement-content {
    width: -webkit-fill-available;
  }
  &__achievement-description {
    font-family: $font-family-title;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
  }

  &__container-progress-bar {
    margin-top: 21px;
    display: flex;
  }
  &__progress {
    align-self: flex-end;
    font-family: $font-family-title;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 8px 0;
  }

  &__claim {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 8px;
  }
  &__name {
    font-family: $font-family-title;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  @media screen and (min-width: 1050px) {
    // margin: 18px auto;
    //max-width: 1400px;
    min-width: 500px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 40px 40px;
    padding: 24px;
    box-shadow: inset 0 -0.5px 1px rgba(100, 100, 100, 0.1);
    &__content-img {
      margin: 0;
      height: 120px;
      width: 120px;
    }
    &__line {
      height: 95px;
    }
    &__header {
      width: calc(100% - 150px);
    }
    .achievement {
      width: 100%;
      padding: 0px 33px;
    }
    &__achievement-content-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .reward__progress {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 24px;
      }
    }
    &__container-progress-bar {
      display: none;
    }
    &__claim {
      display: flex;
      flex-direction: column;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      border-top: none;
      padding-left: 24px;
      min-width: 300px;
      max-width: 300px;
    }
    &__name {
      margin-bottom: 21px;
      font-size: 24px;
      text-align: center;
    }
  }
}