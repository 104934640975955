// Bridge

section.faction-section {
  &.bridge {
    .faction-section__container {
      div {
        h2 a {
          color: inherit;
          font-family: inherit;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          padding: 1em;
        }
      }
      h5 {
        margin-top: 0.4em;
        a {
          color: white;
        }
      }
    }
    p {
      text-align: center;
      &.disclaimer {
        font-size: 1em;
        margin-top: 1em;
      }
      &.help {
        margin-top: 1em;
        a {
          color: #A8A9A9;
          text-decoration: none;
        }
      }
    }
  }
}
