// ERROR
.error-view{
  display: flex;
  flex-direction: column;
  .error{
    &-wrapper{
      display: flex;
      height: 70vh;
      flex-direction: column-reverse;
      @include media-large{
        flex-direction: row;
      }
    }
    &-message{
      width: 100%;
      @include media-large{
        width: 50%;
      }
      padding: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 32px;
    }
    &-title{
      font-weight: 800;
      font-family: $font-family-title;
      font-size: 50px;
      color: $color-primary-orange;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      @include media-large{
        text-align: left;
      }
    }
    &-type{
      font-weight: 800;
      font-family: $font-family-title;
      font-size: 40px;
      text-align: center;
      width: 100%;
      @include media-large{
        text-align: left;
      }
    }
    &-description{
      font-weight: 800;
      font-family: $font-family-title;
      font-family: 18px;
      width: 100%;
      text-align: center;
      @include media-large{
        text-align: left;
      }
    }
    &-actions{
      display: flex;
      gap: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      @include media-large{
        flex-direction: row;
      }
    }
    &-image{
      width: 100%;
      height: 100%;
      background-image: url(../assets/images/error.png) ;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
.maintenance{
  &-view{
    display: flex;
    flex-direction: column;
    margin-top: -70px;
  }
  &-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-family-title;
    justify-content: center;
    height: 100vh;
    gap: 32px;
    position: relative;
    &::before{
      content:"";
      position: absolute;
      top: 0px;
      left: 0px;
      background-image: url(../assets/images/maintenance.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }
  &-title{
    text-transform: uppercase;
    font-family: $font-family-title;
    font-size: 48px;
    z-index: 2;

    @include media-small{
      font-size: 64px;

    }
  }
  &-description{
    width: 100%;
    font-family: $font-family-title;
    text-align: center;
    font-size: 28px;
    text-transform: uppercase;
    z-index: 2;

    @include media-small{
      font-size: 40px;

    }
  }
  &-actions{
    display: flex;
    gap: 16px;
    flex-direction: column;
    z-index: 2;

    @include media-small{
      flex-direction: row;
    }
  }
  &-message{
    font-family: $font-family-title;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    z-index: 2;

  }
  &-social-title{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 16px;
    z-index: 2;

  }

}
