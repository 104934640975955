.tournament-hero {
  margin: -41px auto 0;
  display: flex;
  flex-direction: column;
  background-image: url('/assets/images/hero-tournaments.png');
  filter: drop-shadow(2px 4px 6px black);

  div {
    font-family: 'Rajdhani';
    font-style: normal;
    text-transform: uppercase;
  }

  &-top {
    width: MIN(90vw, 1200px);
    margin: 15px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >div {
      text-transform: uppercase;
    }

    &__back {
      margin: 20px 0 0 60px;
      font-family: 'Rajdhani';
      color: white;
      text-decoration: none;
      font-size: 1.2rem;

      svg {
        margin-right: 5px;
      }

      &:hover {
        filter: drop-shadow(2px 2px 5px white)
      }
    }

    &__info {
      font-family: 'Rajdhani';
      font-size: 1rem;
      margin: 15px 60px 0 0px;
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
    }
  }

  &-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    &__info {
      background-color: #FF5C00;
      font-weight: 700;
      padding: 3px 10px;

      svg {
        margin-right: 5px;
      }

      span {
        font-weight: 300;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 3rem;
      margin: 10px 0 20px 0;
    }

    &__state {
      padding: 8px 45px;
      font-weight: 700;
      position: relative;
      clip-path: polygon(0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 15px 100%, 0 calc(100% - 15px));

      &--ongoing,
      &--registered,
      &--created,
      &--ended,
      &--announced {
        border: 2px solid rgb(53, 151, 46);
      }

      &--ended {
        border: 2px #282F2E;
        background: #282F2E;
        color: rgb(100, 100, 100);
        text-decoration: line-through;
      }

      &::after {
        content: '';
        position: absolute;
        top: 1px;
        right: -6px;
        width: 21px;
        height: 7px;
        background-color: rgb(53, 151, 46);
        transform: rotate(45deg);
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: -6px;
        width: 21px;
        height: 7px;
        background-color: rgb(53, 151, 46);
        transform: rotate(45deg);
      }
    }

    &__state-or-action {
      margin-bottom: 30px;
    }
  }

  &-bottom {
    width: MIN(calc(90% - 150px), 1000px);
    margin: 0 auto 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__booking {
      font-weight: 700;
      margin-top: 10px;
      text-align: center;
      svg {
        margin-right: 5px;
      }
    }
  }
}
