.referral-view {
  // background-image: url(../../components/Hero/assets/bg.png);
  margin-top: -40px;
  padding-top: 50px;

  &__title {
    margin-bottom: 2em;
  }

  .minter-component {}

  &__footer {
    margin-top: 4em;
    margin-bottom: 2em;
  }
}

.smaller-text {
  font-size: 3em;
}

.bigger-text {
  font-size: 2.6em;
}


.referral-banner-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.img-banner-size {
  width: 100%;
  max-width: 1230px;
}
