.df-input {
  background-color: black;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 3px #413f3fb7;
  color: #bcb8b8;
  padding: 5px;

  &:focus-visible {
    box-shadow: 0px 0px 3px orangered;
    outline: none;
  }

  &:invalid {
    border: 2px solid red;
  }

  &::placeholder {
    text-align: center;
  }
}