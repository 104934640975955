.tournament-info {
  width: MIN(90vw, 1200px);
  margin: 0 auto;

  h2 {
    font-size: 1.5rem;
    text-align: left;
    margin-top: 30px;
  }

  p {
    font-family: Rajdhani, serif;
    font-size: 1rem;
    text-align: justify;
    margin-top: 10px;
  }

  .tournament-hero-top__back {
    display: inline;
    cursor: pointer;
  }
}
