#adept-modal {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: none;
    
  }
  
  &__buttons {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }

  .bevel {
    width: 580px;
    height: 600px;
  }

  .content {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &__image {
    object-fit: cover;
    border: 1px solid blue;
  }
}