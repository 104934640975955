@import "../../../styles/deviants-styles.scss";

$button-border-medium: 4px solid;
$button-border-small: 2px solid;

.btn-shadow {
	width: fit-content;
	text-decoration: none;

	&:hover {
		filter: drop-shadow(-4px -4px 20px #ff5207);
	}

	&.btn-disabled {
		&:hover {
			filter: drop-shadow(none);
		}
	}
}

.btn {
	display: flex;
	position: relative;
	color: white;
	font-family: $font-family-title;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	cursor: pointer;
	background-color: transparent;
	text-decoration: none;
	box-sizing: content-box;
	z-index: 2;
	clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));

	& p,
	span {
		font-family: $font-family-title;
		color: white;
	}

	&-disabled {
		cursor: default;
		filter: grayscale(1) !important;

		&::after {
			background: $color-primary-orange  !important;
		}

		&::before {
			background: $color-primary-orange  !important;
		}

		&:hover {
			filter: grayscale(1) !important;
			filter: drop-shadow(none);
			// background: transparent !important;
			// box-shadow: -4px -4px 0px 0px $color-primary-orange inset,4px 4px 0px 0px $color-primary-orange inset !important;
		}
	}

	&.btn-medium {
		min-height: 56px;
		width: 215px;
		font-weight: 700;
		font-size: 18px;
	}

	&.btn-small {
		height: 38px;
		width: 38px;

		&.btn-left {
			clip-path: polygon(10px 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);

			&::after {
				bottom: 1px;
				right: -8px;
				left: auto;
				transform: rotate(-45deg);
			}

			&::before {
				bottom: auto;
				right: auto;
				left: -20px;
				transform: rotate(-45deg);
			}

			&:hover {
				box-shadow: none;
			}
		}

		&.btn-right {
			clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));

			&::after {
				bottom: 3px;
			}

			&::before {
				top: 2px;
			}

			&:hover {
				box-shadow: none;
			}
		}
	}

	&.btn-primary {
		background: $color-button-gradient;
		box-shadow: -4px -4px 0px 0px rgba(143, 0, 0, 0.25) inset, 4px 4px 0px 0px rgba(255, 233, 153, 0.31) inset;
	}

	&.btn-secondary {
		background: $color-button-secondary;
		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5), inset 2px 2px 0px #66ea51, inset -2px -2px 0px rgba(7, 50, 0, 0.35);

		&:hover {
			background: $color-button-secondary;
		}
	}

	&.btn-least {
		background: rgb(40, 47, 46);
		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5), inset 4px 4px 0px rgb(53, 59, 59), inset -4px -4px 0px rgb(36, 36, 36);

		&:hover {
			background: rgb(50, 57, 56);
		}
	}

	&.btn-outline {
		box-shadow: -4px -4px 0px 0px $color-primary-orange inset, 4px 4px 0px 0px $color-primary-orange inset;

		&:hover::after {
			background: none;
		}

		&:hover::before {
			background: none;
		}

		&::after {
			content: "";
			position: absolute;
			height: 4px;
			width: 36px;
			background: $color-primary-orange;
			bottom: 13px;
			left: -10px;
			transform: rotate(45deg);
		}

		&::before {
			content: "";
			position: absolute;
			height: 4px;
			width: 36px;
			background: $color-primary-orange;
			top: 12px;
			right: -10px;
			transform: rotate(45deg);
		}

		&:hover {
			box-shadow: none;
		}

		&.btn-small {
			box-shadow: -3px -3px 0px 0px $color-primary-orange inset, 3px 3px 0px 0px $color-primary-orange inset;
		}

		&.disabled,
		&.disabled:hover {
			box-shadow: -3px -3px 0px 0px #707070 inset, 3px 3px 0px 0px #707070 inset;
			color: #707070;
			background-color: transparent;

			&::before,
			&::after {
				background: #707070;
			}
		}
	}

	&:hover {
		background: #ff7a00;
	}

	&.btn-icon {
		width: 48px;
		height: 48px;
		position: relative;

		&.btn-left {
			clip-path: polygon(15px 0%, 100% 0%, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);
			border: $button-border-small #42220f;

			&::after {
				content: "";
				position: absolute;
				height: 2px;
				width: 32px;
				background: #42220f;
				top: 2px;
				left: -7px;
				transform: rotate(-45deg);
			}

			&::before {
				content: "";
				position: absolute;
				height: 2px;
				width: 32px;
				background: #42220f;
				bottom: 2px;
				right: -7px;
				left: auto;
				transform: rotate(-45deg);
			}

			&:hover {
				background: none;
				filter: none;
				border: $button-border-small $color-primary-orange;

				&::after {
					background: $color-primary-orange;
				}

				&::before {
					background: $color-primary-orange;
				}
			}
		}

		&.btn-right {
			clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
			border: $button-border-small #42220f;

			&::after {
				content: "";
				position: absolute;
				height: 2px;
				width: 32px;
				background: #42220f;
				top: 2px;
				right: -12px;
				transform: rotate(45deg);
			}

			&::before {
				content: "";
				position: absolute;
				height: 2px;
				width: 32px;
				background: #42220f;
				bottom: 1px;
				left: -10px;
				top: auto;
				transform: rotate(45deg);
			}

			&:hover {
				background: none;
				filter: none;
				border: $button-border-small $color-primary-orange;

				&::after {
					background: $color-primary-orange;
				}

				&::before {
					background: $color-primary-orange;
				}
			}
		}
	}

	.icon {
		width: 16px;
		height: 16px;
		margin: 0 8px;
	}
}

#chevron {}