// PROFILE
.userprofile-view {
  display: flex;
  flex-direction: column;
  gap:8px;
  justify-content: space-between;
  height: 100vh;

  h2.title {
    font-size: 40px;
    margin: 0 0 20px 0;
  }

  .profile{
    width: 100%;
    padding: 0 4px;
    @include media-large{
      width: 1024px;
    }
    h1{
      text-transform: uppercase;
      text-align: start;
    }
    h2{
      color: white;
      font-size: 24px;
      text-align: start;
    }
  }
  .data{
    display: none;
  }
  .user-name{
    font-family: $font-family-title;
    font-weight: 800;
    font-size: 24px;
    word-break: break-all;
    text-transform: uppercase;
  }
  .avatar-img{
    min-width: 80px;
    min-height: 80px;
    background-size: contain;
    border-radius: 100%;
    @include media-large{
      min-width: 48px;
      min-height: 48px;
    }
    &.awaken{
      background-image: url('https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/awakenF_2.png');
    }
    &.entropy{
      background-image: url('https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/entropyM.png');
    }
    &.inhuman{
      background-image: url('https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/inhumanM.png');
    }
    &.owner{
      background-image: url('https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/OwnerF.png');
    }
  }

  .user-data{
    display: flex;
    gap: 16px;
    flex-direction: column;
    @include media-large{
      flex-direction: row;
      width: 40%;
    }
    .user-profile{
      display: flex;
      flex-direction: row;
      padding: 16px;
      background: #171A19;
      border: 1px solid #2F3A37;
    }
    .user-wallet-data{
      background: #171A19;
      border: 1px solid #2F3A37;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      p{
        font-family: $font-family-title;
        text-transform: uppercase;
        font-weight: 800;
      }
      .wallet-data-title{
        margin-bottom: 16px;
        font-size: 20px;
      }
      .wallet-address{
        word-break: break-all;
        width: 95%;
      }
      .wallet-currencies{
        .single-currency{
          display: flex;
          gap: 8px;
          .currency-type{}
          .currency-amount{}
        }

      }
    }
  }
  .game-data{

  }
  #profile-overlay{
    .first-step{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      p.description{
        text-align: center;
      }
      h2{
        font-size: 24px;
      }
    }
    .second-step{
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      .name-list{
        margin: 24px 0px;
        width: 300px;
        p.single-item{
          position: relative;
          input{
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            &~label{
              font-family: $font-family-title;
              width: 100%;
              font-weight: 800;
              display: inline-block;
              padding: 4px;
            }
            &:checked{
              &~label{
                background: grey ;
                padding: 4px;
              }
            }
          }
        }
      }
    }
    .third-step{
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      .photo-list{
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        width: 70%;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
        .single-photo{
          position: relative;
          width: 120px;
          height: 120px;
          input{
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 2;
            &~img{
              width: 120px;
              height: 120px;
              filter: grayscale(1);
            }
            &:checked~img{
              width: 120px;
              height: 120px;
              filter: grayscale(0);
              filter: drop-shadow(-4px -4px 20px #FF5207);
            }
          }
        }
      }
    }
    .end-step{
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      h1{
        color: #13DB27;
        margin: 24px 0px;
      }
    }
  }
}
