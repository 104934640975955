.user-staking-view {

  &__footer {
    margin-top: 4em;
    margin-bottom: 2em;
  }
}

.user-staking-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2em;
}

.user-staking-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.title {
  font-size: '3rem'
}

.staking-view-subtitle {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
}

.manage-button-wrapper {
  width: 100%;
}

@media (max-width: 1100px) {
  .user-staking-container {
    flex-direction: column;
    gap: 1em;
    align-items: center;
  }
}
