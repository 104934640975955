@import "../../styles/deviants-styles.scss";

.profile {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 2em;
  align-items: center;  
  background-color: #1B1F1E;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
  clip-path: polygon(0% 0%, 0% calc(100% - 40px), 40px 100%, 100% 100%, 100% 0%, 40px 0%);

  &__actions {
    width: 7rem;
    margin: -8px 0;

    &>div {
      height: 100%;
    }
  }
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.profile-title-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2C1711;
  clip-path: polygon(0% 40px, 0% 100%, 100% 100%, 100% 0%, 40px 0%);
  width: -webkit-fill-available;
}

.avatar-img {
  width: 200px;
  height: 200px;
  background: black;
  background-size: cover;
  border-radius: 50%;
}

.name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2em;
  background: linear-gradient(90deg, #0b0806 0%, #1b1f1e 50%, #0b0806 100%);
  padding: 10px;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
  min-width: 150px;
  justify-content: space-between;

  .btn {
    min-height: 46px;
    width: fit-content;
    min-width: 64px;
    font-weight: 500;
  }
}

.user-name {
  font-family: "Rajdhani";      
  font-size: 30px;
  // text-transform: initial !important;
  font-weight: 700;
}