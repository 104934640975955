$color-background: #111111;
$color-primary-orange: rgba(255, 92, 0, 1);
$color-primary-light-orange: rgba(255, 138, 47, 1);
$color-primary-lightest-orange: rgba(255, 159, 47, 1);
$color-primary-medium-orange: rgba(255, 122, 0, 1);
$color-primary-dark-orange: rgba(227, 69, 0, 1);
$color-primary-darkest-orange: rgba(227, 92, 0, 1);
$color-primary-white: #ffffff;
$color-button-gradient: linear-gradient(274.44deg, #CC4900 0%, rgba(255, 85, 31, 0.73) 67.69%, rgba(254, 91, 0, 0) 100%), #FF5C00;
$metal-color: #181C1B;

$color-button-secondary: #29B712;

$font-family-title: 'Rajdhani';
$font-tomorrow: 'Tomorrow';
$faction-color-undeviant: #606060;
$faction-color-awaken: #114183;
$faction-color-entropy: #C08400;
$faction-color-inhuman: #1E8311;
$faction-color-owner: #831111;

$deviant-border: 2px solid rgba(254, 91, 2, 0.5);