@import "../../styles/deviants-styles.scss";

.faction-button {
  // font-family: "Rajdhani" !important;
  margin: 0px 20px 0 0;
  // display: flex;
  flex-direction: column;
  cursor: pointer;
  
  svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
  
}
