@import "../../../styles/deviants-styles.scss";
.library {

  .search-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;

    &-icon {
      position: absolute;

      left: 10px;
      top: 30px;
      @include media-small {
      }
    }

    input {
      height: 38px;
      border: 2px solid $color-primary-orange;
      background-color: transparent;
      font-family: $font-family-title;
      color: white;
      position: relative;
      padding: 3px 3px 3px 32px;
      text-align: left;
      width: 100%;
      @include media-small {
        width: 100%;
      }

      &::placeholder {
        position: absolute;
        color: white;
      }
    }

    .search-btn {
      display: none;
      @include media-small {
        display: flex;
      }

      .btn {
        width: 120px;
        min-height: 48px;
        // clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
      }
    }
  }

  flex: 1;
  background-color: rgba(0, 0, 0, 0.2);
  // height: 80vh;
  // overflow: scroll;
  @include media-small {
    padding: 0 32px;
  }
  &-card-header {
    display: flex;
    grid-template-rows: 28px;
    align-items: center;
    margin: 32px 0px;
    width: 100%;
    @include media-small {
      display: grid;
      grid-template-columns: 200px 1fr 1fr 1fr 200px;
    }
    .library-line {
      display: none;
      width: 90%;
      height: 2px;
      background-color: #c4c4c4;
      opacity: 0.2;
      @include media-small {
        display: flex;
      }
    }
    .library-title {
      font-family: $font-family-title;
      text-transform: uppercase;
      color: white;
      font-weight: 800;
      text-align: center;
      opacity: 0.2;
      width: 100%;
    }
    .library-divider-left {
      width: 100%;
      height: 12px;
      background-repeat: no-repeat;
      background-size: contain;
      display: none;
      @include media-small {
        display: flex;
      }
      @include media-medium {
        background-image: url(./assets/footer-left-divider.png);
      }
    }
    .library-divider-right {
      width: 100%;
      height: 12px;
      background-repeat: no-repeat;
      background-size: contain;
      display: none;
      @include media-small {
        display: flex;
      }
      @include media-medium {
        background-image: url(./assets/footer-right-divider.png);
      }
    }
  }
  .library-character {
    &-card-header {
      display: grid;
      grid-template-columns: 200px 1fr 1fr 1fr 200px;
      grid-template-rows: 28px;
      align-items: center;
      margin: 32px 0px;
      .library-line {
        width: 90%;
        height: 2px;
        background-color: #c4c4c4;
        opacity: 0.2;
      }
      .library-title {
        font-family: $font-family-title;
        text-transform: uppercase;
        color: white;
        font-weight: 800;
        text-align: center;
        opacity: 0.2;
      }
      .library-divider-left {
        width: 100%;
        height: 12px;
        background-repeat: no-repeat;
        background-size: contain;
        @include media-large {
          background-image: url(./assets/footer-left-divider.png);
        }
      }
      .library-divider-right {
        width: 100%;
        height: 12px;
        background-repeat: no-repeat;
        background-size: contain;
        @include media-large {
          background-image: url(./assets/footer-right-divider.png);
        }
      }
    }
    &-card-list {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      grid-template-rows: repeat(auto-fill, minmax(450px, 1fr));
      justify-items: end;
      grid-auto-rows: 450px;
      @include media-small {
        justify-items: center;
        grid-auto-rows: 450px;
      }
      &-space {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 388px;
        box-sizing: content-box;
        padding: 8px;
        transform: scale(0.9);
        margin-right: 16px;
        > .nft-amount {
          z-index: 2;
          min-width: 48px;
          min-height: 48px;
          background: #171a19;
          font-weight: 800;
          font-size: 18px;
          position: absolute;
          right: 10px;
          bottom: 0px;
          width: 2em;
          height: 2em;
          text-align: center;
          vertical-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: -4px -4px 0px 0px RGB(143, 0, 0,  0.25) inset, 4px 4px 0px 0px RGB(255, 233, 153,0.31) inset;
          clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
        }
        &.added {
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
          > div:first-child {
            z-index: 3;
            position: absolute;
            background-color: black;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.7;
            font-weight: bold;
          }
          &:hover {
            > .hover-options {
              display: none;
            }
            > .hover-overlay {
              display: none;
            }
          }
        }
        @include media-small {
          transform: scale(1.05);
          width: 300px;
          margin: 0;
        }
        &::after {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-bottom: 9px solid white;
          opacity: 0.1;
          top: -4px;
          right: -39px;
          transform: rotate(45deg);
          @include media-small {
            top: 4px;
            right: 4px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 9px solid white;
          opacity: 0.1;
          bottom: -15px;
          left: -34px;
          transform: rotate(45deg);
          @include media-small {
            bottom: -15px;
            left: -1px;
          }
        }
        &:hover::after {
          opacity: 1;
        }
        &:hover::before {
          opacity: 1;
        }
        &:hover .hover-options {
          display: grid;
          cursor: pointer;
        }
        &:hover .hover-overlay {
          display: flex;
        }
        .hover-options {
          position: absolute;
          display: none;
          z-index: 3;
          width: 100%;
          height: 100%;
          .view-btn .btn {
            min-height: 48px;
            gap: 8px;
            background: rgba(23, 26, 25, 1);
          }
          .add-btn .btn {
            background: rgba(41, 183, 18, 1);
            min-width: 48px;
            min-height: 48px;
          }
          .fav-btn .btn {
            min-width: 48px;
            min-height: 48px;
            background: rgba(23, 26, 25, 1);
          }
          .count-btn .btn {
            min-width: 48px;
            min-height: 48px;
            background: rgba(23, 26, 25, 1);
            font-weight: 800;
            font-size: 18px;
          }
          .hover-buttons {
            display: flex;
            grid-column: 1/2;
            grid-row: 1/2;
            align-self: center;
            align-items: center;
            grid-gap: 8px;
            justify-self: center;
            flex-direction: column;
            @include media-small {
              justify-self: center;
              flex-direction: row;
            }
            > .btn-primary {
              background-color: transparent;
              background: none;
              &:hover {
                background: none;
              }
            }
          }
          .hover-status {
            display: flex;
            gap: 8px;
            grid-column: 1/2;
            grid-row: 1/2;
            align-self: flex-end;
            position: relative;
            top: 20px;
            justify-self: center;
            @include media-small {
              justify-self: flex-end;
            }
          }
        }
        .hover-overlay {
          position: absolute;
          z-index: 2;
          display: none;
          width: 122%;
          height: 99%;
          background: rgba(255, 255, 255, 0.1);
          border: 2px solid white;
          box-shadow: 0px 0px 32px white inset;
          margin-top: 10px;
          margin-left: 6px;
          clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px));
          @include media-small {
            width: 296px;
            height: 397px;
            right: 12px;
            top: 3px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 49px;
            height: 2px;
            background: white;
            top: 0;
            right: -12px;
            transform: rotate(45deg);
          }
          &::before {
            content: "";
            position: absolute;
            width: 49px;
            height: 2px;
            background: white;
            bottom: 0;
            left: -12px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .is-nft {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 48px;
      height: 48px;
    }
    &-technology {
      bottom: 15px;
    }
  }

  .library-technology {
    &-card-list {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      grid-template-rows: repeat(auto-fill, minmax(380px, 1fr));
      justify-items: center;
      grid-auto-rows: 380px;

      &-space {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 314px;
        box-sizing: content-box;
        padding: 8px;
        transform: scale(0.9);
        > .nft-amount {
          z-index: 2;
          min-width: 48px;
          min-height: 48px;
          background: #171a19;
          font-weight: 800;
          font-size: 18px;
          position: absolute;
          right: 10px;
          bottom: 15px;
          width: 2em;
          height: 2em;
          text-align: center;
          vertical-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
          box-shadow: -4px -4px 0px 0px RGB(143, 0, 0,  0.25) inset, 4px 4px 0px 0px RGB(255, 233, 153,0.31) inset;
        }
        &.added {
          z-index: 1;
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
          > div:first-child {
            position: absolute;
            background-color: black;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.7;
            font-weight: bold;
            z-index: 3;
          }
          &:hover {
            > .hover-options {
              display: none;
            }
            > .hover-overlay {
              display: none;
            }
          }
        }
        @include media-small {
          justify-content: center;
          transform: scale(1.05);
        }
        &::after {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-bottom: 9px solid white;
          opacity: 0.1;
          top: 10px;
          right: -6px;
          transform: rotate(45deg);
          @include media-small {
            top: 8px;
            right: -1px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 9px solid white;
          opacity: 0.1;
          bottom: 1px;
          left: 1px;
          transform: rotate(45deg);
          @include media-small {
            bottom: 7px;
            left: -1px;
          }
        }
        &:hover::after {
          opacity: 1;
        }
        &:hover::before {
          opacity: 1;
        }
        &:hover {
          .hover-options {
            display: grid;
            place-content: center;
            cursor: pointer;
          }
      }
        &:hover .hover-overlay {
          display: flex;
        }
        .hover-options {
          position: absolute;
          display: none;
          z-index: 3;
          width: 100%;
          height: 100%;
          .view-btn .btn {
            min-height: 48px;
            gap: 8px;
            background: rgba(23, 26, 25, 1);
          }
          .add-btn .btn {
            background: rgba(41, 183, 18, 1);
            min-width: 48px;
            min-height: 48px;
          }
          .fav-btn .btn {
            min-width: 48px;
            min-height: 48px;
            background: rgba(23, 26, 25, 1);
          }
          .count-btn .btn {
            min-width: 48px;
            min-height: 48px;
            background: rgba(23, 26, 25, 1);
            font-weight: 800;
            font-size: 18px;
          }
          .hover-buttons {
            display: flex;
            grid-column: 1/2;
            grid-row: 1/2;
            align-self: center;
            align-items: center;
            grid-gap: 8px;
            justify-self: center;
            > .btn-primary {
              background-color: transparent;
              background: none;
              &:hover {
                background: none;
              }
            }
            @include media-small {
              justify-self: flex-start;
            }
          }
          .hover-status {
            display: flex;
            gap: 8px;
            grid-column: 1/2;
            grid-row: 1/2;
            align-self: flex-end;
            position: relative;
            top: 20px;
            justify-self: center;
            @include media-small {
              justify-self: flex-end;
            }
          }
        }
        .hover-overlay {
          position: absolute;
          z-index: 2;
          display: none;
          width: 95%;
          height: 90%;
          background: rgba(255, 255, 255, 0.1);
          border: 2px solid white;
          box-shadow: 0px 0px 32px white inset;
          clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px));
          @include media-small {
          }
          &::after {
            content: "";
            position: absolute;
            width: 49px;
            height: 2px;
            background: white;
            top: 0;
            right: -12px;
            transform: rotate(45deg);
          }
          &::before {
            content: "";
            position: absolute;
            width: 49px;
            height: 2px;
            background: white;
            bottom: 0;
            left: -12px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
