.airdrop-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
}

p {
  font-family: 'Rajdhani';
}