@import "../../styles/deviants-styles.scss";
$deviant-border: 2px solid #813008;


.accordion{
	position: relative;
	&:hover{
		filter: drop-shadow(-4px -4px 20px #FF5207)
	}
	&-header{
		display: grid;
    grid-template-rows: 35px 11px 10px 0px;
    grid-template-columns: 300px auto 80px;

    align-items: center;
    cursor: pointer;
		@include media-medium{
			position: relative;
			grid-template-columns: 476px auto 93px;

		}
		&-title{
			font-family: $font-family-title;
			font-weight: 800;
			font-size: 20px;
			text-transform: uppercase;
			width: 100%;
			grid-column: 1/2;
			grid-row: 1/3;
			z-index: 2;
			padding-left: 50px;
		}
		.left-top{
			background-image:url(./assets/left-top.png);
			grid-column: 1/2;
			grid-row: 1/4;
			height: 100%;
			width: 100%;
			background-size: 275%;
			background-repeat: no-repeat;
			z-index: 1;
			@include media-medium{
				background-size: contain;
				
			}
		}
		.right-top{
			z-index: 1;
			background-image:url(./assets/right-top.png);
			height: 100%;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			grid-column: 3/4;
			grid-row: 1/4;
		}
		.middle-top{
			z-index: 1;
			grid-column: 2/4;
			grid-row: 1/4;
			width: 100%;
			height: 100%;
			background-color:#171A19;
			border-top: 1px solid #222C29;
			@include media-medium{
				grid-column: 2/3;
				grid-row: 1/2;
			}
		}
		.accordion-counter{
			grid-column: 1/2;
			grid-row: 1/3;
			z-index: 1;
			align-self: center;
			justify-self: end;
			font-size: 32px;
			font-weight: 900;
			color: #171A19;
			-webkit-text-stroke: 1px rgba(255,255,255,0.2);
			margin-right: 8px;
			font-family: $font-family-title;
			opacity: 0.5;
			@include media-xsmall{
				grid-column: 1/2;
			}
		}
		&-icon{
			grid-column: 3/4;
			grid-row: 1/4;
			width: 100%;
			height: 100%;
			z-index: 1;
			place-items: center;
			display: grid;
			cursor: pointer;
		}
		&-divider{
			background-image: url(./assets/divider.png);
			height: 10px;
			width: 80px;
			background-size: cover;
			background-repeat: no-repeat;
			grid-column: 1/2;
			grid-row: 1/3;
			justify-self: flex-end;
			z-index: 2;
			margin-right: 46px;
			display: none;
			@include media-medium{
				display: flex;
			}
		}
	}
	&-footer{
		display: none;
    grid-template-rows: 33px 21px;
    grid-template-columns: 476px auto 93px;
		position: absolute;
    width: 100%;
    bottom: 0px;
		@include media-medium{
			display: grid;
		}
		&.open{
			bottom: -1px;
		}
		.left-bottom{
			background-image:url(./assets/left-bottom.png);
			grid-column: 1/2;
			grid-row: 1/3;
			height: 100%;
			width: 100%;
			background-size: 100%;
			background-repeat: no-repeat;
			z-index: 1;
			background-position-y: 23px;
		}
		.middle-bottom{
			z-index: 1;
			grid-column: 2/3;
			grid-row: 2/3;
			width: 100%;
			height: 100%;
			background-color: #171A19;
			border-top: 1px solid #222C29;
			border-bottom: 1px solid #131716;
		}
		.right-bottom{
			z-index: 1;
			background-image:url(./assets/right-bottom.png);
			height: 100%;
			width: 100%;
			background-repeat: no-repeat;
			grid-column: 3/4;
			grid-row: 1/3;
			background-position-y: 15px;
		}
	}
	&-content{
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 150px rgba(255, 84, 0, .4) inset;
		border: $deviant-border;
		background-color: #323232;
		padding-top: 38px;
		margin-top: -36px;
		@include media-small{
			flex-direction: row;
			grid-gap: 16px;
			gap: 16px;
			flex-wrap: wrap;
			width: 95%;
			padding: 16px;
			margin: auto;
			margin-top: -42px;
			padding-top: 42px;
		}
		b{
			font-family: $font-family-title;
			margin-right: 4px;
		}
		&-skill{
			display: flex;
			gap: 8px;
			align-items: flex-start;
			padding: 8px;
		}
		&-icon{
			width: 50px;
		}
		&-wrapper{
			display: flex;
			flex-direction: column;
			gap: 16px;
			@include media-large{
				flex-direction: row;
				gap: 32px;
			}
			img.example{
				width: 100%;
			@include media-small{
				width: 50%;
			}
			}
		}
		&-title{
			font-family: $font-family-title;
			font-weight: 800;
			font-size: 18px;
			text-transform: uppercase;
			margin-top: 24px;
		}
		&-description{
			font-family: $font-family-title;
			font-size: 18px;
			width: 100%;
			padding: 8px;
			@include media-small{
				width: 50%;
			}
		}
		p{
			font-family: $font-family-title;
			font-size: 18px;
		}
		ul, ol{
			li{
				font-family: $font-family-title;
				font-size: 18px;
			}
		}
	}
}