@import "../../../styles/deviants-styles.scss";
$deviant-border: 2px solid #813008;
.deck__container {
  &__list {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 60px 20px 20px;
    gap: 20px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0,0,0,0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ff5c00;
      border-radius: 20px;
      border: 3px solid rgba(0,0,0,0.3);
    }
  }
  &__details {
    &__deck {
      &-counter {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;
        p {
          font-family: $font-family-title;
          font-size: 16px;
        }
        p.total-cards {
          color: rgba(217, 25, 25, 1);
          font-size: 20px;
          font-weight: 700;
        }
        p.total-cards.complete {
          color: rgba(41, 183, 18, 1);
        }
      }

      &-actions {
        display: flex;
        padding-top: 20px;
        align-content: center;
        justify-content: center;
        padding-bottom: 37px;
      }
    }
  }
}
.deck-detail-card {
  height: 70px;
  font-family: Rajdhani, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  padding-left: 36px;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  position: relative;
  &__icon-gender {
    filter: drop-shadow(1px 3px 0 black);
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: black;
    &.nb {
      margin: 5px;
    }
  }
  &.empty.hq {
    display: flex;
    position: relative;
    flex: 1;
    font-family: Rajdhani, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19.14px;
    padding-left:0;
    &:hover {
      background-color: rgba(127, 51, 11, 0.4);
      color:#FF7A00;
      cursor: pointer;
      > div {
        cursor: pointer;
        > div {
          cursor: pointer;
        }
      }
    }
    > .hq_empty-container {
      flex: 1 1;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      > .icon-type-hq {
        background-image: url(../Card/assets/icon_hq_orange.png);
      }
    }
  }
  &__close {
    position: absolute;
    top: -13px;
    left: -13px;
    display: none;
  }
  &__eye {
    position: absolute;
    bottom: -13px;
    right: -13px;
    display: none;
    >div{
      >.btn-primary{
        background: black;
      }
    }
  }
  &-shadow {
    z-index: 2;
    background-color: transparent;
    filter: drop-shadow(4px 10px 10px rgba(0, 0, 0, 0.5));
    cursor: pointer;
    &:hover {
      position:relative;
      filter: drop-shadow(-4px -4px 10px #ffe794);
      > .deck-detail-card__close {
        display: block;
      }
      > .deck-detail-card__eye {
        display: block;
      }

    }
    &.empty {
      box-shadow: none;
      cursor: auto;
      filter: drop-shadow(0px 0 0 transparent);

      //noinspection CssInvalidFunction
      &:hover {
        filter: drop-shadow(0);
        > .deck-detail-card__close {
          display: none;
        }
        > .deck-detail-card__eye {
          display: none;
        }
      }
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 14px 0;
        border-color: transparent #7F330B transparent transparent;
        right: 0;
        bottom: 0;
        position: absolute;
      }
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 14px 14px;
        border-color: transparent transparent transparent #7F330B;
        left: 0;
        top: 0;
        position: absolute;
      }
    }
  }

  &-background {
    clip-path: polygon(95% 0, 100% 20%, 100% 100%, 5% 100%, 0% 80%, 0 0);
    // background-position-y: -100px;
    background-size: 100% 100%;
    width: 100%;
    background-color: transparent;
  }

  &.awaken {
    background: $faction-color-awaken;
    background: linear-gradient(90deg, $faction-color-awaken 30%, rgba(0, 0, 0, 0) 100%);
    box-shadow: inset 2px 2px 0 0 #417ed1, inset -2px -2px 0 0 #032859, 4px 4px 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 2px 2px 0 0 #417ed1, inset -2px -2px 0 0 #032859,
      4px 4px 10px 0 rgba(0, 0, 0, 0.5);
  }
  &.entropy {
    background: $faction-color-entropy;
    background: linear-gradient(90deg, $faction-color-entropy 30%, rgba(0, 0, 0, 0) 100%);
    box-shadow: inset 2px 2px 0 0 #ffb82b, inset -2px -2px 0 0 #7b5200, 4px 4px 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 2px 2px 0 0 #ffb82b, inset -2px -2px 0 0 #7b5200,
      4px 4px 10px 0 rgba(0, 0, 0, 0.5);
  }
  &.owner {
    background: $faction-color-owner;
    background: linear-gradient(90deg, $faction-color-owner 30%, rgba(0, 0, 0, 0) 100%);
    box-shadow: inset 2px 2px 0 0 #cd3636, inset -2px -2px 0 0 #430101, 4px 4px 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 2px 2px 0 0 #cd3636, inset -2px -2px 0 0 #430101,
      4px 4px 10px 0 rgba(0, 0, 0, 0.5);
  }
  &.inhuman {
    background: $faction-color-inhuman;
    background: linear-gradient(90deg, $faction-color-inhuman 30%, rgba(0, 0, 0, 0) 100%);
    box-shadow: inset 2px 2px 0 0 rgba(102, 234, 81, 0.5), inset -2px -2px 0 0 rgba(7, 50, 0, 0.35),
      4px 4px 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 2px 2px 0 0 rgba(102, 234, 81, 0.5), inset -2px -2px 0 0 rgba(7, 50, 0, 0.35),
      4px 4px 10px 0 rgba(0, 0, 0, 0.5);
  }
  &.undeviant {
    background: $faction-color-undeviant;
    background: linear-gradient(90deg, $faction-color-undeviant 30%, rgba(0, 0, 0, 0) 100%);
    box-shadow: inset 2px 2px 0 0 rgba(188, 188, 188, 0.5), inset -2px -2px 0 0 rgba(0, 0, 0, 0.35),
      4px 4px 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 2px 2px 0 0 rgba(188, 188, 188, 0.5), inset -2px -2px 0 0 rgba(0, 0, 0, 0.35),
      4px 4px 10px 0 rgba(0, 0, 0, 0.5);
  }

  &.empty {
    border: 3px solid #7F330B;
    position: relative;
    :hover {
      cursor: auto;
    }
    & > .empty-card-icons {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      align-items: center;
      & > div:first-of-type {
        right: 40px;
      }
      > .icon-type-char {
        background-image: url(../Card/assets/icon_char_orange.png);
      }
      > .icon-type-tech {
        background-image: url(../Card/assets/icon_tech_orange.png);
      }
    }
    &::before {
      content: "";
      position: absolute;
      background-color:  #7F330B;
      width: 40px;
      height: 3px;
      bottom: 0;
      left: -8px;
      transform: rotate(38deg);
    }
    &::after {
      content: "";
      position: absolute;
      background-color:  #7F330B;
      width: 40px;
      height: 3px;
      bottom: 62px;
      right: -6px;
      transform: rotate(-143deg);
    }
  }
}
.icon-faction {
  display: flex;
  width: 101px;
  height: 74px;
  grid-column: 3/4;
  grid-row: 2/3;
  background-size: 92%;
  background-repeat: no-repeat;
  z-index: 0;
  position: absolute;
  opacity: 0.3;
  left: -6px;
  bottom: -4px;

  &.icon-faction-undeviant {
    background-image: none;
  }
  &.icon-faction-awaken {
    background-image: url(../Card/assets/icon_awaken.png);
  }
  &.icon-faction-entropy {
    background-image: url(../Card/assets/icon_entropy.png);
  }
  &.icon-faction-inhuman {
    background-image: url(../Card/assets/icon_inhuman.png);
  }
  &.icon-faction-owner {
    background-image: url(../Card/assets/icon_owner.png);
  }
}
.icon-type {
  display: flex;
  width: 49px;
  height: 38px;
  grid-column: 3/4;
  grid-row: 2/3;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
  position: absolute;
  right: 0;
  // margin-top: -10px;
  filter: drop-shadow(1px 3px 0 black);
  &.icon-type-char {
    background-image: url(../Card/assets/icon_char.png);
  }
  &.icon-type-hq {
    background-image: url(../Card/assets/icon_hq.png);
  }
  &.icon-type-tech {
    background-image: url(../Card/assets/icon_technology.png);
  }
}
.is-nft {
  position: absolute;
  bottom: 0;
  margin-left: 5px;
  left: 0;
  img {
    width: 24px;
    height: 24px;
  }
}
