.referral-component {
  text-align: center;
}

.referral-component__link {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 1em;
}
.referral-twitter-share {
  justify-content: center;
  display: flex;
}

.referral-copy-link {
  justify-content: center;
  display: flex;
  margin-bottom: 2em;
}

.referrals-table {
  margin-top: 3em;
}

.disclaimer {
  font-size: 1.2em;
  margin-top: 2em;
}

.link-color {
  color: #ff5c00;
}

.link-color:visited {
  color: #ff5c00;
}

.link-color:hover {
  color: #ff5c00;
}

.link-color:active {
  color: #ff5c00;
}


