@import "../../styles/deviants-styles.scss";

.card_container{
	display: flex;
	align-self: center;
	justify-self: center;
	height: 300px;
	width: 300px;
	justify-content: center;
	align-items: center;
	@include media-small{
		width: 420px;
		height: 396px;
	}
	.card{
		justify-self: center;
		align-self: center;
		cursor: pointer;
		pointer-events: none;
		&:hover{
			// transform: scale(1.1);
		}
	}
	.card-HQ{
		width:  100%;
		height: auto;
		@include media-small{
			height: auto;
			width: 100%;
		}
		// @include media-medium{
		// 	width: auto;
		// }
	}
	.card-TECH{
		width:  320px;
		height: auto;
		@include media-small{
			width: 400px;
			// height: auto;
		}
	}
	.card-CHAR{
		width: 240px;
		height:auto;
		max-height: 100%;
		@include media-small{
			width: 300px;
			height: auto;
		}
	}
	.card_overlay{
		place-content: center;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.8);
		position: fixed;
		justify-items: end;
		&.hide{
			display: none;
		}
		&.show{
			display: grid;
		}
		.close_button{
			cursor: pointer;
			background:$color-background;
			padding: 8px;
			box-sizing: content-box;
			box-shadow: 0px 0px 48px $color-primary-orange;
			&:hover{
				color: $color-primary-orange;
			}
		}
		.card{}
	}
}
