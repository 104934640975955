// MARKET PLACE
.marketplace {
  &-store-view {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include media-small {
      flex-direction: row;
    }

    .results-content {
      flex: 1 0;
    }

    #buy-modal {
      .title {
        font-size: 24px;
      }

      .subtitle {
        font-size: 16px;
      }
    }

    footer {
      width: 100%;
    }

    .filters {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 4px;

      .filters-section {
      }

      &-btn {
        width: 100px;
        @include media-small {
          display: none;
        }

        .btn {
          width: 100px;
          clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
          gap: 8px;

          &::before {
            right: -20px;
          }

          &::after {
            left: -20px;
          }
        }
      }

      .search-bar {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 16px;

        &-icon {
          position: absolute;

          left: 10px;
          top: 30px;
          @include media-small {
          }
        }

        input {
          height: 38px;
          border: 2px solid $color-primary-orange;
          background-color: transparent;
          padding: 8px;
          font-family: $font-family-title;
          color: white;
          position: relative;
          padding-left: 32px;
          width: 100%;
          @include media-small {
            width: 100%;
          }

          &::placeholder {
            position: absolute;
            color: white;
          }
        }

        .search-btn {
          display: none;
          @include media-small {
            display: flex;
          }

          .btn {
            width: 120px;
            min-height: 48px;
            // clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
          }
        }
      }
    }

    .sort-items {
      display: flex;
      flex-wrap: wrap;
      @include media-small {
        flex-wrap: nowrap;
      }

      &-dropdown {
        width: 320px;
      }

      &-time {
        display: none;
        justify-content: space-between;
        padding: 4px;
        width: 100%;
        @include media-small {
          justify-content: flex-start;
          gap: 8px;
        }

        .btn {
          width: 48px;
          padding: 0 6px;
        }
      }
    }
  }

  &-mycards-view {
    padding: 0 4px;
    font-family: Rajdhani;
    #trxmodal {
      z-index: 11;
    }
    &-error-getting-cards{

      > div {
        display: flex;
     
        justify-content: center;
        align-items: center;
        margin: auto;
        >button {
          max-width: 250px;
        }
      }
    }
    #mycardsingle {
      background: rgba(0, 0, 0, 0.1);

      .modal {
        height: 100%;
        clip-path: none;
        box-shadow: none;
        background: rgba(0, 0, 0, 1);
        width: 100%;
        overflow-y: auto;

        &::after {
          display: none;
        }

        &::before {
          display: none;
        }

        .close-btn {
          justify-self: end;
        }

        .card {
          transform: none;
        }

        .actions {
          display: flex;
          gap: 8px;

          .btn {
            width: 120px;
            font-size: 16px;
            padding: 8px;
            box-sizing: border-box;
          }
        }

        .card-info {
          display: flex;
          flex-direction: column;
          width: 100%;

          .details-title {
            font-weight: 800;
            font-size: 24px;
            text-transform: uppercase;
          }

          .details-card-name {
            font-weight: 800;
            font-size: 16px;
          }

          .details-card-skill {
            font-size: 16px;
          }
        }

        .card-content {
          display: flex;
          flex-direction: row;
          width: 100%;
          font-family: $font-family-title;
          font-size: 20px;
          font-weight: 800;
          flex-wrap: wrap;

          &-title {
            width: 100%;
          }

          &-data {
            font-weight: normal;
            width: 50%;
            font-size: 16px;
          }

          &-value {
            font-weight: 800;
            font-size: 16px;
          }
        }
      }
    }

    .card-list {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      gap: 2em;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.2);
      padding-bottom: 40px;
      &.selected {
        display: none;
        @include media-large {
          display: flex;
        }
      }
      &-detail {
        width: 100%;
        @include media-large {
          max-width: 450px;
        }
        > .card-detail {
          > div:first-child {
            @include media-xsmall {
              max-width: 400px;
              margin: 0 auto;
            }
          }
        }
        > .close-btn {
          position: sticky;
          right: 20px;
          top: 125px;
          width: max-content;
          display: flex;
          z-index: 2;
          margin-bottom: -52px;
          margin-left: auto;
        }
      }

      .card-layout {
        width: 40%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;

        > .nft-amount {
          z-index: 2;
          min-width: 48px;
          min-height: 48px;
          background: #171a19;
          font-weight: 800;
          font-size: 18px;
          position: absolute;
          right: 10px;
          bottom: 0px;
          width: 2em;
          height: 2em;
          text-align: center;
          vertical-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: -4px -4px 0px 0px RGB(143, 0, 0, 0.25) inset, 4px 4px 0px 0px RGB(255, 233, 153, 0.31) inset;
          -webkit-clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
          clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
        }
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-bottom: 9px solid white;
          opacity: 0.1;
          top: -11px;
          right: -10px;
          transform: rotate(45deg);
        }
        &::after {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-top: 9px solid white;
          opacity: 0.1;
          bottom: -11px;
          left: -10px;
          transform: rotate(45deg);
        }
        &:hover {
          > .nft-background {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            border: 2px solid white;
            box-shadow: 0px 0px 32px white inset;
            clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px));
          }
          &::after,
          &::before {
            opacity: 1;
          }
        }
        @include media-large {
          // background-color: green;
        }
        @include media-xlarge {
          // background-color: blue;
          width: 30%;
          max-width: 380px;
        }
      }
      .card-holder {
        width: 100%;
        height: auto;
        aspect-ratio: 15/17;
        .card {
          // transform: scale(0.68);
          // transform-origin: top left;
        }
      }
    }

    .search-bar {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;
      > svg {
        top: 30px;
      }
      &-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        @include media-small {
          top: 15px;
        }
      }

      input {
        height: 38px;
        border: 2px solid $color-primary-orange;
        background-color: transparent;
        padding: 8px;
        font-family: $font-family-title;
        color: white;
        position: relative;
        padding-left: 32px;
        width: 100%;
        text-align: start;
        vertical-align: middle;

        @include media-small {
          width: 100%;
        }

        &::placeholder {
          color: white;
          text-align: start;
          vertical-align: middle;
        }
      }

      .search-btn {
        display: none;
        @include media-small {
          display: flex;
        }

        .btn {
          width: 120px;
          min-height: 48px;
        }
      }
    }
  }

  &-mysales-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h1 {
      text-align: left;
      width: 100%;
      padding: 16px;
    }

    #confirm-modal {
      .description {
        margin-top: 16px;
      }

      .actions {
        display: flex;
        gap: 8px;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 800;
      text-align: center;
      width: 80%;
    }

    .description {
      font-family: $font-family-title;
      margin: 48px 0px 0px 0px;
    }

    .filters {
      display: flex;
      width: 100%;
      @include media-small {
        width: 100%;
        padding: 0px 32px;
      }
    }

    .nft-list {
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 2em;
      gap: 2em;
      justify-content: center;
      width: 100%;
      @include media-small {
        padding: 0 32px;
      }
    }

    .react-select-container {
    }

    .search-bar {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;

      &-icon {
        position: absolute;
        top: 15px;
        left: 10px;
        @include media-small {
          top: 15px;
        }
      }

      input {
        height: 38px;
        border: 2px solid $color-primary-orange;
        background-color: transparent;
        padding: 8px;
        font-family: $font-family-title;
        color: white;
        position: relative;
        padding-left: 32px;
        width: 100%;
        @include media-small {
          width: 100%;
        }

        &::placeholder {
          position: absolute;
          color: white;
        }
      }

      .search-btn {
        display: none;
        @include media-small {
          display: flex;
        }

        .btn {
          width: 120px;
          min-height: 48px;
        }
      }
    }

   

    footer {
      width: 100%;
    }

    .play-view {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .deckheader {
        margin-bottom: 64px;
      }

      &-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 64px;
        gap: 32px;

        h1 {
          text-transform: uppercase;
        }
      }

      &-content {
        width: 100%;
        display: flex;
      }

      .deck-selector {
        width: 550px;
      }

      &-deck-content {
        flex: 1 0 75%;
      }

      .deck-content-library {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 128px;

        .card-holder {
          .card {
            transform: none;
          }
        }
      }
    }

    footer {
      width: 100%;
    }
  }
}
.card-detail-actions {
  justify-content: space-between;
  > div {
    width: 45% !important;
    > button {
      width: 100% !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.sell-modal{
  &-transaction{
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: Rajdhani;
    font-size: 2em;
    font-weight: bold;
    align-items: center;
    gap:20px
  }
}