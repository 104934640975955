@import "../../styles/deviants-styles.scss";

.achievements-container {
  display: flex;
  flex-direction: column;
  align-items: center;  
  min-width: 500px;
  margin: 100px;
}

.achievements {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1B1F1E;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
  clip-path: polygon(0% 0%, 0% 100%, calc(100% - 40px) 100%, 100% calc(100% - 40px), 100% 0%);
  width: -webkit-fill-available;
  padding: 20px 0px;
  gap: 20px;
}

.achievements-title-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2C1711;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 40px, calc(100% - 40px) 0%);
  width: -webkit-fill-available;
} 

.achievements-title {
  font-family: "Rajdhani";
  font-weight: 700;
  font-size: 60px;
  letter-spacing: -0.04em;
  color: rgba(255, 92, 0, 0.6);
  text-shadow: 0px 0px 16px rgba(255, 66, 0, 0.7);
  -webkit-text-stroke: 2px #ff5c00;
}