.card-sprite {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  font-family: 'Rajdhani', serif;
  font-weight: 500;

  * {
    font-family: 'Rajdhani', serif;
    font-weight: 500;
    b {
      font-weight: bold;
    }
  }

  &.hq .card-container {
    position: relative;
    width: 1195px;
    height: 876px;
    overflow: hidden;

    .card {
      position: relative;
      width: 1195px;
      height: 876px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
    }
    .bg {
      background: none black scroll no-repeat center center;
      background-size: cover;
      display: block;
      width: 1140px;
      height: 605px;
      top: 90px;
      position: absolute;
    }
    .bg.stretch {
      background-size: 100% 100%;
    }
    .mainFrame {
      z-index: 1;
      width: 1195px;
      height: 876px;
      position: absolute;
      background: none transparent no-repeat scroll center center;
      background-size: contain;
    }
    .box {
      z-index: 2;
      width: 1195px;
      height: 876px;
      position: absolute;
      background: none transparent no-repeat scroll center center;
      background-size: contain;
    }
    .name {
      color: white;
      font-size: 42px;
      z-index: 4;
      position: absolute;
      top: 14px;
      flex-direction: row;
      text-align: center;
      width: 420px;
      text-transform: uppercase;
      margin-left: 10px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .st {
      color: white;
      font-size: 76px;
      height: 45px;
      width: 72px;
      z-index: 4;
      position: absolute;
      top: 645px;
      right: 90px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
      text-shadow: -5px -5px 20px #ff6c00, 0 0 20px #FF6A00;
    }

    .artist {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 22px;
      font-weight: 500;
      bottom: 26px;
      left: 100px;
      width: 225px;
      height: 26px;
      text-align: left;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .cardId {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 22px;
      font-weight: 500;
      bottom: 26px;
      left: 740px;
      width: 225px;
      height: 26px;
      text-align: left;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .description {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 24px;
      bottom: 80px;
      left: 150px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      width: 890px;
      height: 112px;
      font-weight: 500;
    }

    .desc-inside .line:first-child:nth-last-child(3),
    .desc-inside .line:first-child:nth-last-child(3) ~ .line {
      margin: 0;
      height: 30px;
    }
  }

  &.character .card-container {
    position: relative;
    width: 646px;
    height: 875px;
    overflow: hidden;

    .card {
      position: relative;
      width: 646px;
      height: 875px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
    }
    .bg {
      background: none black scroll no-repeat center center;
      background-size: cover;
      display: block;
      width: 590px;
      height: 605px;
      top: 88px;
      position: absolute;
    }
    .bg.stretch {
      background-position: center 10%;
      background-size: 100% 100%;
    }
    .mainFrame {
      z-index: 1;
      width: 646px;
      height: 875px;
      position: absolute;
      background: none transparent no-repeat scroll center center;
      background-size: contain;
    }
    .box {
      z-index: 2;
      width: 646px;
      height: 875px;
      position: absolute;
      background: none transparent no-repeat scroll center center;
      background-size: contain;
    }
    .name {
      color: white;
      font-size: 36px;
      z-index: 4;
      position: absolute;
      top: 12px;
      flex-direction: row;
      text-align: center;
      width: 420px;
      text-transform: uppercase;
      margin-left: 5px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .gender {
      margin: 0 10px 0 0;
      display: block;
      &.male {
        width: 34px;
        height: 34px;
        background: url(./assets/gender/male.png) transparent no-repeat scroll center center;
        background-size: contain;
      }
      &.female {
        width: 26px;
        height: 35px;
        background: url(./assets/gender/female.png) transparent no-repeat scroll center center;
        background-size: contain;
      }
      &.non-binary {
        width: 24px;
        height: 44px;
        background: url(./assets/gender/non-binary.png) transparent no-repeat scroll center center;
        background-size: contain;
      }
    }
    .atk_range {
      color: #008992;
      font-size: 45px;
      height: 45px;
      width: 72px;
      z-index: 4;
      position: absolute;
      top: 617px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      font-weight: bold;
      text-align: center;
      margin-left: -28px;
    }
    .atk {
      color: white;
      font-size: 45px;
      height: 45px;
      width: 72px;
      z-index: 4;
      position: absolute;
      top: 640px;
      left: 16px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      font-weight: bold;
      text-align: center;
      text-shadow: -2.5px -2.5px 0 red;
    }
    .hp {
      color: white;
      font-size: 45px;
      height: 45px;
      width: 72px;
      z-index: 4;
      position: absolute;
      top: 640px;
      right: 18px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
      text-shadow: -2.5px -2.5px 0 #007d52;
    }

    .artist {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 18px;
      font-weight: 500;
      bottom: 15px;
      left: 90px;
      width: 225px;
      height: 22px;
      text-align: left;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .cardId {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 18px;
      font-weight: 500;
      bottom: 15px;
      left: 420px;
      width: 225px;
      height: 22px;
      text-align: left;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .description {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 23px;
      bottom: 68px;
      left: 42px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      width: 580px;
      height: 112px;
      font-weight: 500;
    }
  }

  &.technology .card-container {
    position: relative;
    width: 687px;
    height: 669px;
    overflow: hidden;

    .card {
      position: relative;
      width: 687px;
      height: 669px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
    }
    .bg {
      background: none black scroll no-repeat center center;
      background-size: cover;
      display: block;
      width: 610px;
      height: 340px;
      top: 118px;
      position: absolute;
    }
    .bg.stretch {
      background-size: 100% 120%;
    }
    .mainFrame {
      z-index: 1;
      width: 687px;
      height: 669px;
      position: absolute;
      background: none transparent no-repeat scroll center center;
      background-size: contain;
    }
    .box {
      z-index: 2;
      width: 687px;
      height: 669px;
      position: absolute;
      background: none transparent no-repeat scroll center center;
      background-size: contain;
    }
    .name {
      color: white;
      font-size: 36px;
      z-index: 4;
      position: absolute;
      top: 55px;
      flex-direction: row;
      text-align: center;
      width: 420px;
      text-transform: uppercase;
      margin-left: 10px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .res {
      color: white;
      font-size: 59px;
      height: 45px;
      width: 72px;
      z-index: 4;
      position: absolute;
      top: 407px;
      right: 50px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
      text-shadow: -2.5px -2.5px 10px #e3c63f;
    }

    .artist {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 18px;
      font-weight: 500;
      bottom: 48px;
      left: 110px;
      width: 225px;
      height: 22px;
      text-align: left;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .cardId {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 18px;
      font-weight: 500;
      bottom: 48px;
      left: 440px;
      width: 225px;
      height: 22px;
      text-align: left;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .description {
      color: white;
      z-index: 4;
      position: absolute;
      font-size: 24px;
      bottom: 92px;
      left: 70px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      width: 550px;
      height: 112px;
      font-weight: 500;
    }
  }
}

.card-holder {
  .card-sprite {
    width: 100%;
    height: 100%;
    .card-container {
      scale: 0.43;
      display: inline-table;
    }
  }
}

.hq .card-holder {
  .card-sprite {
    .card-container {
      scale: 0.28;
    }
  }
}

.card-sprite {
  .card-container {
    .bg {
      video, .video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

.modal-component-container {
  .card-sprite {
    &.hq {
      width: 584px;
      height: 428px;
      .card-container {
        scale: 0.48;
        display: inline-table;
      }
    }
    &.character {
      width: 443px;
      height: 600px;
      .card-container {
        scale: 0.65;
        display: inline-table;
      }
    }
    &.technology {
      width: 584px;
      height: 569px;
      .card-container {
        scale: 0.85;
        display: inline-table;
      }
    }

  }
}

.HQ-picker-card-list {
  .card-holder {
    .card-sprite.hq {
      .card-container {
        scale: 0.27
      }
    }
  }
}

.card-list {
  .card-sprite.hq {
    .card-container {
      scale: 0.27
    }
  }
}
