.purchases-view {

  &__title {
    margin-bottom: 2em;
  }

  &__footer {
    margin-top: 4em;
    margin-bottom: 2em;
  }
}