@mixin media-xsmall {
  @media (min-width: 425px) {
    @content;
  }
}
@mixin media-landscape{
  @media only screen and (max-height: 425px) and (orientation: landscape){
    @content;
  }
}
@mixin media-small {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: 576px) { //sucio ortega
    @content;
  }
}

@mixin media-medium {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-large {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin media-xlarge {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin media-xxlarge{
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin media-xxxlarge{
  @media (min-width: 2560px) {
    @content;
  }
}
