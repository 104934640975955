@import "../../styles/deviants-styles.scss";

.footer {
  display: grid;
  grid-template-rows: 1fr 88px;
  justify-content: center;
  grid-template-columns: 100%;

  &-wrapper {
    grid-template-rows: 82px 1fr;
    grid-row: 1/2;
    grid-column: 1/2;
    z-index: 2;
    display: grid;
    grid-template-columns: 100%;
    gap: 24px;

    @include media-large {
      grid-template-columns: repeat(8, 1fr);

    }

    @include media-xlarge {
      grid-template-columns: repeat(12, 1fr);
      gap: 8px;
    }
  }

  &-divider-left {
    display: flex;

    @include media-large {
      display: flex;
      background-image: url(./assets/footer-left-divider.png);
      width: 214px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
      grid-column: 1/2;
      grid-row: 1/2;
      align-self: center;
      justify-self: start;
    }

    @include media-xlarge {
      grid-column: 1/3;
    }
  }

  &-divider-right {
    display: flex;

    @include media-large {
      display: flex;
      background-image: url(./assets/footer-right-divider.png);
      width: 214px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
      grid-column: 8/9;
      grid-row: 1/2;
      align-self: center;
      justify-self: end;
    }

    @include media-xlarge {
      grid-column: 11/13;
    }
  }

  &-line {
    display: none;
    width: 90%;
    height: 2px;
    background-color: #c4c4c4;
    opacity: 0.15;
    grid-row: 1/2;

    @include media-large {
      display: block;
    }

    &.left {
      align-self: center;
      justify-self: start;

      @include media-large {
        grid-column: 2/4;
      }

      @include media-xlarge {
        grid-column: 3/7;
      }
    }

    &.right {
      align-self: center;
      justify-self: end;

      @include media-large {
        grid-column: 6/8;
      }

      @include media-xlarge {
        grid-column: 7/11;
      }
    }
  }

  .fingerprint {
    grid-row: 1/2;
    place-self: center;
    width: 68px;
    height: 68px;
    position: relative;
    display: flex;

    @include media-large {
      display: flex;
      grid-column: 4/6;
    }

    @include media-xlarge {
      grid-column: 6/8;
    }
  }

  &-logo {
    grid: 1/2;
    background-image: url(./assets/footer-logo.png);
    height: 82px;
    width: 292px;
    background-repeat: no-repeat;
    background-position: center;
    justify-self: center;

    @include media-large {
      grid-column: 2/8;
    }

    @include media-xlarge {
      grid-column: 5/9;
    }

    @include media-xxlarge {
      grid-column: 3/5;
      height: 124px;
      background-position: left;
    }
  }

  &-title {
    font-family: $font-family-title;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 16px;
    text-align: center;

    @include media-xlarge {
      text-align: left;
    }
  }

  &-about {
    grid-column: 1/2;

    @include media-large {
      grid-column: 2/8;
    }

    @include media-xlarge {
      grid-column: 5/6;
    }
  }

  &-customer-service {
    grid-column: 1/2;

    @include media-large {
      grid-column: 2/8;
    }

    @include media-xlarge {
      grid-column: 6/8;
    }
  }

  &-policies {
    grid-column: 1/2;

    @include media-large {
      grid-column: 2/8;
    }

    @include media-xlarge {
      grid-column: 8/9;
    }
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;
    margin: 8px 0;
    display: flex;
    justify-content: center;

    @include media-xlarge {
      justify-content: start;
    }

    a {
      font-family: $font-family-title;
      font-size: 16px;
      color: #A8A9A9;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: $color-primary-orange;
        filter: drop-shadow(0px 0px 11px #FF5207);
      }
    }
  }

  &-social-networks {
    grid-column: 1/2;

    @include media-large {
      grid-column: 2/8;
    }

    @include media-xlarge {
      grid-column: 8/11;
    }

    @include media-xxlarge {
      grid-column: 10/12;
    }

    &-list {
      display: flex;
      justify-content: center;
      gap: 16px;

      .btn {
        .footer-icon {
          width: 24px;
          height: 24px;
          display: flex;
        }

        &:hover .icon-twitter {
          background-image: url(../../assets/icons/icon-twitter-hover.svg);
        }

        .icon-twitter {
          background-image: url(../../assets/icons/icon-twitter.svg);
        }

        &:hover .icon-discord {
          background-image: url(../../assets/icons/icon-discord-hover.svg);
        }

        .icon-discord {
          background-image: url(../../assets/icons/icon-discord.svg);
        }

        &:hover .icon-telegram {
          background-image: url(../../assets/icons/icon-telegram-hover.svg);
        }

        .icon-telegram {
          background-image: url(../../assets/icons/icon-telegram.svg);

        }

        &:hover .icon-medium {
          background-image: url(../../assets/icons/icon-medium-hover.svg);
        }

        .icon-medium {
          background-image: url(../../assets/icons/icon-medium.svg);

        }
      }
    }

  }

  &-holder {
    display: grid;
    grid-column: 1/2;
    grid-row: 1/3;
    grid-template-columns: 100%;
    filter: drop-shadow(0px 0px 11px #000);
    z-index: 1;

    @include media-xlarge {
      grid-template-columns: 250px auto 250px;
    }

    &-left {
      display: none;

      @include media-xlarge {
        display: flex;
        grid-column: 1/2;
        height: auto;
        width: 100%;
        align-self: end;
      }

    }

    &-fill {
      display: grid;
      grid-column: 1/2;
      width: 100%;
      position: relative;
      background: linear-gradient(90deg, rgba(17, 17, 16, 1) 0%, rgba(26, 30, 29, 1) 50%, rgba(17, 17, 16, 1) 100%);
      align-self: end;
      height: 64px;

      @include media-xlarge {
        grid-column: 2/3;
      }

      &::after {
        content: "";
        width: 100%;
        height: 6px; // pardon me css gods
        background: linear-gradient(90deg, #18211e 0%, rgba(24, 32, 29, 1) 100%);
        position: absolute;
        top: 0;
      }

      & p.copyright {
        font-family: $font-family-title;
        place-self: center;
      }
    }

    &-right {
      display: none;

      @include media-xlarge {
        display: flex;
        grid-column: 3/4;
        height: auto;
        width: 100%;
        align-self: end;
      }

    }
  }
}