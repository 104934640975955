@import 'styles/variables.scss';

.tournament-item {
  margin: 1rem;
  width: 410px;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 0px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
  cursor: pointer;

  &__content {
    padding: 1.5rem;
    box-shadow: -4px -4px 0px 0px rgba(40, 40, 40, .4) inset, 4px 4px 0px 0px rgba(180, 180, 180, .4) inset;
    height: 100%;
  }

  &--awaken {
    background-color: #11418377;
  }

  &--entropy {
    background: linear-gradient(90deg, rgba(192, 132, 0, .95) 20%, rgba(192, 132, 0, .4) 80%);
  }

  &--inhuman {
    background: linear-gradient(90deg, rgba(30, 131, 17, .95) 20%, rgba(30, 131, 17, .4) 80%);
  }

  &--owner {
    background: linear-gradient(90deg, rgba(131, 17, 17, .95) 20%, rgba(131, 17, 17, .4) 80%);
  }

  &--undeviants {
    background: linear-gradient(90deg, rgba(0, 0, 0, .6) 20%, rgba(20, 20, 20, .4) 80%);
  }

  &__announced {
    background-color: $color-primary-orange;
    font-size: 1.1rem;
    font-family: 'Rajdhani';
    font-weight: 300;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 0.6rem;
  }

  &__date {
    font-size: 1.1rem;
    font-family: 'Rajdhani';
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 2.3rem;

    svg {
      margin-right: 10px;
    }
  }

  &__title {
    font-size: 1.9rem;
    font-family: 'Rajdhani';
    font-weight: 700;
    margin-top: 20px;
  }

  &__bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  & .btn-shadow {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: none transparent no-repeat scroll center center;
    background-size: cover;
    background-position-x: right;

    img {
      width: 100%;
    }
  }

  .avatar-img {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    border: 2px solid grey;
  }
}
