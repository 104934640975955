@import "../../styles/deviants-styles.scss";

.user-profile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	width: 100%;
	cursor: pointer;

	p,
	svg {
		transition-duration: 0.3s;
		color: white;
		fill: white;
	}

	&:hover p,
	&:hover svg {
		color: $color-primary-light-orange;
		fill: $color-primary-light-orange;
	}

	@include media-large {
		flex-direction: row;
	}

	&.connected-wallet {
		flex-direction: row;
		padding: 0 16px;
		align-items: center;
		flex-wrap: wrap;
		justify-content: flex-end;

		@include media-xlarge {
			justify-content: space-between;
		}
	}

	.user-avatar-container {
		width: fit-content;
		position: relative;
	}

	.user-avatar {
		width: fit-content;
		border-radius: 100%;
		overflow: hidden;

		&-mobile {
			@include media-large {
				display: none;
			}
		}

		&-desktop {
			display: none;

			@include media-large {
				display: block;
			}
		}

		.avatar-img {
			width: 80px;
			height: 80px;
			background: black;
			background-size: cover;

			@include media-large {
				width: 50px;
				height: 50px;
			}

			&.awaken {
				background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/awakenF_2.png");
			}

			&.entropy {
				background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/entropyM.png");
			}

			&.inhuman {
				background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/inhumanM.png");
			}

			&.owner {
				background-image: url("https://deviants.mypinata.cloud/ipfs/QmQu5jhAXxZZXjTi5jHsNDhr1B3xXsgcCz8mrCYQ5gek82/OwnerF.png");
			}
		}
	}

	.user-name {
		font-family: $font-family-title;
		font-weight: 800;
		font-size: 18px;
		// text-transform: uppercase;
		text-align: left;
		width: 123px;
		margin-top: -30px;
	}

	.user-balances {
		display: none;

		@include media-xlarge {
			display: block;
			font-size: 14px;
			position: absolute;
			min-width: 200px;
			margin-left: 58px;
			margin-top: 30px;
		}
	}

	.user-balances-icon {
		height: 16px;
		margin-bottom: -1px;
		margin-right: -2px;
	}

	.user-balances-icon-second {
		margin-left: 8px;
	}

	.user-balances-icon-freeroll img {
		// margin-left: 5px;
		margin-right: -5px;
	}

	.user-balances-tickets {
		margin-top: 5px;
	}

	.user-balances-item {
		width: 70px;
		// margin-right: 5px;
		// background-color: red;
		display: inline-block;
	}

	.rewards-to-claim {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			background: #ff8a2f;
			border-radius: 5px;
			top: 0.5px;
			right: 0.5px;
		}
	}
}

.icon-chevron {
	display: none;
	margin-top: -15px;

	@include media-large {
		display: block;
	}
}

.profile-actions {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px;
	margin-top: 16px;

	@include media-large {
		display: flex;
		position: absolute;
		padding: 0;
		top: 40px;
	}

	transition-duration: 0.3s;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	max-height: 0;
	
	&.show {
		max-height: 100vh;
		opacity: 1;
		visibility: visible;

		@include media-large {
			display: flex;
			position: absolute;
			top: 60px;
			background: #171a19;
		}
	}

	a {
		color: white;
		text-decoration: none;
		width: 100%;
		font-family: $font-family-title;
		font-weight: 800;
		text-transform: uppercase;
	}

	.edit-profile {
		cursor: pointer;
		color: white;
		transition-duration: 0.3s;

		&:hover {
			color: $color-primary-light-orange;
		}
	}

	.profile-menu-item {
		cursor: pointer;
		color: $color-primary-dark-orange;
		transition-duration: 0.3s;
		padding: 15px;

		&:hover {
			color: $color-primary-light-orange;
		}
	}

	.rewards-to-claim {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			background: #ff8a2f;
			border-radius: 5px;
			margin: -5px 0 0 5px;
		}
	}
}