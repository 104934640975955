@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;700&family=Tomorrow:wght@800&family=Roboto+Condensed:wght@300;400;700&display=swap');
@import "variables.scss";

h1,h2,h3,h4,h5,h6{
    font-family: $font-family-title;
    color: white;
		text-align: center;
}
h1{}
h2{
	color: $color-primary-orange;
	font-size: 18px;
	font-family: $font-family-title;
	text-transform: uppercase;
	font-weight: 800;

}
h3{
	font-size: 40px;
	text-transform: uppercase;
	@include media-small{
		font-size: 70px;
	}
}
h4{}
h5{}
h6{}
.typo-button{}
.typo-link{}
.title{
    font-family: $font-family-title;
}
