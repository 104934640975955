.tournament-join-success-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
}