.rewards-table {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-width: 400px;
  padding-top: 60px;
  margin: 0 20px;
  background: #2C1711;
  opacity: 0.8;
  border: 3px solid rgba(254, 91, 2, 0.5);
  box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.35), inset 0px 0px 200px rgba(255, 92, 0, 0.44);
  position: relative;

  &::before {
    content: '';
    display: block;
    background: #181C1B;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 40%), inset -2px -2px 0px #0d1110, inset 2px 2px 0px #222c29;
    width: calc(100% + 30px);
    height: 57px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 20px 100%, 0 100%, 0 100%);
    position: absolute;
    top: -4px;
    left: -13px;
  }


  &__title {
    font-family: 'Rajdhani';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgb(250, 92, 2);
    text-align: center;
    letter-spacing: -0.08em;
    text-transform: uppercase;
    color: rgba(255, 92, 0, 0.6);
    text-shadow: 0px 0px 40px rgba(255, 66, 0, 70%), 0px 0px 20px rgba(255, 66, 0, 70%), 0px 0px 40px rgba(255, 66, 0, 70%);
  }

  &__items {

    width: 100%;
    height: 100%;
    font-family: 'Tomorrow';
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    padding: 0 20px;

    .reward-item {
      display: flex;
      align-items: center;
      height: 53px;
      border-top: 1px solid rgba(254, 91, 2, 0.5);

      &:first-child {
        border-top: none;
      }

      .money {
        display: flex;
        align-items: center;
        font-family: 'Rajdhani';
        font-weight: 700;
        font-size: 1.1rem;
      }

      &__position {
        color: rgb(180, 180, 180);
        font-weight: 900;
        font-family: sans-serif;
        width: 90px;
        // font-style: italic;
        transform: skewX(-20deg) scaleX(1.5);

        //noinspection CssInvalidPropertyValue
        span {
          display: block;
          padding-left: 8px;
          background: linear-gradient(180deg, white, rgb(63, 31, 17)), #DEDEDE;
          background-clip: text;
          -webkit-text-stroke: 2px transparent;
          font-size: 10px;
          margin-left: 12px;
        }

        &__piece {
          min-width: 70px;
          font-size: 20px;
          margin-right: 8px;
          display: flex;
          align-items: flex-end;
          gap: 0.5ch;

          span {
            padding-left: 4px;
            padding-bottom: 5px;
          }

          &-icon {
            height: 26px;
            margin-left: 5px;

            &-currency {
              height: 20px;
            }
          }
        }
      }

      .reward-currency {
        height: 18px;
        margin-right: 5px;
      }

      //noinspection CssInvalidPropertyValue
      .position--1st {
        margin-left: 10px;
        color: rgb(209, 182, 85);
        font-size: 22px;
        background: linear-gradient(180deg, rgb(247, 214, 130), rgb(51, 25, 13));
        background-clip: text;
        -webkit-text-stroke: 3px transparent;
      }

      //noinspection CssInvalidPropertyValue
      .position--2nd {
        margin-left: 12px;
        color: rgb(233, 233, 233);
        font-size: 16px;
        background: linear-gradient(180deg, white, rgb(63, 31, 17)), #DEDEDE;
        background-clip: text;
        -webkit-text-stroke: 2px transparent;
      }

      //noinspection CssInvalidPropertyValue
      .position--3rd {
        margin-left: 12px;
        color: rgb(212, 151, 89);
        background: linear-gradient(180deg, rgb(230, 177, 118), rgb(63, 31, 17)), #DEDEDE;
        font-size: 14px;
        background-clip: text;
        -webkit-text-stroke: 2px transparent;
      }
    }
  }
}
