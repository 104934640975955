.tournaments-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;

  .tournaments-head__title {
    font-size: 2.5rem;
    text-transform: uppercase;
    margin: 0 0 0;
  }

  &__content {
    width: MIN(90vw, 1260px);
    margin: 0 auto -30px;
    position: relative;

    &__featured {
      font-family: 'Rajdhani', serif;
      font-size: 5rem;
      letter-spacing: -0.05em;
      font-weight: 700;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.5);
      color: transparent;
      text-align: center;
      text-transform: uppercase;
    }

    .tournament-hero-top__info {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
    }
  }

  &__slider {
    height: 250px;
    background: transparent;
    width: 100%;
  }
}
