@import "../../styles/deviants-styles.scss";

.overlay{

  .modal{
    .content{
  
      .error-title{
        font-size: 28px;
      }
      .error-description{
        width: 100%;
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 32px;
      }
      .error-icon{
        color: red;
      }
    }
  }
}