@import 'styles/variables.scss';

.tournament-rules {
  margin-bottom: 30px;
  width: MIN(90vw, 1200px);
  margin: 30px auto;

  &__title {
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    text-align: center;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    justify-content: space-around;

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 400px;
      margin: 20px;
      background-color: $metal-color;
      box-shadow: 4px 4px 10px black, inset -2px -2px 0px #0d1110, inset 2px 2px 0px #222c29;
      clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px));

      &__title {
        padding: 8px;
        display: flex;
        justify-content: center;
        flex: 1 1 auto;
        align-items: center;
        font-family: Rajdhani;
        font-style: normal;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 30px;
        color: #FFFFFF;
        text-transform: uppercase;
      }

      &__content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 120px;
        height: 100%;
        display: flex;
        flex-direction: row;
        font-family: Rajdhani;
        border-left: 2px solid #0d1110;

        >div {
          font-family: Rajdhani;
        }

        &__info {
          display: flex;
          align-items: center;
          font-size: .7rem;
          text-transform: uppercase;
          cursor: pointer;

          img {
            width: 28px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}