// INVENTORY

.inventory{
  &-view{
    display: grid;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 120px 1fr;
    &-loaded{
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
      &-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 8px;
        & .btn{
          margin: 32px 0px;
        }
      }
      .modal{
        .content{
          max-height: 570px;
        }
        &-nft{
          display: grid;
          grid-template-columns: 260px;
          grid-template-rows: 8px 8px 30px 300px auto 14px;
          height: 510px;
          gap: 16px;
          &_title{
            grid-column: 1/2;
            grid-row: 3/4;
            align-self: center;
            justify-self: center;
            z-index: 2;
            text-transform: uppercase;
            font-weight: 800;
          }
          &_image{
            grid-column: 1/2;
            grid-row: 2/5;
            background-size: cover;
            background-position: center;
          }
          &_actions{
            grid-column: 1/2;
            grid-row: 5/6;
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;

            .btn-shadow, .btn{
              width: 100%;
            }
          }
        }
      }
    }

    .connect-wallet{
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
    }
  }
}
