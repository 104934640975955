@import "../../styles/deviants-styles.scss";

.banner{
	display: flex;
	padding: 0px 8px;
	margin-bottom: 32px;
	justify-content: center;
	@include media-xlarge{
		display: grid;
		grid-template-columns: 32px 1fr 32px;
		grid-template-rows: 80px;
		width: 80%;
		margin: auto;
		margin-bottom: 64px;
		filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.5))
	}
	@include media-xxlarge{
		max-width: 1200px;
	}
	&-left{
		display: none;
		&::before{
			@include media-xlarge{
				content: "";
				position: absolute;
				width: 43px;
				height: 6px;
				background: #020101;
				bottom: 12px;
				left: -3px;
				transform: rotate(45deg);
			}
		}
		@include media-xlarge{
			position: relative;
			display: flex;
			width: 32px;
			background-color: rgba(11,9,7,1);
			grid-column: 1/2;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0% calc(100% - 30px));
			box-shadow: 6px 0px #030201  inset, 0px 6px #111713 inset;
			
		}
	}
	&-right{
		display: none;
		&::after{
			@include media-large{
				content: "";
				position: absolute;
				width: 43px;
				height: 6px;
				background: #1B2923;
				transform: rotate(45deg);
				top: 13px;
				right: -2px;
			}
		}
		@include media-xlarge{
			display: flex;
			position: relative;
			width: 32px;
			background-color: rgba(11,9,7,1);
			grid-column: 3/4;
			clip-path: polygon(0 0, calc(100% - 30px) 0%, 100% 30px, 100% 100%, 0 100%);
			box-shadow: -6px -6px #131814 inset;
		}
	}
	&-container{
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		@include media-xlarge{
			background: linear-gradient(90deg, rgba(11,9,7,1) 0%, rgba(25,29,28,1) 50%, rgba(11,9,7,1) 100%);
			flex-direction: row;
			justify-items: center;
			align-items: center;
			grid-column: 2/3;
			gap: 16px;
			justify-content: space-between;
		}

		&::after{
			@include media-xlarge{
				content: "";
				position: absolute;
				width: 100%;
				height: 6px;
				background: linear-gradient(90deg, rgba(17,22,18,1) 0%, rgba(33,44,41,1) 50%, rgba(18,24,20,1) 100%);
				top: 0;
				left: 0;
			}
		}

		&::before{
			@include media-xlarge{
				content:"";
				position: absolute;
				width: 100%;
				height: 6px;
				background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(20,24,20,1) 100%);;
				bottom: 0;
			}
		}
		
		&__text{
			font-family: $font-family-title;
			font-weight: 800;
			text-align: center;
			font-size: 24px;
			margin-bottom: 30px;
			@include media-xlarge{
				margin-bottom: 0px;
				padding: 0px 32px;
			}
		}
		&__decal{
			@include media-xxlarge{
				display: flex;
				background-image: url(./assets/banner-decal.png);
				height: 12px;
				width: 120px;
				background-repeat: no-repeat;
			}
		}
	}
}