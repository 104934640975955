.tournament-detail {
  .tournament-match {
    display: flex;
    justify-content: center;
    align-items: stretch;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    margin: 30px auto 0;

    &__left {
      background: rgb(23, 26, 25);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0 calc(100% - 30px));
    }

    &__info1 {
      border: 3px solid rgb(34, 44, 41);
      border-right: 3px solid rgb(14, 17, 16);
      border-bottom: 3px solid rgb(14, 17, 16);
      padding: 40px;

      &>div {
        font-family: 'Rajdhani';
        font-size: 1.3rem;
      }

      .you-are-in {
        font-size: 1.1em;
        font-weight: 300;

        span {
          font-family: 'Rajdhani';
          font-weight: 700;
          font-size: 1.1rem;
          background: #FF5C00;
          padding: 0px 5px;
        }
      }
    }

    &__info2 {
      padding: 20px 40px;
      font-family: 'Rajdhani';
      font-size: 1.3rem;
      border-left: 3px solid rgb(34, 44, 41);
      border-right: 3px solid rgb(14, 17, 16);
      border-bottom: 3px solid rgb(14, 17, 16);

      span {
        color: #FF5C00;
        font-size: 1.1rem;
        margin-bottom: 3px;
        position: relative;
        top: -2px;
      }
    }

    &__center {
      background: rgba(0, 0, 0, 0.3);
      text-align: center;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 100%);

      img {
        margin: -95px 0;
        width: 320px;
      }

      &>div {
        font-family: 'Rajdhani';
        font-size: 1.3rem;
      }

      span {
        font-family: 'Rajdhani';
        font-weight: 300;
        font-size: 0.9rem;
        color: rgb(131, 146, 143);
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgb(23, 26, 25);
      border: 3px solid rgb(34, 44, 41);
      border-right: 3px solid rgb(14, 17, 16);
      border-bottom: 3px solid rgb(14, 17, 16);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0 calc(100% - 30px));
      width: 350px;
      margin-left: 20px;
    }

    &__deck {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 30px;
      border-bottom: 3px solid rgb(14, 17, 16);
      position: relative;
      text-transform: none;
      font-family: 'Rajdhani';
      font-weight: 300;

      span {
        text-transform: uppercase;
        margin-left: 10px;
        font-weight: 500;
      }

      &::after {
        content: '';
        display: block;
        background-color: rgb(14, 17, 16);
        height: 13px;
        width: 160px;
        position: absolute;
        bottom: -9px;
        border-bottom: 1px solid rgb(34, 44, 41);
        clip-path: polygon(10px 0%, calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 10px 100%, 0% 50%);
      }
    }

    &__play {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }
  }

  .tournament-requirements {
    width: MIN(90vw, 1200px);
    margin: 30px auto 0;
  }

  &-data {
    margin-top: 30px;
    margin-bottom: 50px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset -25px 15px 25px black;
    border-bottom: 5px solid rgba(255, 255, 255, 0.1);

    &__container {
      width: MIN(90vw, 1200px);
      margin: 30px auto 40px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.tournament-timer-container {
  margin: auto;
  width: 580px;
  display: flex;
  justify-content: space-around;
  margin-top: -50px;
}

.tournament-timer-startsin {
  font-family: "Rajdhani";
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  padding-top: 30px;
}