#response-minter-modal {
  .content {
    padding: 0 20px;
    justify-content: stretch;
    align-content: stretch;
    height: 100%;
  }

  .response-minter-modal {
    position: relative;

    &__title {
      font-size: 2rem;
    }

    &__message {
      margin-bottom: 20px;
      text-align: center;
    }

    &__actions {
      display: flex;
      margin-bottom: 10px;
    }

    &__payment-id {
      color: #ff5c00;
      font-size: 1.5rem;
      font-family: "Rajdhani";
      text-transform: uppercase;
      font-weight: 800;
    }

  }

  .btn.my-purchases {
    height: 56px;
    margin-right: 10px;
    color: white
  }

  .btn-shadow {
    justify-self: auto;
  }

  .close.btn {
    position: absolute;
    top: 10px;
    right: 10px;
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
    width: 1.7rem;
    height: 1.7rem;
    box-shadow: -3px -3px 0px 0px rgba(143, 0, 0, 0.25) inset, 3px 3px 0px 0px rgba(255, 233, 153, 0.31) inset;
  }
}