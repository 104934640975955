.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1em;

  .btn {
    width: 50px;
    clip-path: none;
    padding: .3rem;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0px 6px 0px 6px;
    opacity: 0.5;

    &:hover {
      opacity: 0.9;
    }
  }

  .pagination__item--active {
    opacity: 1;
  }

}

.rewards {

  .gridjs-table {
    min-width: 100%;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1em;
  padding: 0 2em;

  &__actions {
    width: 7rem;
    margin: -8px 0;

    &>div {
      height: 100%;
    }
  }

  .gridjs-th-content {
    cursor: pointer;
  }

  .gridjs-pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
  }

  .gridjs-summary {
    font-family: "Rajdhani";
    font-size: 18px;
  }

  .gridjs-pages {
    padding: 10px 10px;

    button {
      background: linear-gradient(274.44deg, #CC4900 0%, rgba(255, 85, 31, 0.73) 67.69%, rgba(254, 91, 0, 0) 100%), #FF5C00;
      box-shadow: -4px -4px 0px 0px rgba(143, 0, 0, 0.25) inset, 4px 4px 0px 0px rgba(255, 233, 153, 0.31) inset;
      margin-right: 10px;
      font-weight: 700;
      font-size: 14px;
      font-family: "Rajdhani";
      color: white;
      padding: 10px;
      cursor: pointer;
    }
  }

}

.claim-button-wrapper {
  display: flex;
}