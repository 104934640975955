.df-selector {
  position: relative;
  background-color: #000;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  
  .df-selector-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .df-selector-content-wrapper {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;

    .df-selector-content {
      background-color: #000;

      .df-selector-item {
        cursor: pointer;
        z-index: 999;
        padding: 5px;
        background-color: #000;

        &___selected {
          background-color: #171717;
        }

        &:hover {
          background-color: #171717;
        }
      }

      &__closed {
        display: none;
      }

      &__opened {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
      }
    }
  }
}