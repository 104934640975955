@import "../../styles/deviants-styles.scss";

.featcards{
  &-section{
    display: grid;
    grid-template-columns: 16px auto 16px;
    width: 100%;
    grid-template-rows: 200px 30px 61px 400px 16px 58px 26px 200px;
    margin-top: -120px;
    @include media-xlarge{
      grid-template-columns: 170px auto 160px; // responsive here
      grid-template-rows: 84px 30px 61px 400px 16px 58px 26px;
      width: 100%;
      // margin-bottom: 128px;
    }
  }
  &-title{
    grid-row: 1/3;
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-list{
    display: flex;
    border-top: 3px solid rgba(254, 91, 2, 0.5);
    border-bottom: 3px solid rgba(254, 91, 2, 0.5);
    box-shadow: 0px 0px 200px 0px rgba(255, 92, 0, 0.44) inset;
    grid-column: 1/4;
    grid-row: 4/5;
    z-index: 1;
    overflow: auto;
    padding: 0px 0px;
    scroll-behavior: smooth;
    @include media-xlarge{
      grid-column: 1/4;
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }
  
  &-top{
    &-right{
      display: none;
      @include media-xlarge{
        display: flex;
      }
      grid-column: -1/-2;
      grid-row: 2/7;
      width: 128px;
      z-index: 2;
      background-size: contain;
      background-repeat: no-repeat;
      justify-self: end;
      background-position-x: 10px;
    }
    &-left{
      grid-column: 1/2;
      grid-row: 2/7;
      width: 128px;
      z-index: 2;
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: -35px;
      @include media-medium{
        background-position-x: 0px;
      }
      
    }
  }
  &-lower{
    &-left{
      &-start{
        grid-column: 1/3;
        grid-row: 2/8;
        width: 172px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &-right{
      &-start{
        display: none;
        @include media-xlarge{
          display: flex;
          grid-column: -1/-2;
          grid-row: 2/8;
          width: 162px;
          background-size: contain;
          background-repeat: no-repeat;
          justify-self: end;
        }
      }
    }
  }
  &-actions{
    grid-column: 2/3;
    grid-row: 6/9;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    @include media-xlarge{
      grid-row: 6/8;
      grid-template-columns: 174px 1fr 495px;
      grid-template-rows: auto;
    }
    &-left{
      width: 174px;
      height: 86px;
      padding-left: 20px;
      align-items: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      gap: 16px;
      &-buttons{
        display: none;
        @include media-xlarge{
          display: flex;
          flex-direction: row;
          gap: 8px;
        };
      }
      &-scroll{
        font-family: $font-family-title;
        color: white;
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
        position: relative;
        display: flex;
        align-items: center;
        opacity: 0.5;
        @include media-xlarge{
          display: none;
        }
        &::before{
          content: "";
          background: white;
          position: relative;
          height: 1px;
          width: 24px;
          margin-right: 16px;
        }
      }
      
    }
    &-drag{
      display: none;
      font-family: $font-family-title;
      color: white;
      font-size: 18px;
      font-weight: 900;
      text-transform: uppercase;
      position: relative;
      align-items: center;
      opacity: 0.5;
      align-self: center;
      justify-self: center;
      width: 85%;
      grid-column: 2/3;
      grid-row: 1/2;
      @include media-xlarge{
        display: flex;
      }
      &::before{
        content: "";
        background: white;
        position: relative;
        height: 1px;
        width: 30%;
        margin: 0 16px;
      }
      &::after{
        content: "";
        background: white;
        position: relative;
        height: 1px;
        width: 30%;
        margin: 0 16px;
      }
    }
    &-right{
      &-bg{
        display: none;
        @include media-xlarge{
          display: flex;
          position: relative;
          justify-self: end;
          align-items: center;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 34px 100%, 0 48px);
          background: linear-gradient(90deg, rgba(24,26,25,1) 0%, rgba(14,12,11,1) 100%);
          border-left: 6px solid #070707;
          border-bottom: 6px solid #0E0E0E;
          height: 80px;
          width: 500px;
          grid-column: 3/4;
          grid-row: 1/2;
          z-index: 1;
        }
        &::after{
          content: "";
          width: 70px;
          height: 6px;
          position: absolute;
          background:#060606;
          transform: rotate(45deg);
          left: -21px;
          top: 60px;
        }
        &::before{
          content: "";
          position: absolute;
          height: 6px;
          width: 100%;
          top: 0;
          background: linear-gradient(90deg, rgba(32,41,39,1) 0%, rgba(20,27,23,1) 100%);
        }
      }
      &-buttons{
        display: flex;
        position: relative;
        align-self: baseline;
        align-items: center;
        height: 80px;
        gap: 16px;
        grid-column: 1/2;
        grid-row: 2/3;
        z-index: 2;
        flex-direction: column;
        @include media-xlarge{
          padding-left: 32px;
          flex-direction: row;
          padding-left: 32px;
          grid-column: 3/4;
          grid-row: 1/2;
        }
      }
      & .btn{
        height: 56px;
        min-height: 56px;
        background-repeat: no-repeat;
        background-size: contain;
        width: 200px;
      }
    }
  }

}