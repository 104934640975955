@import "../../styles/deviants-styles.scss";

.hero{
	&-section{
		width: 100%;
		display: grid;
		justify-items: center;
		position: relative;
		grid-template-columns: 20px 100px auto 100px 20px;
		grid-template-rows: 20px auto 20px 44px 50px;
		height: 95vh;
		top: -132px;
		overflow: hidden;
		min-height: 690px;
		margin-bottom: 0;
		@include media-small{
			grid-template-columns: 20px 120px auto 120px 20px;
		}
		@include media-large{
			grid-template-columns: 20px 220px auto 220px 20px;
		}
		@include media-xlarge{
			grid-template-columns: 275px 100px auto 100px 275px;
		}
		@include media-xxlarge{
			height: 100vh;
			grid-template-columns: 375px auto 375px;
			grid-template-rows: 1fr 4fr 1fr 72px 50px;
		}
		&-container{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			grid-column: 2/5;
			grid-row:2/5;
			@include media-small{
				grid-column: 3/4;
			}
			@include media-xlarge{
				grid-column: 2/5;
			}
			@include media-xxlarge{
				grid-row: 2/4;
				grid-column: 2/3;
			}
		}
		&-title{
			font-family: $font-family-title;
			font-size: 3.5rem;
			text-align: center;
			width: 100%;
			line-height: 3rem;
			margin-bottom: 24px;
			text-shadow: 0 4px rgba(0,0,0,0.5);
			@include media-small{
				width: 140%;
			}
			@include media-medium{
				width: 90%;
			}
			@include media-landscape{
				width: 90%;
				font-size: 3rem;
			}
			@include media-xlarge{
				line-height: 4.5rem;
				font-size: 4.5rem;
			}
		}
		&-subtitle{
      text-shadow: 2px 0 0 rgba(0,0,0,0.25), -2px 0 0 rgba(0,0,0,0.25), 0 2px 0 rgba(0,0,0,0.25), 0 -2px 0 rgba(0,0,0,0.25), 1px 1px rgba(0,0,0,0.25), -1px -1px 0 rgba(0,0,0,0.25), 1px -1px 0 rgba(0,0,0,0.25), -1px 1px 0 rgba(0,0,0,0.25);
			text-transform: uppercase;
			font-weight: 600;
			font-size: 1rem;
			font-family: $font-family-title;
			margin-bottom: 24px;
			text-align: center;
			width: 60%;

			@include media-xlarge{
				font-size: 1.6rem;
				line-height: 1.5rem;
				margin-bottom: 48px;
			}
		}
		&-scroll-down{
			font-family: $font-family-title;
			font-weight: 700;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 1rem;
			grid-column: 3/4;
			grid-row: 4/5;
			@include media-small{
				grid-column: 3/4;
			}
			@include media-xlarge{
				grid-column: 2/5;
			}
			@include media-xxlarge{
				grid-column: 2/3;
			}
			&::after{
				content:"";
				position: relative;
				overflow: hidden;
				display: flex;
				width: 2px;
				height: 72px;
				background: linear-gradient(180deg, #FE5B02 0%, rgba(254, 91, 2, 0) 100%);
			}
		}
		&-left{
			grid-column: 1/3;
			grid-row: 4/6;
			width: 200%;
			align-self: center;
			left: -55px;
			position: relative;
			@include media-medium{
				left: 0;
				width: 150%;
			}
			@include media-xlarge{
				grid-column: 1/3;
			}
			@include media-xxlarge{
				grid-row: 4/6;
				grid-column: 1/2;
				align-self: flex-start;
				width: 100%;
				left: 0;
			}
		}
		&-right{
			grid-column:4/6;
			grid-row: 4/6;
			width: 200%;
			position: relative;
			align-self: center;
			right: -55px;
			@include media-medium{
				right: 0;
				width: 150%;
			}
			@include media-xlarge{
				grid-column: 4/6;
			}
			@include media-xxlarge{
				grid-row: 4/6;
				grid-column: 3/4;
				width: 100%;
				align-self: flex-start;
				right: 0;
			}
		}
		&-background{
			grid-column: 1/6;
			grid-row: 1/5;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-origin: border-box;
			background-position: center;
			background-image: url(./assets/actual/entropy/mobile.jpg);
			@include media-medium{
				background-image: url(./assets/actual/entropy/bg.jpg);
				background-position-y: 90px;
			}
			@include media-xlarge{
				grid-column: 1/6;
			}
			@include media-xxlarge{
				grid-column: 1/4;
				grid-row: 1/5;

			}
		}
		&-actions{
			display: flex;
			flex-direction: column;
			gap: 24px;
			a{
				position: relative;
			}
			.new-asset-img{
				position: absolute;
				top: -8px;
				z-index: 3;
				width: 72px;
   			left: 0;

			}
      &.actions-row {
        flex-direction: column;
        @include media-large {
          flex-direction: row;
        }
      }
		}
		.outbreak-link{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.outbreak-launch{
			display: flex;
			width: 100%;
			height: 100px;
			margin-top: 48px;
			background-image: url('../../assets/img/launch-mobile.png');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			@include media-medium{
				width: 320px;
				margin-top: 0;
			}
			@include media-xlarge{
				background-image: url('../../assets/img/launch-desktop.png');
				width: 210px;
				height: 250px;
				position: absolute;
				top: 7%;
				left: 2%;
				margin-top: 48px;
			}
		}
		.hero-button{
			&.btn-play{
				transform: scale(1.1);
				.btn.btn-primary{
					font-size: 24px;
				}
			}
			&.btn-rulebook{
				transform: scale(0.8);
			}
		}
	}
}
