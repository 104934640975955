@import "../../styles/deviants-styles.scss";

@include media-mobile {
  main {
    margin-top: 100px;
  }
}

main {
  margin-top: 130px;
}

header {
  display: grid;
  justify-content: space-between;
  top: 0;
  z-index: 9;
  font-family: $font-family-title;
  background-repeat: no-repeat;
  background-size: contain;
  height: 132px;
  grid-template-columns: 260px 1fr;
  grid-template-rows: 1fr 31px;

  position: fixed;
  width: 100vw;
  // >nav {
  //   background-color: red;
  //   >div{
  //     background-color: blue;
  //   }
  // }
  @include media-mobile {
    position: fixed;
    width: 100vw;
  }

  & .show {
    display: flex;
  }
  & .hide {
    display: none;
  }
  & nav.hide {
    animation: slide-out 0.3s;
  }
  & nav.show {
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    animation: slide-in 0.3s;
    padding-top: 120px;
    overflow: auto;
    justify-content: flex-end;
    overflow-x: hidden;

    @include media-mobile {
      padding-left: 1rem;
      height: 100vh;
      overflow-y: auto;

      > div:first-child {
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 2rem;
      }
    }

    .navigation-link {
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 8px 12px;
      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
      span {
        font-size: 16px;
        color: white;
        font-family: $font-family-title;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.03em;
        @include media-xxlarge {
          font-size: 18px;
        }
      }
    }
  }
  & .button-holder {
    grid-column: 2/3;
    grid-row: 1/2;
    align-self: center;
    justify-self: flex-end;
    display: flex;
    z-index: 3;
    padding-right: 16px;
    @include media-large {
      display: none;
    }
  }
  @include media-large {
    grid-template-columns: 180px 1fr 200px;
    grid-template-rows: 89px 17px;
  }
  @include media-xlarge {
    grid-template-columns: 200px 1fr 332px;
    grid-template-rows: 89px 17px;
  }
  @include media-xxlarge {
    grid-template-columns: 300px 1fr 332px;
    grid-template-rows: 89px 17px;
  }
  .header-holder {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
  }
  .header-mobile-bg {
    grid-column: 1/2;
    grid-row: 1/3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
    width: 100%;
    height: 100%;
    @include media-large {
      grid-row: 1/4;
      height: 115px;
    }
    @include media-xlarge {
      display: none;
    }
  }
  .header-left-bg {
    display: none;

    @include media-xlarge {
      display: flex;
      grid-column: 1/2;
      grid-row: 1/4;
      background-position-x: -114px;
    }
    @include media-xxlarge {
      background-position-x: -48px;
    }
  }
  .header-right-bg {
    display: none;
    @include media-large {
      display: flex;
      grid-column: 3/4;
      grid-row: 1/4;
      background-position: left;
    }
  }
  .logo {
    display: flex;
    grid-column: 1/2;
    grid-row: 1/2;
    padding-left: 8px;
    z-index: 3;
    align-items: center;
    @include media-small {
      padding-left: 16px;
    }
    img.logo {
      width: auto;
      height: 48px;
      align-self: center;
      @include media-xxlarge {
        height: 64px;
      }
    }
  }
  .menu {
    grid-column: 2/3;
    grid-row: 1/2;
    position: relative;
    height: 100%;
    background: rgba(18, 18, 17, 1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    &::after {
      content: "";
      width: 100%;
      height: 6.5px; // pardon me css gods
      background: linear-gradient(90deg, #18211e 0%, rgba(24, 32, 29, 1) 100%);
      position: absolute;
      bottom: 0;
    }
    &.show {
      display: flex;
    }
    @include media-medium {
    }
    .sub-menu {
      display: none;
    }
    nav {
      display: none;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: 90px;
      background: linear-gradient(
        90deg,
        rgba(17, 17, 16, 1) 0%,
        rgba(26, 30, 29, 1) 50%,
        rgba(17, 17, 16, 1) 100%
      );
      grid-column: 1/2;
      grid-row: 1/2;
      &::after {
        content: "";
        width: 100%;
        height: 7.5px; // pardon me css gods
        background: linear-gradient(
          90deg,
          rgba(24, 32, 29, 1) 0%,
          rgba(33, 44, 42, 1) 50%,
          rgba(24, 32, 29, 1) 100%
        );
        position: absolute;
        bottom: 0;
      }
      @include media-large {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-column: 2/3;
        justify-content: space-evenly;
        padding: 0;
        width: 100%;
      }
      .navigation-link {
        padding: 0 8px;
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include media-large{
          padding: 8px;
        }
        &-selected.navigation-link span {
          color: $color-primary-orange;
          filter: drop-shadow(0px 0px 11px #ff5207);
        }
        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
        span {
          font-size: 16px;
          color: white;
          font-family: $font-family-title;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 0.03em;
          @include media-xxlarge {
            font-size: 18px;
          }
        }
        svg {
          margin: 0 0 0 8px;
          @include media-xxlarge {
            margin: 0 16px;
          }
        }
        &:last-child {
          margin-bottom: 32px;
          @include media-small {
            margin-bottom: 0;
          }
        }
        &:not(.disabled) :hover {
          color: $color-primary-orange;
          filter: drop-shadow(0px 0px 11px #ff5207);
        }
        svg.svg-inline--fa {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .btn-menu {
    grid-row: 1/2;
    grid-column: 2/3;
    z-index: 2;
    justify-self: end;
    align-self: center;
    @include media-large {
      display: none;
    }
  }
  .btn-connect {
    display: none;
    z-index: 3;
    min-width: 235px;
    justify-content: flex-end;
    @include media-large {
      display: flex;
      flex-direction: column;
      flex: 1;
      grid-column: 3/4;
      grid-row: 1/2;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      align-self: center;
      justify-self: end;
      justify-content: flex-end;
      margin-right: 16px;
      & > .btn-shadow .btn {
        width: 100px;
      }
      p {
        display: none;
      }
    }
    @include media-xlarge {
      display: flex;
      align-self: center;
      justify-self: end;
      margin-right: 16px;
      grid-column: 3/4;
      grid-row: 1/2;
      & > .btn-shadow .btn {
        width: 215px;
      }
      p {
        display: flex;
      }
    }
  }
  .btn-connect-mobile {
    align-self: center;
    margin-top: 24px;
    margin-bottom: 16px;
    width: 100%;
  }
  .header-dropdown{
    padding: 12px;
    @include media-large{
      padding: 0;
      &:hover{
      }
    }
    &-title{
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: $font-family-title;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 18px;
      padding: 0;
      cursor: pointer;
      position: relative;
      @include media-large{
        &:hover{
          color: $color-primary-orange;
          filter: drop-shadow(0px 0px 11px #ff5207);

        }
      }
      .svg-inline--fa{
        display: none;
        @include media-large{
          display: block;

        }
      }
    }
    &-title.active{
      color: $color-primary-orange;
    }
    &-elements{
      transition-duration: 0.3s;

      @include media-large{
        position: relative;
      }
      &.inactive{
        display: none;
        @include media-large{
        }
      }
      .dropdown-content{
        grid-column: 1/2;
        grid-row: 1/2;
        z-index: 2;
        padding: 0 16px;
        @include media-large{
          padding: 16px;
        }
        .navigation-link{
          @include media-small{
            min-width: 160px;
          }
        }

      }
      .dropdown-background{
        display: none;
        @include media-large{
          display: block;
          grid-column: 1/2;
          grid-row: 1/2;
          background: rgba(44,23,17,0.9);
          box-shadow: 0px 0px 200px 0px rgba(255, 92, 0, 0.44) inset, 0px 24px 74px 0px rgba(0, 0, 0, 0.35),0px 4px 14px 0px rgba(0, 0, 0, 0.35);
          padding: 16px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: $deviant-border;
          clip-path: polygon(10px 0%, calc(100% - 10px) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0% calc(100% - 10px), 0% 10px);
          z-index: 1;
        }
      }
      & .header-dropdown .submenu{
        display: none;
      }
      & .header-dropdown .submenu.active{
        display: grid;
      }
      & .header-dropdown .submenu{
        position: absolute;
        left: 100%;
        margin-top: -24px;
        padding: 8px;
        z-index: 3;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        &-content{
          grid-column: 1/2;
          grid-row: 1/2;
          z-index: 2;
          padding: 16px;
        }
        &-background{
          display: none;
          @include media-large{
            display: block;
            grid-column: 1/2;
            grid-row: 1/2;
            background: rgba(44,23,17,0.9);
            box-shadow: 0px 0px 200px 0px rgba(255, 92, 0, 0.44) inset, 0px 24px 74px 0px rgba(0, 0, 0, 0.35),0px 4px 14px 0px rgba(0, 0, 0, 0.35);
            padding: 16px;
            z-index: 3;
            border: $deviant-border;
            clip-path: polygon(10px 0%, calc(100% - 10px) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0% calc(100% - 10px), 0% 10px);
            z-index: 1;
        }
        }
      }

    }
    &-elements.inactive{
      display: flex;
      flex-direction: column;
      @include media-large{
        visibility: hidden;
        opacity: 0;
      }
      position: absolute;
      @include media-mobile {
        position: relative;
      }

      & .navigation-link{
        padding-left: 8px;
        padding-right: 0;
        @include media-large{
          padding-left: 0;
        }
        &:first-child{
          @include media-large{
            margin-top: 10px;
          }
        }
      }
    }

    &-elements.active{
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      flex-direction: column;
      margin-top: 10px;

      @include media-large{
        position: absolute;
        margin-top: 48px;
      }
      .dropdown-content a.navigation-link:first-child{
          margin-top: 0;
      }
      & .navigation-link{
        padding-left: 8px;
        padding-right: 0;
        @include media-large{
          padding-left: 0;
        }
      }
    }
  }
}
@keyframes slide-in {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}
@keyframes slide-out {
  from {
    left: 0;
  }
  to {
    left: -100vw;
  }
}

.btn .wallet-connected {
  font-size: 16px;
  width: 120px;
  text-overflow: ellipsis;
}

