@import "../../styles/deviants-styles.scss";

$deviant-border: 2px solid #813008;

.faction-section {
  font-family: "Rajdhani" !important;
  margin: 60px 0 60px 0;
  // display: flex;
  // flex-direction: column;
  h2, h3 {
    text-align: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgba(255, 84, 0, 0.1);
    box-shadow: 0 0 150px rgba(255, 84, 0, .4) inset;
    padding-top: 20px;
    margin-top: 50px;
    border: $deviant-border;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    clip-path: polygon(calc(100% - 30px) 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 30px 100%, 0 calc(100% - 30px), 0 30px, 30px 0);
    &::before {
      content: '';
      position: absolute;
      background-color: #813008;
      width: 48px;
      height: 2px;
      bottom: 14px;
      left: -11px;
      transform: rotate(45deg);
      @include media-small{
        width: 74px;
        top: 22px;
        left: -12px;
        transform: rotate(-45deg);
      }
    }
    @include media-small{
      width: auto;
      border-radius: 40px 40px 40px 0;
      padding: 20px 30px 30px 30px;
      margin: 60px 40px 0 40px;
      clip-path: polygon(calc(100% - 50px) 0, 100% 50px, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%, 0 50px, 50px 0);
    }
  }
  
  &__content{
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    &::after{
      @include media-small{
        content: '';
        position: absolute;
        background-color: #813008;
        width: 70px;
        height: 2px;
        top: 22px;
        right: -11px;
        transform: rotate(45deg);
      }
    }
  }
  
  &__factions-list {
    display: flex;
    flex-direction: row;
    padding-inline-start: 0;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
    
   
      &::before {
        content: '';
        position: absolute;
        background-color: #813008;
        width: 110px;
        height: 2px;
        top: 20px;
        left: -49px;
        transform: rotate(-45deg);
      }
      &::after {
        content: '';
        position: absolute;
        background-color: #813008;
        width: 42px;
        height: 2px;
        bottom: 13px;
        right: -7px;
        transform: rotate(-45deg);
        // display: none;
        @include media-medium{
          width: 110px;
          height: 2px;
          bottom: 20px;
          right: -49px;
        }
      }

    li {
      font-family: $font-family-title;
      padding: 5px 10px 5px 23px;
      list-style: none;
      position: relative;
      margin-right: 0;
      cursor: pointer;
      flex: 1 0 45%;
      font-weight: bold;
      display: flex;
      justify-content: center;
      @include media-small{
        padding: 5px 10px 5px 30px;
        font-size: 1rem;
        margin-right: 10px;
        
      }
      @include media-medium{
        flex: 0 0;
      }
      svg {
        position: absolute;
        fill: $color-primary-orange;
        width: 15px;
        height: 15px;
        left: 5px;
        top: 5px;
        @include media-small{
          width: 20px;
          height: 20px;
        }
      }

      &::marker {
        display: none;
      }
      &.selected {
        border: 3px solid $color-primary-orange;
        clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
        line-height: 1;
        border-radius: 0 10px 0 10px;
        box-shadow: 0 0 10px rgba($color-primary-orange, .8) inset;;

        svg {
          fill: white;
          left: 5px;
          top: 2px;
          filter: drop-shadow(0 0 5px $color-primary-orange);
        }

        &::before {
          content: '';
          position: absolute;
          width: 20px;
          height: 3px;
          transform: rotate(45deg);
          top: 0;
          right: -6px;
          background-color: $color-primary-orange;
        }
        &::after {
          content: '';
          position: absolute;
          width: 20px;
          border-bottom: 3px;
          transform: rotate(45deg);
          bottom: 0;
          left: -6px;
          border-bottom: 4px solid $color-primary-orange;
        }
      }
    }
  }

  &__image {
    border: $deviant-border;
    margin-top: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    @include media-small{
      margin: 20px 40px;
    }
    box-shadow: 0 0 10px rgba(255, 84, 0, .9);
    &-wrapper {
      display: flex;
      position: relative;
      flex-grow: 0;
    }
    &-logo {
      position: absolute;
      bottom: -10px;
      right: 10px;
      width: 120px;
      height: 120px;
    }
  }

  &__description {
    padding: 20px 30px 85px;
    position: relative;
    flex: 1 1 160px;
    width: 320px;    
    @include media-small{
      width: auto;
    }
    &-title {
      font-size: 2.3rem;
      font-weight: 700;
      transform: scaleY(1.2);
      margin: 10px 0 20px 0;
      position: relative;

      span {
        display: none;
        animation: factionTitle .3s ease-in-out;
        // transform: translateX(200px);

        &.selected {
          display: block;
        }
      }
      &::before {
        content: '';
        position: absolute;
        height: 18px;
        top: 21px;
        left: -23px;
        border-left: 1px solid rgba($color-primary-orange, .5);
        transform: rotate(45deg);
      }
      &::after {
        content: '';
        position: absolute;
        width: 10px;
        top: 23px;
        left: -17px;
        border-top: 1px solid rgba($color-primary-orange, .5);
      }
    }
    p {
      font-family: "Rajdhani";
      display: none;
      animation: factionDescription .5s ease-out;
      color: #E5E5E5;
      &.selected {
        display: block;
      }
    }
    @include media-small{
      &::before {
        content: '';
        position: absolute;
        height: 190px;
        top: 69px;
        left: 1px;
        border-left: 1px solid rgba($color-primary-orange, .5);
      }
      &::after {
        content: '';
        position: absolute;
        height: 18px;
        top: 256px;
        left: -6px;
        border-left: 1px solid rgba($color-primary-orange, .5);
        transform: rotate(45deg);
      }
    }
  }
  
  &__tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 60px);

    &-buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    &-info {
      color: #878787;
      position: relative;
      letter-spacing: 2pt;
      font-family: "Rajdhani";
    }
    &::before {
      content: '';
      height: 1px;
      width: calc(100% - 160px);
      background-color: #878787;
      position: absolute;
      left: 110px;
      bottom: 17px;
    }
    @include media-small{
      &::after {
        content: '';
        position: absolute;
        background-color: #813008;
        width: 110px;
        height: 5px;
        bottom: -21px;
        right: -102px;
        transform: rotate(-45deg);
      }
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    width: 35px;
    height: 35px;
    border: 3px solid $color-primary-orange;
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
    line-height: 1;
    position: relative;
    cursor: pointer;
    transition: background-color .4s ease-in-out;

    &:hover {
      box-shadow: 0 0 10px rgba(255, 84, 0, .9);
      background-color: $color-primary-orange;
    }
    span {
      transform: scaleY(1.2);
      margin-top: -4px;
    }
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 3px;
      transform: rotate(45deg);
      top: 0;
      right: -6px;
      background-color: $color-primary-orange;
    }
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      border-bottom: 3px;
      transform: rotate(45deg);
      bottom: 0;
      left: -6px;
      border-bottom: 4px solid $color-primary-orange;
    }
  }
  &__back-button {
    clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
    margin-right: 10px;

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 3px;
      transform: rotate(-45deg);
      top: 0;
      left: -6px;
      right: inherit;
      background-color: $color-primary-orange;
    }
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      border-bottom: 3px;
      transform: rotate(-45deg);
      bottom: 0;
      right: -6px;
      left: inherit;
      border-bottom: 4px solid $color-primary-orange;
    }
  }
  &__forward-button {
    border: 3px solid $color-primary-orange;
  }
  
}

.img-image {
  max-width: 100%;
  height: auto;
  background-color: lightgrey;
  display: none;
  &.selected {
    display: block;
  }
}

.img-logo {
  width: 120px;
  height: 120px;
  display: none;
}

@keyframes factionTitle {
  0% {transform: translateX(100px); opacity: 0;}
  100% {transform: translateX(0); opacity: 1;}
}
@keyframes factionDescription {
  0% {transform: translateY(110px); opacity: 0;}
  100% {transform: translateY(0); opacity: 1;}
}

/* flip something */
.front, .back {
  display: block;
	backface-visibility: hidden;

	position: absolute;
	top: 48px;
	left: 0;
  @include media-large{
    top: 0;
  }
}
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
  box-shadow: 0 0 10px rgba(255, 84, 0, .9);
  position: relative;
}
.front {
	z-index: 2;
	transform: rotateY(0deg);
}
.back {
	transform: rotateY(180deg);
}
.flip-container {
  width: 120px;
  height: 120px;
}
.flip-back {
  transform: rotateY(180deg);
}
.flip-front {
  transform: rotateY(0);
}