@import "../../styles/deviants-styles.scss";

.crafting-view {
  min-height: 80vh;
  position: relative;
  padding-top: 2%;

  @media (max-width: 1367px) {
    padding-top: 5%;
  }

  .crafting-header {
    background-color: rgb(43, 43, 43);
    position: absolute;
    top: -3.6vh;
    height: 75px;
    left: 0px;
    width: 100%;
    background-color: #1c1b1b;
    box-shadow: inset 5px 15px 15px 1px #131313, inset 0px -2px 8px 1px #444444;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    .pending-craftings {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      row-gap: 5px;
      flex: 1/4;
      width: 20%;
    }

    .crafting-links {
      align-self: flex-end;
      flex: 1;
      align-self: center;
      text-align: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-evenly;

      a {
        text-decoration: none;
        color: $color-primary-orange;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h1 {
      flex: 1 / 2;
      color: #fff;
      text-shadow: 0px 0px 15px #460644;
      background-image: url(./assets/cleft.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 5%;
    }
  }

  .crafting-container {
    padding: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    height: 80vh;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      height: auto;
      width: auto;
    }

    .recipes-container {
      width: 50%;
      padding: 1%;
      overflow-y: scroll;

      @media (max-width: 900px) {
        height: auto;
        width: 100%;
      }

      .crafting-recipes {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 10%;
        row-gap: 20px;
        max-height: 85vh;
        min-height: 60vh;

        .selected-recipe {
          box-shadow: 0px 0px 15px 1px #EA3FE4,
            0px 5px 14px -2px #FF9900,
            -5px 0px 14px 0px #0FFFBB;
        }

        .recipe-unavailable {
          img {
            filter: grayscale(100%);
          }

          &:hover {
            cursor: not-allowed;
          }
        }

        .crafting-recipe {
          padding: 1%;
          border-radius: 5px;
          width: 25%;
          text-align: center;
          transition: box-shadow 0.1s ease-in-out;
          position: relative;
          height: 45%;

          .recipes-left {
            position: absolute;
            bottom: 15px;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            z-index: 2;

            &__label {
              padding: 2.5px;
              border-radius: 4px;
              background-color: #f97204d6;
              font-weight: 600;
              text-shadow: 0px 0px 15px #000;
              font-size: 1.1rem;
              font-family: 'Rajdhani',
                sans-serif;
            }
          }

          &:active {
            scale: 0.95;
          }

          &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            z-index: 1;
            display: grid;
            place-content: center;
            background-color: rgba(208, 20, 127, 0.1);
            transition: opacity 0.2s ease-in-out;
            opacity: 0;

            h1 {
              color: #fff;
              font-size: 2.5rem;
              text-shadow: 0px 0px 15px #460644;
            }

            img {
              filter: drop-shadow(0px 0px 15px #ff74fa);
              opacity: 0.8;
            }

            &:hover {
              opacity: 1;
              cursor: pointer;
              box-shadow: 0px 0px 15px 1px #EA3FE4,
                0px 5px 14px -2px #FF9900,
                -5px 0px 14px 0px #0FFFBB;
            }

          }

          .show-overlay {
            opacity: 1;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .crafting-table {
      width: 50%;
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      border-radius: 5px 0px 0px 5px;
      background-color: #1f1f1f;
      /* box-shadow: inset -5px 5px 10px 1px #444444, -5px -10px 20px 10px #101010; */
      position: relative;

      .union {
        position: absolute;

        @media screen and (max-width: 1367) {
          display: none;
        }

        &__a {
          top: 5px;
          left: 5px;
          rotate: -180deg;
        }

        &__b {
          top: 45px;
          left: 62%;
          rotate: -90deg;
        }

      }

      @media (max-width: 900px) {
        width: 100%;
      }

      .ingredients-container {
        height: 40%;

        h2 {
          text-align: left;
          padding: 0 4%;
        }

        .ingredient-list {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          height: 100%;
          padding: 1%;

          .recipe-ingredient {
            width: 30%;
            height: 100%;
            scale: 0.8;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px;
            border-radius: 8px;
            background-color: #151515;
            /* box-shadow: 2px 2px 1px 1px #444444, inset 5px 5px 15px 1px #191919; */
            border: 1px dashed #dcdcdc79;
            position: relative;

            .crafting-ingredient-metadata {
              position: absolute;
              left: 0;
              bottom: -25px;
              width: 100%;
              background-color: #e94e0b;
              color: #fff;
              font-size: 0.8rem;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              padding: 5px 0;
              font-family: 'Rajdhani', sans-serif;
              font-weight: 600;
              visibility: hidden;
              z-index: 10;
            }

            .ingredient-status {
              position: absolute;
              top: 60%;
              left: 0;
              width: 100%;
              text-align: center;
              font-size: 1.5rem;
              font-weight: bolder;
              font-family: 'Rajdhani',
                sans-serif;
              padding: 10px 0;
            }

            .__ready {
              background-color: #03664a84;
              box-shadow: inset 0px 0px 15px #0fffbb, 0px 0px 15px #0fffbb;
              text-shadow: 1px 1px 1px #000;
            }

            .__not-ready {
              background-color: #35363684;
              box-shadow: inset 0px 0px 15px #414443, 0px 0px 15px #3a3d3c;
              text-shadow: 1px 1px 1px #000;

              p {
                font-size: 0.9rem;
              }
            }

            .__not-enough {
              background-color: #e70a0a59;
              box-shadow: inset 0px 0px 15px #c40f0f, 0px 0px 15px #ad0a49;
              text-shadow: 1px 1px 1px #000;
            }

            .ingredient-overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              z-index: 1;
              display: grid;
              place-content: center;
              background-color: rgba(33, 14, 2, 0.3);
              text-shadow: 0px 0px 15px #000;
              opacity: 0;

              &:hover {
                opacity: 1;
              }
            }

            &:hover {
              border: 3px solid #dcdcdc79;
              cursor: pointer;

              .crafting-ingredient-metadata {
                visibility: visible;
              }
            }

            .qty-not-satisfied {
              border: 1px solid #888787;
              border-radius: 4px;
            }

            .qty-satisfied {
              border: 1px solid #0fffbb;
              border-radius: 4px;
            }

            .no-ingredient {
              border: 1px solid #e61325;
              border-radius: 4px;
            }

            .ingredient-info {
              display: flex;
              justify-content: space-evenly;

              &__qty {
                font-weight: bold;
              }

              .qty-not-satisfied {
                color: #888787;
                border: none;
              }

              .qty-satisfied {
                color: #0fffbb;
                border: none;
              }

              &__name {
                font-weight: bold;
              }

              .has-ingredient {
                color: #0fffbb;
                border: none;
              }

              .no-ingredient {
                color: #e61325;
                border: none;
              }

            }

            img {
              width: 100%;
              max-height: 90%;
            }
          }
        }
      }

      .crafting-table-details {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 45%;
        justify-content: space-between;

        .recipe-info {
          display: flex;
          flex-direction: column;
          padding: 1%;
          row-gap: 2%;
          width: 70%;
          height: 100%;
          align-items: center;
          justify-content: center;

          .craft-name-cleft {
            align-self: center;
            justify-self: center;
            background-image: url(./assets/cleft.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 100%;

            h1 {
              opacity: 0.5;
            }
          }

          .crafting-info {
            height: 75%;
            width: 80%;
            display: flex;
            flex-direction: column;
            row-gap: 2em;
            justify-content: space-around;
            position: relative;

            .desc-inside {
              font-size: 0.9rem;
            }

            .desc-inside .card-desc-arrow {
              font-size: 0.8rem;
              padding: 0px auto;
            }

            .desc-inside .card-desc-step {
              font-size: 0.9rem;
            }

            @media screen and (max-width: 1367px) {
              .desc-inside {
                font-size: 0.75rem;
                width: 100%;
              }

              .desc-inside .card-desc-arrow {
                font-size: 0.5rem;
                padding: 0px 10px;
              }

              .desc-inside .card-desc-step {
                font-size: 0.70rem;
              }
            }

          }

          .crafting-cost {
            height: 20%;
            width: 92%;
            display: flex;
            align-items: center;
            place-content: space-evenly;
            background-image: url(./assets/cleft.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .crafting-cost-placeholder {
              color: rgba(80, 80, 80, 0.645);
              font-size: 1.4rem;
              font-weight: bold;
              text-decoration: line-through;
            }

            .cost-item {

              img {
                object-fit: scale-down;
              }

              column-gap: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }
        }

        .crafting-result {
          /* box-shadow: -5px 5px 10px 1px #444444, inset 5px 5px 15px 1px #191919; */
          background-color: #151515;
          padding: 2.5px 5px;
          border-radius: 0px 0px 0px 8px;
          justify-self: flex-end;
          width: 30%;
          position: relative;
          display: grid;
          place-content: center;

          .no-recipe-image {
            filter: grayscale(100%);
            opacity: 0.2;
            align-self: center;
            place-self: center;
            width: 100%;
            object-fit: cover;
          }

          .recipe-image {
            align-self: center;
            place-self: center;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .crafting-table-footer {
        height: 12%;
        display: grid;
        place-content: center;
        position: relative;

        .crafting-table-holder-r {
          position: absolute;
          right: -1%;
          width: 40%;
          transform: scaleX(-1);
        }

        .crafting-table-holder-l {
          position: absolute;
          left: -1%;
          bottom: -40px;
          width: 40%;
          filter: drop-shadow(0px 0px 15px #0c0c0c);
        }

      }
    }
  }

  .store-img-icon {
    filter: grayscale(100%);
    z-index: 2;
    position: relative;

    &:hover {
      filter: grayscale(0%);
      filter: drop-shadow(0px 0px 15px #d85509);
    }
  }
}