.layer-swap-button {
  background-color: #ff5207;
  border-radius: 4px;
  padding: 10px;
  box-shadow: '0px 0px 15px rgba(0,0,0,0.5)';

  &:hover {
    outline: 1.5px solid #ff5107e6;
    background-color: #ff5107e6;
  }
}