@import "styles/variables.scss";

.tournament-join-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  &__name {
    font-family: Rajdhani, serif;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &__select-deck {
    background: rgba(10, 10, 10, 0.6);
    padding: 20px;
    margin-top: 20px;

    > div {
      margin-top: 20px;
      font-family: Rajdhani, serif;
      font-size: 0.8rem;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    .deck-selector {
      margin: 10px 0 0 0;
    }
  }

  &__accept-terms {
    margin-top: 20px;

    input {
      margin-right: 10px;
    }

    label {
      margin-right: 5px;
    }
  }

  &__pre-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    width: 100%;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    width: 100%;
  }

  label {
    font-family: Rajdhani, serif;
    font-size: 1.1rem;
    font-weight: 300;
  }

  &__entry-fee {
    font-weight: 700;

    .money {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
  }

  &__tickets {
    height: 16px;
  }

  select {
    margin-left: 10px;
    width: calc(100% - 100px);
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    margin: 20px;
    background-color: rgb(55, 25, 13);
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    background-color: inherit;
    outline: 1px $color-primary-orange solid;
    transform: translate(-50%, -50%);
  }

  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    background-color: $color-primary-orange;
    outline: 1px $color-primary-orange solid;
    transform: translate(-50%, -50%);
  }

  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 10px;
    border: solid;
    border-width: 0 2px 2px 0;
    border-color: white;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .tournament-rules {
    width: 75%;
    text-align: left;
    font-size: 0.8em;
    font-family: 'Rajdhani', serif;
  }

  .warning-text {
    color: #FF5C00;
    font-size: 1em;
    font-family: 'Rajdhani', serif;
  }
}