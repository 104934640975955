.player-cashier-view {
  
  &__title {
    margin-bottom: 2em;
  }
  
  &__footer {
    margin-top: 4em;
    margin-bottom: 2em;
  }
}

.player-cashier-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2em;
  column-gap: 1.5em;
} 

@media (max-width: 1200px) {
  .player-cashier-container {
    flex-direction: column;
    gap: 1em;
    align-items: center;
  }
}
