.entry-fee {
  &__items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    gap: 1ch;
  }

  >div,
  .money>div {
    font-family: 'Rajdhani', serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__ticket {
    font-size: 2rem;

    span {
      font-size: 0.9rem;
      display: inline-block;
      margin: 0 10px 0 5px;
    }
  }

  &__name {
    font-size: 0.6rem;
    padding-left: 6px;
  }

  & div:nth-child(1) {
    font-size: 1rem;
    font-family: 'Rajdhani';
  }

  & div:nth-child(2) {
    font-weight: 700;
    text-align: center;
  }

  & div:nth-child(3) {
    display: flex;
    align-items: center;
    font-weight: 700;
  }
}