.progress-bar {
  width: 100%;
  position: relative;
  &__background-bar {
    height: 5px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.81435px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__bar {
    height: 3px;
    top: 1;
    left: 0;
    background-color: #FF5C00;
    border-radius: 2.81435px;
  }
}