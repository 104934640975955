@import "../styles/deviants-styles.scss";

// PROJECT INFO
.tutorials {

  a {
    color: $color-primary-light-orange;
  }

  h1 {
    font-size: 48px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .accordion{
    width: 100%;
    padding: 0px 8px;
    @include media-medium{
      max-width: 960px;
      padding: 0;
    }
    &-content{
      &-description.ability{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .accordion-content-skill{
        flex-basis: 100%;
        @include media-small{
          flex-basis: 50%;
         }
        }
      }
    }
  }
  img.example.board{
    height: 432px;
  }
  .video-responsive{
    @include media-small{
      flex: 0 100%;
    }
    @include media-xlarge{
      flex: 0 60%;
      padding-bottom: 0;
      height: 400px;
    }
    footer{
      margin-top: 120px;
    }
  }

  .accordion-content-wrapper {
    .pic {
      width: 100%;
      @include media-small{
        width: 50%;
      }
      img {
        width: 100%;
      }
    }
  }

  .accordion-content-wrapper-rewarded {
    background-color: yellow;
  }
}
.tutorials.terms .accordion-content-description {
  width: 100% !important;

  ol {
    margin: 24px 0;
  }
}
