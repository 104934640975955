.confirm-dialog-portal {
  padding: 15px;
  background-color: rgb(20, 19, 19);
  color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  width: 350px;
  height: 150px;
  display: grid;
  place-content: center;
}

.confirm-dialog-overlay {
  display: grid;
  place-content: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}