.tooltip {
    font-family: "Rajdhani";
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    cursor: pointer;
    
}

.tooltip .tooltiptext {
    font-family: "Rajdhani";
    visibility: hidden;
    width: 200px;
    background-color:#7F330B;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 20;
    left: calc(50% - 100px);
    opacity: 0;
    transition: opacity 0.3s;
    top: -2.5em;
    &.tooltip-bottom {
        top: 100%;
        left: -200%;
        margin-left: -60px;
        padding:10px;
         &:after{
            content: "";
            position: absolute;
            top: -5px;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
         }
    }
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #7F330B transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}


