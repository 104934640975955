@import "../../styles/deviants-styles.scss";

.welcome-modal-wallet-connected,
.welcome-modal-profile
{

  .modal {
    grid-column: initial !important;
    grid-row: initial !important;
    display: flex;
    grid-template-rows: initial !important;
    grid-template-columns: initial !important;
    box-sizing: border-box;
    flex-direction: column;
    padding-bottom: 0;
    place-items: initial !important;
    width: 100%;
    overflow: hidden;
    min-height: 200px;

    .modal-content {
      position: relative;
      background: url(../../assets/img/modals/wallet-connected-text.png) transparent scroll no-repeat center center;
      background-size: contain;
      width: 528px;
      height: 480px;
      z-index:10;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      >.close-btn {
        border:1px solid rgba(255, 92, 0, 1);
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        text-align: center;
        &:hover{
          filter: drop-shadow(2px 4px 6px rgba(255, 92, 0, 1));
        }
      }
      .modal-box {
        display: flex;
        flex-direction: column;
        width: 370px;
        margin: 230px 0 0 0;
        align-items: center;

        span {
          text-align: center;
          font-family: $font-family-title;
          font-size: 1.05rem;
        }

        button {
          padding: 0 20px;
        }
      }
    }
  }
}

.welcome-modal-profile {
  .modal {
    .modal-content {
      background: url(../../assets/img/modals/profile-text.png) transparent scroll no-repeat center center;
      background-size: contain;

      .avatar {
        background: none black scroll no-repeat center center;
        background-size: cover;
        width: 90px;
        height: 90px;
        overflow: hidden;
        border-radius: 45px;
        position: absolute;
        top: 165px;
        left: 221px;
      }

      .modal-box {
        margin: 300px 0 0 0;
      }
    }
  }
}
