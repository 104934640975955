@import "../../styles/deviants-styles.scss";

.marquee-section{
  display: grid;
  grid-template-columns: 78px 1fr 78px;
  grid-template-rows: 78px 70px 70px 78px;
  @include media-small{
    grid-template-columns: 160px 1fr 160px;
    grid-template-rows: 155px 70px 70px 150px;
  }
  &-wrapper{
    overflow: hidden;
    position: relative;
    height: 140px;
    border-top: 3px solid rgba(254, 91, 2, 0.5);
    border-bottom: 3px solid rgba(254, 91, 2, 0.5);
    box-shadow: 0px 0px 200px 0px rgba(255, 92, 0, 0.44) inset;
    grid-column: 1/4;
    grid-row: 2/4;
  }
  &-text{
    -webkit-text-stroke: 2px #FF5C00;
    color:rgba(255, 92, 0, 0.6);
    font-size: 6rem;
    font-family: $font-family-title;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0px 0px 16px rgba(255, 66, 0, 0.7);
    padding: 8px;
    display: flex;
    width: max-content;
    animation: scroll-left 16s linear infinite;
  }
  &-top{
    &-left{
      grid-column: 1/2;
      grid-row: 1/3;
    }
    &-right{
      grid-column: 3/4;
      grid-row: 1/3;
    }
  }
  &-bottom{
    &-left{
      grid-column: 1/2;
      grid-row: 3/5;
      margin-top: 50px;
      @include media-small{
        margin-top: 32px;
      } 
    }
    &-right{
      grid-column: 3/4;
      grid-row: 3/5;
      margin-top: 50px;
      @include media-small{
        margin-top: 32px;
      }
    }
  }
  & .holder{
    width: 100%;
    @include media-small{
      width: 100%;
    }
    
  }
}
@keyframes scroll-left{
  0%{
    transform:translateX(100%);
  }
  100%{
    transform:translateX(-100%);
  }
}