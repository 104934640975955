.tournament-quit-modal {
  &__title {
    font-family: Rajdhani;
    font-weight: 700;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 10px;
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    .btn-shadow {
      width: 130px;
      height: 40px;

      button {
        width: 100%;
        min-height: 45px;
      }
    }
  }
}