@import "../../styles/deviants-styles.scss";

.tutorials {
  .items {

    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0 40px;
    width: calc(100% - 80px);
    max-width: 1280px;

    .item {
      height: 300px;
      display: flex;
      flex-direction: column;
      position: relative;
      background: rgba(255, 84, 0, 0.1);
      border: 2px solid #813008;
      box-shadow: 0 0 150px rgba(255, 84, 0, .4) inset;
      border-radius: 20px 20px 20px 0;
      padding: 20px 30px 30px 30px;
      clip-path: polygon(calc(100% - 25px) 0, 100% 25px, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%, 0 25px, 25px 0);

      align-items: center;

      margin: 0 20px 40px;
      min-height: 240px;

      width: calc(100%);

      @include media-medium {
        width: calc(50% - 40px);
      }

      @include media-xlarge {
        width: calc(33% - 40px);
      }

      h1 {
        font-size: 26px;
      }

      p {
        text-align: center;
      }

      .sep {
        flex: 1;
      }

      .btn.btn-medium {
        min-width: 215px;
        width: auto;
        padding: 0 20px;
      }
    }
  }
  .rules-link {
    font-family: 'Rajdhani', sans-serif;
    font-size: 26px;
    font-weight: 700;
  }
  .tutorial-reward {
    height: 50px;
    padding-top: 20px;
    margin-bottom: 10px;
  }
}
