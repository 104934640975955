// DECK BUILDER
.empty-deck{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 90vw;
  margin: auto;
  @include media-large{
    width: 60vw;
  }
  @include media-large{
    height: 70vh;
  }
  h2{
    margin-top: 8px;
  }
  h3{
    margin-top:32px;
  }
  p{
    font-family: $font-family-title;
    font-size: 22px;
    width: 240px;
    text-align: center;
    @include media-small{
      width: 100%;
    }
  }
  .actions{
    margin-top: 64px;
    display: flex;
    gap: 16px;
    margin-bottom: 48px;
    flex-direction: column;
    @include media-small{
      flex-direction: row;
    }
  }
}

.deck-builder {
  display: flex;
  flex-wrap: wrap;
  .deck-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include media-small {
      flex-direction: row;
    }
  }
  #HQ-picker {
    z-index: 9;
    .modal {
      width: 1000px;
      height: 70vh;
      overflow-y: auto;
      p.description {
        font-size: 10px;
      }
      .content {
        max-height: none;
      }
      .HQ-picker-card-list {
        margin-top: 32px;
        display: grid;
        place-content: center;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(340px, 1fr));
        grid-auto-rows: 384px;
        width: 900px;

        &-single {
          display: flex;
          flex-direction: column;
          width: auto;
          height: auto;
          justify-content: center;
          align-items: center;
          gap: 16px;

          .HQ-picker-container {
            position: relative;
          }

          .card-holder {
            height: 245px;
          }
          > div {
            position: relative;

            > .hover-options {
              display: none;
              place-content: center;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.1);
              border: 2px solid white;
              box-shadow: 0 0 32px white inset;
              z-index: 3;
              position: absolute;
              top: 0;
              left: 0;
            }
            @-moz-document url-prefix() {
              > .hover-options {
                height: 48%;
              }
            }
          }
          &:hover {
            > div {
              > .hover-options {
                display: grid;

                .view-btn .btn {
                  min-height: 48px;
                  gap: 8px;
                  background: rgba(23, 26, 25, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
