.tournament-timer {
    font-family: "Rajdhani";
    color: #8B8B8B;
    font-size: 48px;
    font-weight: bold;
    width: 445px;
    height: 79px;
    padding: 10px 10px 10px 20px;

    background-image: url(../../assets/img/timer.png);
    background-size: auto;
}

.tournament-timer .description {
    font-size: 18px;
    font-weight: lighter;
    text-transform: uppercase;
    padding-left: 5px;
}

.tournament-timer .hours, .tournament-timer .minutes {
    
}

.tournament-timer .separator{
    background-color: #1e2020;
    padding-left: 1px;
    max-width: 1px;
    max-height: 6px;

    margin: 0px 25px;
}