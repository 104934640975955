.metal-container {
  position: relative;
  margin-bottom: 76px;

  &::after {
    content: '';
    background: url('../../assets/images/metal-holder-left.png') no-repeat center center;
    position: absolute;
    bottom: -76px;
    left: 0;
    width: 470px;
    height: 146px;
    filter: brightness(2);
  }

  &::before {
    content: '';
    background: url('../../assets/images/metal-holder-right.png') no-repeat center center;
    position: absolute;
    bottom: -76px;
    right: 0;
    width: 470px;
    height: 146px;
    z-index: 1;
    filter: brightness(2);
  }
}