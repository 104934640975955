.tournaments-view {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: MIN(90vw, 1330px);
    margin: 0 auto;
    padding: 30px 0;
  }

  &__table {
    min-width: calc(100% - 442px);
    flex: 1;
  }

  &__item {
    position: relative;
    .tournament-item {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 130px;
    }
  }

  .metal-container {
    background-image: url('/assets/images/hero-tournaments.png');
    height: 200px;
    display: flex;
    background-size: cover;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    margin-top: -30px;

    h1 {
      margin: 40px 0;
      font-family: 'Rajdhani', serif;
      font-size: 3rem;
      text-transform: uppercase;
    }
  }

  table.gridjs-table {
    border-collapse: collapse;
    flex: 1;
    width: 100%;
    margin: 0 auto 0;

    td,
    th div {
      font-family: 'Rajdhani', serif;
      text-align: center;
      padding: 8px;
    }

    th div {
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: 300;
      color: rgb(116, 116, 116);
      padding: 10px 5px 15px;
    }

    tr:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.6);
    }

    tbody {
      border: 2px solid rgba(0, 0, 0, 0.5);
    }

    td a {
      cursor: pointer;
      .entry-fee {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    td a.selected {
      color: #ff5c00;
    }
  }
}
