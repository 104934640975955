.purchases {

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  &__item {
    display: flex;
    flex: 1 1 0;
    font-size: 1.1em;
    background: rgba(255, 84, 0, 0.1);
    box-shadow: 0 0 10px rgba(255, 84, 0, .4) inset;
    padding: 10px 20px;
    margin: 5px 10px;
    border: 3px solid #813008;
    font-family: "Rajdhani";
    text-transform: uppercase;
    font-weight: 600;
    justify-content: space-between;
  }

  .purchase {
    &__payment-id {
      width: 5rem;

      &>a {
        color: #ff5c00;
      }
    }

    &__status {
      width: 8rem;
      text-align: center;
    }

    &__actions {
      width: 7rem;
      margin: -8px 0;

      &>div {
        height: 100%;
      }
    }
  }
}