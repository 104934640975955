@import "../../styles/deviants-styles.scss";

.overlay{
  .another-btn{
    position: absolute;
    width: 115.5px;
    height: 46.63px;
    left: 833px;
    top: 669px;

    border: 3px solid #FF5C00;
    box-sizing: border-box;
  }
  a {
    color: $color-primary-light-orange;
	text-decoration: none;
  }

	display: none;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	justify-items: center;
	align-items: center;
	background: rgba(0,0,0,0.4);
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	grid-template-rows: 1fr;

	&.show{
	  display: grid;
	  position: fixed;
	  top: 0;
	  left: 0;
	}
	&.hide{
	  display: none;
	}
	& .bevel{
		position: relative;
		background: #171A19 ;
		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5), inset -2px -2px 0px #0D1110, inset 2px 2px 0px #222C29;
		clip-path: polygon(20px 0%, calc(100% - 20px) 0%, 100% 20px, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20px 100%, 0% calc(100% - 20px), 0% 20px);
		&::before {
			content: '';
			position: absolute;
			background-color: #222C29;
			width: 110px;
			height: 2px;
			top: 20px;
			left: -56px;
			transform: rotate(-45deg);
		}
		&::after {
			content: '';
			position: absolute;
			background-color: #0D1110;
			width: 42px;
			height: 2px;
			bottom: 6px;
			right: -6px;
			transform: rotate(-45deg);
		}
	}
	& .modal{
	  grid-column: 1/2;
	  grid-row: 1/2;
	  display: grid;
	  grid-template-rows: 8px auto 32px auto;
	  grid-template-columns: 1fr;
	  box-sizing: border-box;
	  flex-direction: column;
	  padding-bottom: 20px;
	  place-items: center;
	  width: calc(100vw - 16px);
	  overflow: hidden;
		min-height: 200px;
    position: relative;

    &.modal-container {
      overflow: initial;
    }

    & > .modal {
      grid-column: initial;
      grid-row: initial;
      display: flex;
      grid-template-rows: initial;
      grid-template-columns: initial;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

	  @include media-small{
		width: 528px;
		padding-bottom: 16px;
	  }

	  & .header-modal{
		grid-row: 1/2;
		grid-column: 1/2;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 24px 24px 21px 24px;
		width: 100%;
		position: relative;
		box-sizing: border-box;
		align-items: center;
		color: white;
		&::before {
			content: '';
			position: absolute;
			background-color: #813008;
			width: 106px;
			height: 2px;
			top: 31px;
			left: -53px;
			transform: rotate(-45deg);
		}
		& p,h1,h2,h3,h4,h5,h6{
		  margin: 0;
      font-family: 'Rajdhani', serif;
			font-size: 24px;
		}
		& .close_button{
			cursor: pointer;
			&:hover{
				color: white;
				}
			}
	  }

	  & .content{
		max-height: 520px;
		min-height: 80px;
		grid-row: 2/4;
		grid-column: 1/2;
		box-sizing: border-box;
      margin-top: 24px;
		align-self: flex-start;
      padding: 0 16px 40px;
      width: 100%;
		color: white;
		display: grid;
		gap: 16px;
		justify-items: center;
		z-index: 2;
		justify-content: center;
		@include media-xsmall{
		  min-height: 80px;
		  max-height: initial;
      padding: 0 24px 40px 24px;
		}
		.title{
			font-size: 32px;
			text-transform: uppercase;
			font-weight: 700;
		}
		p{
			font-family: $font-family-title;
			font-size: 22px;
		}
		&::-webkit-scrollbar{

		}

		&::-webkit-scrollbar-thumb{

		}

		&::-webkit-scrollbar-track{

		}
		form{
			display: flex;
			flex-direction: column;
			gap: 16px;
			width: 100%;
			label{
				font-family: $font-family-title;
			}
		}
		& table{
		  table-layout: fixed;
		  font-size: 16px;
		  margin-bottom: 32px;
		  margin-right: 16px;
		  width: 100%;
		  tr{
			margin: 8px 0;

		  }
		  td{
			width: 50%;
			height: 40px;

		  }
		  td:first-child{
			font-weight: 800;
			color: black;
		  }
		  td:last-child{
			text-align: end;
		  }
		}
		hr {
		  height: 1px;
		  margin: 0 0 8px 0;
		  border: none;
		}
	  }
		&-actions{
			display: flex;
			flex-direction: column;
			@include media-small{
				flex-direction: row;
			}
		}
	}
  }

