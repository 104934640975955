@import "../../../../styles/deviants-styles.scss";

.tournaments-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: MIN(90vw, 1260px);
  margin: 0 auto;
  padding: 30px 0 0 0;

  &__sort-by {
    width: 200px
  }

  &__types {
    display: flex;

    >div {
      font-family: 'Rajdhani', serif;
      text-align: center;
      margin-left: 20px;
      padding: 10px 20px;
      position: relative;

      cursor: pointer;

      &.active {
        border-color: rgb(235, 90, 0);

        &::after {
          background: rgb(235, 90, 0) !important;
          filter: drop-shadow(0px 0px 8px rgb(235, 90, 0))
        }

        &::before {
          background: rgb(235, 90, 0) !important;
          filter: drop-shadow(0px 0px 8px rgb(235, 90, 0))
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: calc(100% - 10px);
        height: 3px;
        background: white;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 5px;
        left: -2px;
        display: block;
        width: 15px;
        height: 3px;
        background: white;
        transform: rotate(45deg);
      }
    }

    &__search {
      width: 200px;
    }
  }

  .search-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 0 50px;
    gap: 16px;

    &-icon {
      position: absolute;

      left: 10px;
      top: 30px;
      @include media-small {
      }
    }

    input {
      height: 38px;
      border: 2px solid $color-primary-orange;
      background-color: transparent;
      font-family: $font-family-title;
      color: white;
      position: relative;
      padding: 3px 3px 3px 32px;
      text-align: left;
      width: 100%;
      @include media-small {
        width: 100%;
      }

      &::placeholder {
        position: absolute;
        color: white;
      }
    }

    .search-btn {
      display: none;
      @include media-small {
        display: flex;
      }

      .btn {
        width: 120px;
        min-height: 48px;
        // clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 10px 100%, 0 calc(100% - 10px));
      }
    }
  }
}
