@import "../../styles/deviants-styles.scss";

.empty-state{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin: auto;
    gap: 8px;
    @include media-large{
      width: 600px;
    }
    .icon{
      margin-bottom: 16px;
    }
    h1{
      margin-bottom: 24px;
      text-transform: uppercase;
    }
    p{
      font-family: $font-family-title;
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 16px;
      text-align: center;

    }
  }