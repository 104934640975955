// PROJECT INFO

.project-info {
  display: flex;
  flex-direction: column;

  &-title {
    padding: 8px 0 24px 8px;
    width: 100%;
    text-align: left;
  }

  &-section {
    display: flex;
    flex-direction: column;
    @include media-medium {
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 48px;
    }

    &-title {
      font-size: 18px;
      color: white;
      width: 100%;
      text-align: left;
      padding: 8px 0 16px 8px;
    }

    &-content {
      @include media-medium {
        flex-direction: row;
        display: flex;
        align-items: flex-start;
      }

      &.reversed {
        @include media-small {
          flex-direction: row-reverse;
        }
      }

      &-picture {
        width: 100%;
        @include media-medium {
          width: 400px;
          height: auto;
        }
      }

      &-description {
        font-family: $font-family-title;
        padding: 16px 8px;
        @include media-medium {
          flex: 1 0 65%;
          padding: 0px 8px;
        }
      }
    }
  }

  .the-team {
    font-size: 20px;
    color: white;
  }

  .team-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &-profile-box {
      margin: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 260px;
      gap: 8px;

      .profile-picture {
      }

      .name {
        width: 100%;
        text-align: center;
        font-family: $font-family-title;
        font-size: 20px;
      }

      .rs-list {
        display: flex;
        gap: 4px;
        justify-content: space-between;
        padding: 0px 24px;

        a img {
          width: 32px;
          height: 32px;

          .linkedin, .twitter, .github, .behance {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
