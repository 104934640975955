@import "styles/variables.scss";

.crystal-modal {
  position: relative;
  border: $deviant-border;
  max-width: 80vw;
  background: rgb(163, 72, 20);
  background: radial-gradient(circle, rgba(99, 53, 26, 0.4) 30%, rgba(255, 92, 0, 0.4) 100%);

  &-header {
    background: rgba(163, 72, 20, 0.5);
    padding: 10px 20px 0;
    text-align: center;

    span {
      position: relative;
      bottom: -1.3rem;
      font-family: 'Rajdhani';
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 80px;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #fa5c02;
      text-align: center;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      color: rgba(255, 92, 0, 0.6);
      text-shadow: 0px 0px 40px #ff4200, 0px 0px 20px #ff4200, 0px 0px 40px #ff4200;
    }
  }

  &-content {
    padding: 20px 20px 50px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
  }
}