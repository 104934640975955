#nft-modal {
  .modal {
    width: 80vw;

    .content {
      width: 100%;
      height: 99%;
      display: flex;
      flex-direction: column;
      
      .available-nfts {
        padding: 50px 10px;
        width: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 20px;
        justify-content: space-evenly;
        background-color: #0000007e;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), inset 0 0 10px 0 rgba(0, 0, 0, 0.2);
      }

      .crafting-nft-card {
        width: 250px;
        height: fit-content;
        border-radius: 8px;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        position: relative;

        &__selected {
          box-shadow: 0 0 10px 0 rgba(32, 225, 11, 0.5);
        }

        .crafting-nft-selected {
          text-align: center;
          font-size: 1.4rem;
          border-radius: 8px 8px 0px 0px;
          color: #fff;
          background-color: #21e50b8f;
          font-weight: 600;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
          font-family: 'Rajdhani', sans-serif;
          transition: all 0.2s ease-in-out;
        }

        .crafting-nft-unselected {
          text-align: center;
          font-size: 1.4rem;
          border-radius: 8px 8px 0px 0px;
          color: #0d0d0d;
          background-color: #9a9a9a8f;
          font-weight: 600;
          font-family: 'Rajdhani', sans-serif;
          transition: all 0.2s ease-in-out;
        }

        .crafting-nft-card__overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: grid;
          place-content: end;
          border-radius: 8px;
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
          z-index: 1;
          padding: 10px;
        }

        h2 {
          z-index: 2;
        }

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
        }
      }

    }
  }
}