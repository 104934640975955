.assigned-challenges {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5ch;
  padding-top: 3.5ch;

  &__header {
    width: 100%;
    text-align: center;
    padding: 2ch;
    background-color: rgba(74, 72, 72, 0.2);
    box-shadow: 0px -3px 1px #9b9b9b82, 1px 4px 5px black;

    span {
      letter-spacing: 0.1ch;
      font-size: 1.7rem;
      font-family: 'Tomorrow';
    }
  }
}

.challenge {
  &__item {
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 0px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
    background-color: #111312;
    width: 80%;
    display: flex;
    box-shadow: inset 0px 14px 24px rgba(0, 0, 0, 0.25),
      inset -3px -3px 1px rgba(255, 255, 255, 0.1);

    .challenge-rewards {
      padding: 1ch;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .btn {
        width: 150px;
      }

    }

    &__body {
      width: 70%;
      display: flex;
      flex-direction: column;
      padding: 1ch;
      justify-content: space-between;

      .challenge-time-left {
        letter-spacing: 0.2ch;
        font-family: "Tomorrow";
      }

      .challenge-item-title {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .challenge-step-counter {
          font-weight: 700;
          font-family: "Rajdhani";
        }

        .challenge-text-title {
          font-weight: 700;
          font-family: "Rajdhani";
          font-size: 1.35rem;
        }

        .challenge-text-subtitle {
          font-weight: 500;
          font-size: 0.9rem;
          font-family: "Rajdhani";
          padding-left: 1ch;
        }
      }
    }
  }
}