@import "../../../styles/deviants-styles.scss";

.editdeck {
  width: 100%;
  height: 134px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-small {
    // width: 616px;
    justify-content: flex-start;
    flex-direction: row;
  }

  &-status {
    flex: 2;
    padding: 0px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    @include media-small {
      justify-content: flex-start;
    }

    >.deck-selector {
      background-color: blue;
      min-width: 310px;

      background-image: url(./selector-floor.png);
      background-repeat: no-repeat;
      background-position-y: 30px;
      background-color: transparent;

      >select {
        background-color: #0b0806;
        background-color: rgba(0, 0, 0, 0.8);
        -webkit-appearance: none;
        border: none;
        color: white;
        width: 100%;

        &:focus-visible {
          outline: none;
        }

        padding: 20px;
      }
    }

    span.editdeck-status-name {
      margin: 0px 8px;
      opacity: 1;
    }

    span {
      font-family: $font-family-title;
      font-weight: 800;
      opacity: 0.5;
    }

    .icon-status {
      width: 40px;
      height: 40px;
    }
  }

  &-actions {
    display: flex;
    gap: 8px;
    flex-direction: row;
    margin-left: 46px;
    flex: 8;

    &__edit-new {
      display: flex;
      flex: 4;

      >div {
        margin: 0 10px;
      }
    }

    &__play {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding-right: 40px;

      >div {
        >.btn {
          width: 150px;
          font-size: 1.4em;
        }
      }
    }

    @include media-small {
      flex-direction: row;
    }

    .btn {
      min-width: 128px;
      width: fit-content;
      min-height: 46px;
    }
  }

  &-title {
    display: flex;
    position: relative;
    flex: 1 1 100%;
    height: 40px;
    justify-content: space-between;
    padding: 0px 16px;
    font-family: $font-family-title;
    font-weight: 800;

    &::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 16px;
      background: $color-primary-orange;
      bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      height: 3px;
      width: 90%;
      background: linear-gradient(180deg, #080a09 0%, #111514 100%);
      bottom: 0;
    }

    &-actions {
      display: flex;
      gap: 8px;
    }
  }
}

.search-bar {
  display: flex;
  align-items: center;
  flex-direction: column;

  >input {
    width: 80%;
    background-color: black;
    color: white;
    font-size: 22px;
    margin: 20px 0;
    text-align: center;

    &:focus-visible {
      outline: none;
    }
  }

  >p {
    font-size: 1em !important;
  }

  >.editdeck-actions {
    display: flex;
    flex: 1;
    flex: 1 1;
    width: 100%;
    justify-content: space-around;
    margin-top: 20px;
  }

  .busy-deck-tournament-list {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }
}
